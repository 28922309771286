import React, { Component } from "react";
import classes from "./DescriptionModal.module.css";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as VegIcon } from "../../assets/veg.svg";
import { ReactComponent as NonvegIcon } from "../../assets/nonveg.svg";
import CartManager from "../../utilities/CartManager";
// import { Image } from "cloudinary-react";
// import { Transformation } from "cloudinary-core";
import { GET_CUSTOMIZATIONS_FOR_STOCKS } from "../../apollo/queries";
import client from "../../apollo/client";
import {
  CheckForMultiSku,
  generateCustomizationConfig,
} from "../../utilities/helpers";
import { Image } from "cloudinary-react";
import { AppConfigContext } from "../../data/AppConfiguration";
import { RUPEE_SYMBOL } from "../../utilities/config";
import Swal from "sweetalert2";

const Contains = (props) => {
  return (
    <div className={classes.modalHeader}>
      {props.contains === "VEG" && <VegIcon />}
      {props.contains === "NONVEG" && <NonvegIcon />}
    </div>
  );
};

const Name = (props) => {
  return <h2>{props.name}</h2>;
};

// const product = data.data;
class DescriptionModal extends Component {
  static contextType = AppConfigContext;
  state = {
    name: this.props.data.trending
      ? this.props.data.description.item.node.sku.name
      : this.props.data.description.item.name,
    image: this.props.data.trending
      ? this.props.data.description.item.node.sku.secondaryUrl
        ? this.props.data.description.item.node.sku.noBgUrl
        : this.props.data.description.item.node.sku.image
      : this.props.data.description.item.image,
    store: this.props.data.description.item.store,
    description: this.props.data.trending
      ? this.props.data.description.item.node.sku.description
      : this.props.data.description.item.description,
    contains: this.props.data.trending
      ? this.props.data.description.item.node.sku.product.contains
      : this.props.data.description.item.contains,
    stockSla: this.props.data.description.item.stockSla,
    skuCardColor: this.props.data.trending
      ? this.props.data.description.item.node.sku.selectedColor
      : this.props.data.description.item.skuCardColor,
    skuFontColor: this.props.data.trending
      ? this.props.data.description.item.node.sku.fontColor
      : this.props.data.description.item.skuFontColor,
    skuMask: this.props.data.trending
      ? this.props.data.description.item.node.sku.mask
      : this.props.data.description.item.skuMask,
    storeName: this.props.data.trending
      ? this.props.data.description.item.node.store.name
      : this.props.data.description.item.storeName,
    customizations: {},
    isLoadingCustomization: false,
    itemsInCart: 0,
    showModal: false,
    stocksIds: [],
    itemAdded: false,
    showDescription: false,
    itemAddedAnimation: false,
    //can it have multiple stocks..
    trending: this.props.data.trending,
    stock: this.props.data.description.item.stocks
      ? this.props.data.description.item.stocks[0]
      : "",
    salePrice: this.props.data.trending
      ? this.props.data.description.item.node.salePrice
      : this.props.data.description.item.stocks[0].salePrice,
  };

  handleCloseClick = () => {
    this.props.onClose();
  };

  componentDidMount() {
    if (!this.state.trending) {
      this.mounted = true;
      const { item } = this.props.data.description;
      console.log("hello", JSON.stringify(this.props));
      const cartManager = new CartManager();
      const store = { id: item.pickuppoint, name: item.storeName };
      const stocksForProduct = item.stocks.map((stock) => stock.id);
      let sum = 0;
      cartManager
        .getAdddedStocksForProduct(stocksForProduct, store.id)
        .forEach((item) => {
          sum = sum + item.quantity;
        });
      this.setState(
        {
          itemsInCart: sum,
        },
        () => {
          if (item.isCustomizable) {
            this.setState(
              {
                isLoadingCustomization: true,
              },
              () => {
                const stocksIds = item.stocks.map((stk) => stk.id) || [];
                this.setState({
                  stocksIds: stocksIds,
                });
                this.getCustomizationForProduct(stocksIds);
              }
            );
          }
        }
      );
    } else {
      this.mounted = true;
      const {
        item: { node: stock },
      } = this.props.data.description;

      const cartManager = new CartManager();
      const stocksForProduct = stock.id;
      let sum = 0;
      cartManager
        .getAdddedStocksForProduct(
          stocksForProduct,
          stock.store.pickupPoints.edges[0].node.id
        )
        .forEach((item) => {
          sum = sum + item.quantity;
        });
      this.setState(
        {
          itemsInCart: sum,
        },
        () => {
          if (stock.customizations.edges.length) {
            this.setState(
              {
                isLoadingCustomization: true,
              },
              () => {
                const stocksIds = [stock.id] || [];
                this.getCustomizationForProduct(stocksIds);
              }
            );
          }
        }
      );
    }
  }

  getCustomizationForProduct = (stocksIds) => {
    client
      .query({
        query: GET_CUSTOMIZATIONS_FOR_STOCKS(),
        variables: { forStocks: stocksIds },
        fetchPolicy: "cache-first",
      })
      .then((res) => {
        let customStocks = {};
        res.data.stocks.edges.forEach((stock) => {
          console.log(
            "generate customization",
            generateCustomizationConfig(stock)
          );
          customStocks[stock.node.id] = generateCustomizationConfig(stock);
          // stock.node.customizations;
        });
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
            customizations: customStocks,
          });
      })
      .catch((err) => {
        console.log("Error in getting customization data", err);
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
          });
      });
  };

  _addItemToCart = (e) => {
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    e.stopPropagation();
    const {
      item: { node: stock },
    } = this.props.data.description;

    const pickupoint = {
      id: stock.store.pickupPoints.edges[0].node.id,
      name: stock.store.name,
    };
    console.log("item for the cart", pickupoint);
    const { customizations } = this.state;
    const cartManager = new CartManager();
    if (stock.customizations.edges.length > 0) {
      this.props.data.description.setCustomizationData(true, {
        stock: stock,
        pickupoint: pickupoint,
        customizations: customizations,
        isStock: true,
      });
    } else {
      const stockP = {
        id: stock.id,
        name: stock.sku.name,
        price: stock.salePrice,
      };
      if (
        offerDetails &&
        offerDetails.offerStocks &&
        offerDetails.offerStocks.includes(stock.id) &&
        freeMealCount < 1
      ) {
        Swal.fire({
          text: "You have exhausted complimentary meal quota",
          title: "Error",
          icon: "error",
          confirmButtonColor: "#ff5500",
        });
      } else {
        if (
          offerDetails &&
          offerDetails.offerStocks &&
          offerDetails.offerStocks.includes(stock.id)
        ) {
          setFreeMealCount(freeMealCount - 1);
        }
        cartManager.addStockToCart(pickupoint, stockP, 1);
      }
      this.setState(
        {
          itemAddedAnimation: true,
        },
        () => {
          this.timeout = setTimeout(() => {
            this.setState({
              itemAddedAnimation: false,
            });
          }, 1000);
        }
      );
      this.props.data.description.updateCart();
    }
    this.props.onClose();
  };

  addItemToCart = () => {
    const { item } = this.props.data.description;
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    const pickupoint = { id: item.pickuppoint, name: item.storeName };
    const { customizations, isLoadingCustomization } = this.state;
    console.log("***** Product Customizations *****", customizations);
    // const pickupoint = { id: store.id, name: store.store.name };
    const cartManager = new CartManager();
    if (item.isCustomizable || CheckForMultiSku(item)) {
      this.props.onClose();
      !isLoadingCustomization &&
        this.props.data.description.setCustomizationData(true, {
          product: item,
          pickupoint: pickupoint,
          customizations: customizations,
        });
    } else {
      const selectedStock = item.stocks[0];
      const stock = {
        id: selectedStock.id,
        name: item.name,
        price: selectedStock.salePrice,
      };
      if (
        offerDetails &&
        offerDetails.offerStocks &&
        offerDetails.offerStocks.includes(stock.id) &&
        freeMealCount < 1
      ) {
        Swal.fire({
          text: "You have exhausted complimentary meal quota",
          title: "Error",
          icon: "error",
          confirmButtonColor: "#ff5500",
        });
      } else {
        if (
          offerDetails &&
          offerDetails.offerStocks &&
          offerDetails.offerStocks.includes(stock.id)
        ) {
          setFreeMealCount(freeMealCount - 1);
        }
        cartManager.addStockToCart(pickupoint, stock, 1);
      }
      this.props.data.description.updateCart();
      this.setState(
        {
          itemAdded: true,
          itemAddedAnimation: true,
        },
        () => {
          this.timeout = setTimeout(() => {
            this.setState({
              itemAddedAnimation: false,
            });
          }, 1000);
        }
      );
    }
    this.props.onClose();
  };

  addItemForQuantity = (sum, item) => {
    console.log("IOtem", item);
    return sum + item.quantity;
  };

  render() {
    const {
      name,
      image,
      description,
      contains,
      stockSla,
      // isCustomizable,
      skuCardColor,
      skuFontColor,
      skuMask,
      storeName,
      stock,
      trending,
      salePrice,
    } = this.state;

    const { isSmallScreenDevice } = this.props;
    console.log(isSmallScreenDevice);
    const style = {
      backgroundColor: skuCardColor ? skuCardColor : "#ffffff",
      color: skuFontColor ? skuFontColor : "#000000",
    };

    if (!trending) {
      return (
        <div className={classes.overlay}>
          <div className={classes.modal} style={style}>
            <Contains contains={contains} />
            <div className={classes.modalImage}>
              {/* <img src={image} alt="" /> */}
              <Image
                cloudName="grabbngo"
                responsive
                // width={widthImage}
                quality={"auto:good"}
                crop={"scale"}
                fetchFormat={"auto"}
                dpr={2.0}
                publicId={image}
                alt={name}
                radius={skuMask ? "max" : ""}
                background={"transparent"}
                style={{
                  top: "10%",
                  height: isSmallScreenDevice ? "175px" : "300px",
                  width: isSmallScreenDevice ? "175px" : "300px",
                  objectFit: "contain",
                  filter: "drop-shadow(15px 15px 15px rgb(34, 34, 34, 0.8))",
                }}
                className={classes.imageCard}
              ></Image>
            </div>
            <div>
              <Name name={name}></Name>
              <p>by {storeName}</p>
              <h4>
                {RUPEE_SYMBOL} {salePrice}
              </h4>
              <p className={classes.description}>{description}</p>
              <div className={classes.cartBtn}>
                {stock.inStock ? (
                  <button
                    style={{
                      color: skuFontColor,
                    }}
                    onClick={this.addItemToCart}
                  >
                    Add to cart
                  </button>
                ) : (
                  <button disabled={true}>Out of stock</button>
                )}
              </div>
            </div>
          </div>
          <div className={classes.closebtn}>
            <button onClick={this.handleCloseClick}>
              <CloseIcon height={40} width={40} />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.overlay}>
          <div className={classes.modal} style={style}>
            <Contains contains={contains} />
            <div className={classes.modalImage}>
              {/* <img src={image} alt="" /> */}
              <Image
                cloudName="grabbngo"
                responsive
                // width={widthImage}
                quality={"auto:good"}
                crop={"scale"}
                fetchFormat={"auto"}
                dpr={2.0}
                publicId={image}
                alt={name}
                radius={skuMask ? "max" : ""}
                background={"transparent"}
                style={{
                  top: "10%",
                  height: isSmallScreenDevice ? "175px" : "300px",
                  width: isSmallScreenDevice ? "175px" : "300px",
                  objectFit: "contain",
                  filter: "drop-shadow(15px 15px 15px rgb(34, 34, 34, 0.8))",
                }}
                className={classes.imageCard}
              ></Image>
            </div>
            <div>
              <Name name={name}></Name>
              <p>by {storeName}</p>
              <h4>
                {RUPEE_SYMBOL} {salePrice}
              </h4>
              <p className={classes.description}>{description}</p>
              <div className={classes.cartBtn}>
                {<button onClick={this._addItemToCart}>Add to cart</button>}
              </div>
            </div>
          </div>
          <div className={classes.closebtn}>
            <button onClick={this.handleCloseClick}>
              <CloseIcon height={40} width={40} />
            </button>
          </div>
        </div>
      );
    }
  }
}

export default DescriptionModal;
