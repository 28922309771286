import React, { useState, useEffect } from "react";
import classes from "./Banks.module.css";
import { filterBankResults } from "../../utilities/helpers";
import { PAYMENT_MODE_CONSTANTS } from "./config";

const Banks = ({ availableBanks = [], onSubmit }) => {
  const [filteredBanks, setFilteredBanks] = useState(availableBanks);
  //eslint-disable-next-line
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    filterBankResults(availableBanks, searchValue, setFilteredBanks);
  }, [availableBanks, searchValue]);

  // const onSearchChange = e => {
  //   const searchText = e.target.value;
  //   setSearchValue(searchText.toLowerCase());
  // };

  const onBankClick = code => {
    console.log("bank code", code);
    onSubmit &&
      onSubmit({ method: PAYMENT_MODE_CONSTANTS.netbanking, bank: code });
  };

  return (
    <div className={classes.maindiv}>
      <p className={classes.heading}>Netbanking</p>
      {/* <div className={classes.input_div}>
        <input
          className={classes.bank_input}
          value={searchValue}
          onChange={onSearchChange}
          placeholder={"type your bank's name here.."}
        />
        <button className={classes.close_btn}>X</button>
      </div> */}
      <ul className={classes.bank_list}>
        {filteredBanks.map(([code, name]) => (
          <li onClick={() => onBankClick(code)} key={code}>
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Banks;
