import React, { useState, useEffect } from "react";
import classes from "./RegionScreen.module.css";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_REGION_FROM_URL } from "../../apollo/queries";
import Loading from "../../components/Loading/Loading";
import { ORDER_MODES, CUSTOMER_ORDER_MODE } from "../../utilities/config";
import { ReactComponent as TakeAway } from "../../assets/takeaway.svg";
import { ReactComponent as DineIn } from "../../assets/dinein.svg";
import { ReactComponent as Delivery } from "../../assets/delivery.svg";
import TableServicePopUp from "../../components/PopUp/TableServicePopUp";
import StateManager from "../../utilities/StateManager";
import Swal from "sweetalert2";
import GatePicker from "../../components/GatePicker/GatePicker";

const renderLogo = (mode) => {
  if (mode === "DELIVERY") {
    return <Delivery height={30} width={30} fill={"#FFFFFF"} />;
  } else if (mode === "TAKEAWAY") {
    return <TakeAway height={30} width={30} fill={"#FFFFFF"} />;
  }
  return <DineIn height={30} width={30} fill={"#FFFFFF"} />;
};

const RegionScreen = ({ onRegionSelect, ...props }) => {
  const { url } = useParams();
  const { data, loading, error } = useQuery(GET_REGION_FROM_URL(), {
    variables: { customUrl: url },
  });
  const [regionFromUrl, setRegionFromUrl] = useState(null);
  const [hasError, setError] = useState(false);
  let history = useHistory();
  const [isgateModalOpen, togglegateModal] = useState(false);
  const ongateModalClose = () => {
    togglegateModal(false);
  };

  useEffect(() => {
    if (data && data.regionFromUrl) {
      setRegionFromUrl(data.regionFromUrl);
    } else if (!loading && !data) {
      setError(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (regionFromUrl) {
      const region = regionFromUrl;
      const {
        canChangeOrderMode,
        canChangeRegion,
        canShareCheckout,
        checkoutHeader,
        trendingLogo,
        shouldShowTrending,
        trendingName,
        terminal,
        isAirport,
        isFidsAvailable,
        productDisplayView,
      } = region;
      const config = {
        canChangeOrderMode,
        canChangeRegion,
        canShareCheckout,
        checkoutHeader,
        trendingLogo,
        shouldShowTrending,
        trendingName,
        terminal,
        isAirport,
        isFidsAvailable,
        productDisplayView,
      };
      onRegionSelect(region, config);
    }
  }, [regionFromUrl, onRegionSelect]);

  const onGateSelect = (orderMode, gate) => {
    const stateManager = new StateManager();
    stateManager.deliveryLocation = gate.name;
    ongateModalClose();
    this.props.onOrderModeUpdate(orderMode, gate.name);
    history.push("/store");
  };

  const onOrderModeSelect = (mode) => {
    const stateManager = new StateManager();
    const modeName = mode.node.orderMode.name;
    if (mode.node.isOpen) {
      if (modeName === ORDER_MODES.delivery) {
        stateManager.deliveryLocationType = "";
        togglegateModal(true);
      } else if (
        modeName === ORDER_MODES.tableService ||
        modeName === ORDER_MODES.seatService
      ) {
      } else {
        props.onOrderModeUpdate(modeName);
        history.push("/store");
      }
    } else {
      Swal.fire({
        title: "",
        text: `We are not doing ${mode.node.orderMode.name} at this moment.\n Please try after some time.`,
        customClass: {
          confirmButton: classes.swal_close_button,
          closeButton: classes.swal_close_button,
          cancelButton: classes.swal_close_button,
        },
        icon: "info",
      });
    }
  };

  const handleTableConfirmation = (mode, table) => {
    props.onOrderModeUpdate(mode, table);
    history.push("/store");
  };
  console.log("wrrrororor ---", error, hasError);

  if (regionFromUrl && regionFromUrl.id) {
    const region = regionFromUrl;
    console.log("region", region);
    return (
      <div className={classes.parent}>
        <div className={classes.image_div}>
          <img
            alt={region.name}
            src={region.image}
            className={classes.region_image}
          />
        </div>
        <div className={classes.content_div}>
          <div className={classes.welcome_text_div}>
            <p className={classes.highlight_text}>Hey</p>
            <br />
            {/* {user && user.firstName && (
                  <p className={classes.highlight_text}>{user.firstName}</p>
                )} */}
            <br />
            <p className={classes.welocme_text}>
              Welcome to {region && region.welcomeText}
            </p>
          </div>
          <div className={classes.order_mode_div}>
            {region &&
              region.regionOrderModes.edges.map((order) => {
                if (
                  order.node.status === "ACTIVE" &&
                  order.node.orderMode.name !== ORDER_MODES.tableService &&
                  order.node.orderMode.name !== ORDER_MODES.seatService &&
                  order.node.orderMode.name !== ORDER_MODES.delivery
                ) {
                  return (
                    <button
                      onClick={() => onOrderModeSelect(order)}
                      key={order.node.id}
                      className={classes.ordermodepanel}
                    >
                      {renderLogo(order.node.orderMode.name)}
                      <p>
                        {order.node.orderMode.name &&
                          CUSTOMER_ORDER_MODE[order.node.orderMode.name]}
                      </p>
                    </button>
                  );
                } else if (
                  order.node.status === "ACTIVE" &&
                  order.node.orderMode.name === ORDER_MODES.delivery
                ) {
                  return (
                    <button
                      onClick={() => onOrderModeSelect(order)}
                      key={order.node.id}
                      className={classes.ordermodepanel}
                    >
                      {renderLogo(order.node.orderMode.name)}
                      <p>
                        {order.node.orderMode.name &&
                          CUSTOMER_ORDER_MODE[order.node.orderMode.name]}
                      </p>
                    </button>
                  );
                } else if (
                  order.node.status === "ACTIVE" &&
                  (order.node.orderMode.name === ORDER_MODES.tableService ||
                    order.node.orderMode.name === ORDER_MODES.seatService)
                ) {
                  return (
                    <TableServicePopUp
                      hasTableNumber={false}
                      tableNo={""}
                      key={order.node.id}
                      onSelect={handleTableConfirmation}
                      mode={order.node.orderMode}
                      skipOrderMode={
                        order.node.orderMode.name === ORDER_MODES.seatService
                      }
                      isOpen={order.node.isOpen}
                    />
                  );
                }
                return null;
              })}
          </div>
        </div>
        {isgateModalOpen && (
          <GatePicker
            isOpen={isgateModalOpen}
            onClose={ongateModalClose}
            gates={region.deliveryLocations}
            onSelect={onGateSelect}
          />
        )}
      </div>
    );
  }
  if (hasError || error) {
    return <Redirect to={{ pathname: "/region" }} />;
  }
  return <Loading open />;
};

export default RegionScreen;
