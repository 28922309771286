import React, { Component } from "react";
import classes from "./BoardingScreen.module.css";
import TopNav from "../../components/TopNav/TopNav";
import StateManager from "../../utilities/StateManager";
import { ReactComponent as TakeAway } from "../../assets/takeaway.svg";
import { ReactComponent as DineIn } from "../../assets/dinein.svg";
import { ReactComponent as Delivery } from "../../assets/delivery.svg";
import moment from "moment";
import {
  ORDER_MODES,
  CITY_CODE_LIST,
  CUSTOMER_ORDER_MODE,
} from "../../utilities/config";
import { ReactComponent as PlaneIcon } from "../../assets/Plane.svg";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";
import TableServicePopUp from "../../components/PopUp/TableServicePopUp";
import GatePicker from "../../components/GatePicker/GatePicker";
import RedirectionCodeInput from "../../components/RedirectionCodeInput";

const renderLogo = (mode) => {
  if (mode === "DELIVERY") {
    return <Delivery height={30} width={30} fill={"#FFFFFF"} />;
  } else if (mode === "TAKEAWAY") {
    return <TakeAway height={30} width={30} fill={"#FFFFFF"} />;
  }
  return <DineIn height={30} width={30} fill={"#FFFFFF"} />;
};

const MSG = {
  PLACE_ORDER: "Please place the order before ",
  PLACE_ORDER_: " to get it on delivery time.",
};

const stateManager = new StateManager();

/**
 * This screen should handle both, Region selection and redirection url
 */
class BoardingScreen extends Component {
  mounted = false;
  state = {
    region: null,
    flightData: [],
    isLoadingFlightData: false,
    isModalOpen: false,
    selectedOrdermode: null,
    selectedFlight: null,
    isRightTimeToDelivery: true,
    availableSlots: [],
    selectedSlot: null,
    user: null,
    imageUrl: null,
    isGateModalOpen: false,
    isLoadingGateData: false,
    gates: null,
    isAirport: false,
    isFidsAvailable: false,
    terminal: "",
    shouldAskLocation: false,
    showTableOrderPopUp: false,
    redirectionURLHasTableNo: false,
    showGatePicker: false,
    isSeatService: false,
    shouldAskCode: false,
    isRedirected: false,
  };

  componentDidMount() {
    this.mounted = true;
    const { region, appConfig, redirectionURL } = this.props;
    console.log("redirection URL ---->", redirectionURL);
    if (
      redirectionURL &&
      redirectionURL.orderMode === ORDER_MODES.seatService
    ) {
      this.setState({
        isSeatService: true,
      });
    }
    const stateManager = new StateManager();
    const deliveryLocation = stateManager.deliveryLocation;
    console.log("region data", appConfig, redirectionURL, region);
    const imageUrl =
      redirectionURL && redirectionURL.imageUrl
        ? redirectionURL.imageUrl
        : region.image;
    const user = stateManager.userProfile;
    if (!region) {
      this.props.history.replace("/region");
    }
    this.mounted &&
      this.setState({
        region: region,
        isLoadingFlightData: true,
        isRedirected: redirectionURL && redirectionURL.id,
        user: user,
        isAirport: appConfig.isAirport,
        isFidsAvailable: appConfig.isFidsAvailable,
        terminal: appConfig.terminal,
        shouldAskLocation: !appConfig.isFidsAvailable,
        imageUrl,
        redirectionURLHasTableNo: deliveryLocation,
        shouldAskCode:
          redirectionURL && redirectionURL.shouldAskCode
            ? redirectionURL.shouldAskCode
            : false,
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onFlightPickerClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onGatePickerClose = () => {
    this.setState({
      showGatePicker: false,
    });
  };

  onCouponCodeInputClose = () => {
    this.setState({
      shouldAskCode: false,
    });
  };

  onOrderModeSelect = async (mode) => {
    const stateManager = new StateManager();
    const modeName = mode.node.orderMode.name;
    if (mode.node.isOpen) {
      if (modeName === ORDER_MODES.delivery) {
        // if (appConfig.isFidsAvailable) {
        this.setState(
          {
            isModalOpen: true,
            selectedOrdermode: modeName,
          },
          () => {
            stateManager.deliveryLocationType = "";
          }
        );
        // }
      } else if (
        modeName === ORDER_MODES.tableService ||
        modeName === ORDER_MODES.seatService
      ) {
        const tableno = null;
        const seatno = null;
        this.setState(
          {
            showTableOrderPopUp: true,
          },
          () => console.log("table order popup")
        );
        if (tableno) {
          this.props.onOrderModeUpdate(modeName, tableno);
          // stateManager.deliveryLocation = tableno;
          this.props.history.replace("/store");
        } else if (seatno) {
          this.props.onOrderModeUpdate(modeName, seatno);
          this.props.history.replace("/store");
        }
      } else {
        this.props.onOrderModeUpdate(modeName);
        this.props.history.replace("/store");
      }
    } else {
      Swal.fire({
        title: "",
        text: `We are not doing ${mode.node.orderMode.name} at this moment.\n Please try after some time.`,
        customClass: {
          confirmButton: classes.swal_close_button,
          closeButton: classes.swal_close_button,
          cancelButton: classes.swal_close_button,
        },
        icon: "info",
      });
    }
  };

  onGateSelect = (orderMode, gate) => {
    stateManager.deliveryLocation = gate.name;
    this.setState(
      {
        showGatePicker: false,
      },
      () => {
        this.props.onOrderModeUpdate(orderMode, gate.name);
        this.props.history.replace("/store");
      }
    );
  };

  onCancelClick = () => {
    this.setState({
      selectedOrdermode: "", // current order mode
      isLoading: false, // is anything loading
      selectedFlight: null, //selected flight by user for delivery
      isselectedOrderMode: false, // has any order mode been selected
      showFlightModal: false, // to show flight selection modal or not
    });
  };

  onDeliveryTimeSelect = (e) => {
    let selectedDate = moment(Number(e.target.value));
    this.setState({
      selectedSlot: moment(selectedDate),
    });
  };

  onNextClick = () => {
    const { selectedFlight, selectedSlot, selectedOrdermode } = this.state;
    if (selectedFlight && selectedSlot && selectedOrdermode) {
      stateManager.deliveryTime = selectedSlot;
      // this.props.onOrderModeUpdate(selectedOrdermode);
      this.props.history.replace("/store");
    } else {
      //To-Do Put a proper alert
      alert("Please complete the form");
    }
  };

  handleTableConfirmation = (mode, table) => {
    this.props.onOrderModeUpdate(mode, table);
    this.props.history.replace("/store");
  };

  render() {
    const {
      region,
      selectedFlight,
      isRightTimeToDelivery,
      availableSlots,
      selectedSlot,
      user,
      imageUrl,
      showTableOrderPopUp,
      redirectionURLHasTableNo,
      isModalOpen,
      isSeatService,
      shouldAskCode,
      isRedirected,
    } = this.state;
    if (region) {
      return (
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
          className={classes.maindiv}
        >
          <TopNav
            hideRegionName={true}
            hideOrderLogo={true}
            hideCartbutton={true}
            hidebackbutton={isRedirected}
            isTransparent
          />
          {!selectedFlight && (
            <>
              <div className={classes.welcome_text_div}>
                <p className={classes.highlight_text}>Hey{user && ","}</p>
                <br />
                {user && user.firstName && (
                  <p className={classes.highlight_text}>{user.firstName}</p>
                )}
                <br />
                <p className={classes.welocme_text}>
                  Welcome to {region && region.welcomeText}
                </p>
              </div>
              {!showTableOrderPopUp && (
                <div className={classes.ordermodeDiv}>
                  {region &&
                    region.regionOrderModes.edges.map((order) => {
                      if (
                        order.node.status === "ACTIVE" &&
                        order.node.orderMode.name !==
                          ORDER_MODES.tableService &&
                        order.node.orderMode.name !== ORDER_MODES.seatService &&
                        order.node.orderMode.name !== ORDER_MODES.delivery
                      ) {
                        return (
                          <button
                            onClick={() => this.onOrderModeSelect(order)}
                            key={order.node.id}
                            className={classes.ordermodepanel}
                          >
                            {renderLogo(order.node.orderMode.name)}
                            <p>
                              {order.node.orderMode.name &&
                                CUSTOMER_ORDER_MODE[order.node.orderMode.name]}
                            </p>
                          </button>
                        );
                      } else if (
                        order.node.status === "ACTIVE" &&
                        order.node.orderMode.name === ORDER_MODES.delivery
                      ) {
                        return (
                          <button
                            onClick={() => this.onOrderModeSelect(order)}
                            key={order.node.id}
                            className={classes.ordermodepanel}
                          >
                            {renderLogo(order.node.orderMode.name)}
                            <p>
                              {order.node.orderMode.name &&
                                CUSTOMER_ORDER_MODE[order.node.orderMode.name]}
                            </p>
                          </button>
                        );
                      } else if (
                        order.node.status === "ACTIVE" &&
                        (order.node.orderMode.name ===
                          ORDER_MODES.tableService ||
                          order.node.orderMode.name === ORDER_MODES.seatService)
                      ) {
                        return (
                          <TableServicePopUp
                            hasTableNumber={redirectionURLHasTableNo}
                            tableNo={redirectionURLHasTableNo || ""}
                            key={order.node.id}
                            onSelect={this.handleTableConfirmation}
                            mode={order.node.orderMode}
                            skipOrderMode={isSeatService}
                            isOpen={order.node.isOpen}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              )}
            </>
          )}
          {isModalOpen && (
            <GatePicker
              isOpen={isModalOpen}
              onClose={this.onFlightPickerClose}
              gates={region.deliveryLocations}
              onSelect={this.onGateSelect}
            />
          )}
          {selectedFlight && (
            <div className={classes.boarding_pass}>
              <div className={classes.boardingpass}>
                <div className={classes.destinationdiv}>
                  <div>{CITY_CODE_LIST[region.city]}</div>
                  <PlaneIcon height={50} width={50} />
                  <div>{selectedFlight.destination_code}</div>
                </div>
                <div className={classes.datarow}>
                  <div>
                    <p className={classes.fieldtitle}>Flight</p>
                    <p className={classes.fieldText}>
                      {selectedFlight.flight_number}
                    </p>
                  </div>
                  <div>
                    <p
                      className={[
                        classes.fieldtitle,
                        classes.fieldtitleRight,
                      ].join(" ")}
                    >
                      Departs at
                    </p>
                    <p
                      className={[
                        classes.fieldText,
                        classes.fieldtitleRight,
                      ].join(" ")}
                    >
                      {`${moment(selectedFlight.departure_datetime).format(
                        "ddd hh:mm A"
                      )}`}
                    </p>
                  </div>
                </div>
                <div className={classes.datarow}>
                  <div>
                    <p className={classes.fieldtitle}>Gate</p>
                    <p className={classes.fieldText}>{selectedFlight.gates}</p>
                  </div>
                  <div>
                    <p
                      className={[
                        classes.fieldtitle,
                        classes.fieldtitleRight,
                      ].join(" ")}
                    >
                      Boarding Closure
                    </p>
                    <p
                      className={[
                        classes.fieldText,
                        classes.fieldtitleRight,
                      ].join(" ")}
                    >
                      {" "}
                      {`${moment(selectedFlight.boarding_closure).format(
                        "ddd hh:mm A"
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.boardingpass}>
                {isRightTimeToDelivery && (
                  <div className={classes.datarow}>
                    <div>
                      <p className={classes.fieldtitle}>Last Delivery Slot</p>
                      <p className={classes.fieldText}>
                        {`${moment(selectedFlight.boarding_closure)
                          .subtract(15, "minutes")
                          .format("hh:mm A")}`}
                      </p>
                    </div>
                  </div>
                )}
                {isRightTimeToDelivery && selectedSlot && (
                  <div className={classes.select_div}>
                    <label className={classes.select_lable}>
                      Select delivery time
                    </label>
                    <select
                      className={classes.select_delivery_slot}
                      name="select time slot"
                      onChange={this.onDeliveryTimeSelect}
                    >
                      {availableSlots.map((item, index) => {
                        return (
                          <option
                            className={classes.select_option}
                            key={index}
                            value={item}
                          >
                            {moment(item).format("hh:mm A")}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {isRightTimeToDelivery && selectedSlot && (
                  <p className={classes.deliveryAlert}>
                    {MSG.PLACE_ORDER}
                    <span>
                      {selectedSlot &&
                        selectedSlot.subtract(15, "minutes").format("hh:mm A")}
                    </span>
                    {MSG.PLACE_ORDER_}
                  </p>
                )}
                {!isRightTimeToDelivery && (
                  <p className={classes.deliveryAlert}>
                    Delivery is not available for this flight. Please choose
                    another order mode.
                  </p>
                )}
                {isRightTimeToDelivery && (
                  <button
                    onClick={this.onNextClick}
                    className={classes.submitbutton}
                  >
                    Next
                  </button>
                )}
                {!isRightTimeToDelivery &&
                  region &&
                  region.regionOrderModes.edges.map((order) => {
                    if (order.node.orderMode.name !== ORDER_MODES.delivery) {
                      return (
                        <button
                          onClick={() => this.onOrderModeSelect(order)}
                          key={order.node.id}
                          className={classes.submitbutton}
                        >
                          {order.node.orderMode.name &&
                            CUSTOMER_ORDER_MODE[order.node.orderMode.name]}
                        </button>
                      );
                    }
                    return null;
                  })}
                <button
                  onClick={this.onCancelClick}
                  className={classes.submitbutton}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {shouldAskCode && (
            <RedirectionCodeInput
              onClose={this.onCouponCodeInputClose}
              open={shouldAskCode}
            />
          )}
        </div>
      );
    }
    return (
      <div className={classes.layoverloading}>
        <Loading open />
      </div>
    );
  }
}

export default BoardingScreen;
