// this screen is to book the slot for the screen
import React, { Component } from "react";
import classes from "./DeliveryAddress.module.css";
import Button from "../../components/Button/Button";
import client from "../../apollo/client";
import { UPDATE_USER_LOCATION } from "../../apollo/mutations";

/**
 * This screen should handle both, Region selection and redirection url
 */

const Input = (props) => {
  return (
    <input
      className={classes.inputField}
      placeholder={props.placeholder}
      value={props.value ? props.value : ""}
      onChange={props.onChange}
      maxLength={props.maxLength ? props.maxLength : null}
    ></input>
  );
};

class DeliveryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      house: null,
      landmark: null,
      saveAs: null,
      error: false,
      saving: false,
    };
  }

  componentDidMount() {}

  saveAddress = () => {
    //save address to the server
    const { house, name, landmark } = this.state;
    const latitude = "420";
    const longitude = "420";
    const input = {
      name,
      landmark,
      house,
      latitude,
      longitude,
    };

    client
      .mutate({
        mutation: UPDATE_USER_LOCATION(),
        variables: { input: input },
      })
      .then((res) => {})
      .catch((err) => {
        alert("there was a problem in saving the address");
      });
  };

  handleAddAddress = () => {
    const { house, name, landmark } = this.state;
    if (
      (house === null && name === null && landmark === null) ||
      house.length < 3 ||
      name.length < 3 ||
      landmark.length < 3
    ) {
      this.setState({ error: true });
    } else {
      this.setState(
        {
          error: false,
        },
        () => {
          this.saveAddress();
          this.props.history.replace("/cart");
        }
      );
    }
  };

  onChangeHouse = (e) => {
    this.setState({ house: e.target.value });
  };

  onChangeLandmark = (e) => {
    this.setState({ landmark: e.target.value });
  };

  onChangeLabel = (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { house, landmark, name, error } = this.state;
    return (
      //   <div className={classes.layoverloading}>
      //     <Loading open />
      //   </div>
      <div className={classes.addressContainer}>
        <div>
          <h3>Add address for delivery</h3>
        </div>
        <div>
          <Input
            placeholder="House No / Flat No / Block"
            value={house ? house : null}
            onChange={this.onChangeHouse}
          ></Input>
        </div>
        <div>
          <Input
            placeholder="Landmark"
            value={landmark ? landmark : null}
            onChange={this.onChangeLandmark}
          ></Input>
        </div>
        <div>
          <Input
            placeholder="Save As"
            value={name ? name : null}
            onChange={this.onChangeLabel}
            maxLength={"8"}
          ></Input>
        </div>
        <div>
          {error && (
            <span style={{ color: "red", fontSize: "10px" }}>
              Please fill the correct details
            </span>
          )}
        </div>
        <div className={classes.addButton}>
          <Button
            name={"SAVE ADDRESS"}
            value={"SAVE ADDRESS"}
            onClick={() => this.handleAddAddress()}
          ></Button>
        </div>
      </div>
    );
  }
}
export default DeliveryAddress;
