import React, { Component } from "react";
import classes from "./WaiterService.module.css";
// import { ReactComponent as Waiter } from "../../assets/waiter-table.svg";
// import { margin } from "polished";
import SuccessIcon from "../../components/SuccessIcon/SuccessIcon";
import client from "../../apollo/client";
import { USER_AVAIL_SERVICE } from "../../apollo/mutations";
import StateManager from "../../utilities/StateManager";
import { Button } from "semantic-ui-react";
import { mergeCheckoutRequest } from "../../apollo/api";
import { GET_USER_CHECKOUT } from "../../apollo/queries";
import { generateCartFromCheckout } from "../../utilities/helpers";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

const Checkbox = (props) => {
  return (
    <label className={classes.containerCheckbox}>
      {props.service}
      <input
        onChange={props.handleOnChange}
        type="checkbox"
        value={props.service}
        className={classes.checkbox}
      />
      <span className={classes.checkmark}></span>
    </label>
  );
};

const Services = (props) => {
  return (
    <div className={classes.services}>
      {props.services.map(function (service, index) {
        return (
          <Checkbox
            handleOnChange={(e) => props.handleOnChange(e, service)}
            key={index}
            service={service.node.name}
          ></Checkbox>
        );
      })}
    </div>
  );
};

class WaiterService extends Component {
  state = {
    tableNumber: null,
    changeTableNumber: true,
    // get list of services available for the region/store..
    services: [
      "Need drinking water",
      "Need table cleaning",
      "There is an issue with my order",
    ],
    selectedServices: [],
    disable: true,
    success: false,
    open: this.props.open,
    deliveryLocation: false,
    region: null,
  };

  componentDidMount() {
    const stateManager = new StateManager();
    const region = stateManager.region;
    const deliverylocation = `${stateManager.deliveryLocationType || ""} ${
      stateManager.deliveryLocation
    }`;
    this.setState({
      deliveryLocation: deliverylocation,
      region: region,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  closePopup = () => {
    this.props.onClose();
  };

  handleOnChange = (event, value) => {
    if (event.target.checked) {
      console.log(value, "----->>>");
      this.setState(
        {
          selectedServices: [
            ...this.state.selectedServices,
            event.target.value,
          ],
        },
        () => console.log(this.state.selectedServices)
      );
      const { deliveryLocation } = this.state;
      client.mutate({
        mutation: USER_AVAIL_SERVICE(),
        variables: {
          input: { service: value.node.id, comments: deliveryLocation },
        },
      });
    } else {
      var selectedServices = [...this.state.selectedServices]; // make a separate copy of the array
      var index = selectedServices.indexOf(event.target.value);
      if (index !== -1) {
        selectedServices.splice(index, 1);
        this.setState({ selectedServices: selectedServices });
      }
    }
  };

  handleRequestTableService = (event) => {
    // send request for the table service
    // these are the services requested
    console.log(this.state.selectedServices);
    this.setState({
      success: true,
    });
    setTimeout(() => {
      this.props.onClose();
      this.setState({
        success: false,
        selectedServices: [],
      });
    }, 2000);

    // call function to pass the request
    // this.props.handleRequestTableService();
  };

  onMergeCheckout = async () => {
    let checkoutID = await mergeCheckoutRequest();
    checkoutID = checkoutID.checkout_id;
    if (checkoutID) {
      client
        .query({
          query: GET_USER_CHECKOUT(),
          variables: { id: checkoutID },
        })
        .then((res) => {
          console.log("Response for the checkout", res.data.checkout);
          const checkoutFromServer = res.data.checkout;
          const data = generateCartFromCheckout(checkoutFromServer);
          console.log("data ----->", data);
          localStorage.setItem(
            "localCheckoutFromServer",
            JSON.stringify(res.data.checkout)
          );
          this.props.history.push("/cart");
        });
    } else {
      Swal.fire({
        position: "center",
        html: `<p class="textI">No payment pending</p>`,
        showCloseButton: true,
        confirmButtonColor: "#ff5500",
        confirmButtonText: "Okay",
      });
    }
  };

  render() {
    const { success, open, region } = this.state;
    const { serviceList } = this.props;
    const disabled = this.state.selectedServices.length > 0;
    return (
      <div>
        {open && (
          <div>
            <div
              id="bottom-sheet"
              className={classes.overlay}
              //   onClick={this.togglePopup}
            >
              {!success && (
                <aside className={classes.social}>
                  {
                    <h4 style={{ color: "#868686" }}>
                      How can we help?
                      <button
                        className={classes.backButton}
                        onClick={this.closePopup}
                      >
                        Close
                      </button>
                    </h4>
                  }
                  {/* list of service available for that region */}
                  {serviceList && (
                    <Services
                      handleOnChange={this.handleOnChange}
                      services={serviceList}
                    />
                  )}
                  <button
                    disabled={!disabled}
                    className={classes.confirmBtn}
                    onClick={this.handleRequestTableService}
                  >
                    Confirm
                  </button>
                  {region && region.hasMultiCheckoutPayment && (
                    <Button
                      onClick={this.onMergeCheckout}
                      className={classes.pay_button}
                    >
                      Pay Bill
                    </Button>
                  )}
                </aside>
              )}
              {success && (
                <aside className={classes.social}>
                  <SuccessIcon></SuccessIcon>
                  <h4 className={classes.success}>Request Submitted</h4>
                </aside>
              )}
              <a href="#close" className={classes.btnClose} aria-hidden="true">
                <span className={classes.mdi}></span>
                <span className={classes.srOnly}>Close</span>
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(WaiterService);
