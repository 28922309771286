import { gql } from "apollo-boost";
import { ORDER_MODES } from "../utilities/config";

export const GET_AVAILABLE_REGIONS = () => {
  return gql`
    query regions {
      regions(app: true, isOpen: true) {
        edges {
          node {
            id
            app
            kiosk
            name
            welcomeText
            isAirport
            isFidsAvailable
            checkoutHeader
            trendingLogo
            trendingName
            shouldShowTrending
            canShareCheckout
            canChangeOrderMode
            canChangeRegion
            hasMultiCheckoutPayment
            isOnlinePayment
            isPayAtCounter
            productDisplayView
            terminal
            domestic
            appBrandingLogo
            international
            isDeliveryAtSticker
            deliveryScheduleOffset
            city
            appHideGngLogos
            services {
              edges {
                node {
                  id
                  name
                  image
                  status
                }
              }
            }
            supportPhoneNumber
            deliveryLocations {
              edges {
                node {
                  id
                  name
                }
              }
            }
            regionOrderModes {
              edges {
                node {
                  id
                  isOpen
                  isPayOnDelivery
                  status
                  startsAt
                  orderMode {
                    id
                    name
                  }
                }
              }
            }
            image
            delivery
            takeaway
            dinein
          }
        }
      }
    }
  `;
};

export const GET_REGION_FROM_URL = () => {
  return gql`
    query regionFromUrl($customUrl: String!) {
      regionFromUrl(customUrl: $customUrl) {
        id
        app
        kiosk
        name
        welcomeText
        isAirport
        isFidsAvailable
        checkoutHeader
        trendingLogo
        trendingName
        shouldShowTrending
        canShareCheckout
        canChangeOrderMode
        canChangeRegion
        hasMultiCheckoutPayment
        isOnlinePayment
        isPayAtCounter
        productDisplayView
        terminal
        domestic
        appBrandingLogo
        international
        isDeliveryAtSticker
        deliveryScheduleOffset
        city
        appHideGngLogos
        services {
          edges {
            node {
              id
              name
              image
              status
            }
          }
        }
        supportPhoneNumber
        deliveryLocations {
          edges {
            node {
              id
              name
            }
          }
        }
        regionOrderModes {
          edges {
            node {
              id
              isOpen
              isPayOnDelivery
              status
              startsAt
              orderMode {
                id
                name
              }
            }
          }
        }
        image
        delivery
        takeaway
        dinein
      }
    }
  `;
};

export const GET_REGION_DATA = () => {
  return gql`
    query region($forRegion: ID!) {
      region(id: $forRegion) {
        id
        app
        kiosk
        hasMultiCheckoutPayment
        isOnlinePayment
        isPayAtCounter
        appBrandingLogo
        name
        welcomeText
        isAirport
        isFidsAvailable
        checkoutHeader
        trendingLogo
        trendingName
        shouldShowTrending
        canShareCheckout
        canChangeOrderMode
        canChangeRegion
        productDisplayView
        terminal
        domestic
        international
        isDeliveryAtSticker
        deliveryScheduleOffset
        city
        supportPhoneNumber
        appHideGngLogos
        deliveryLocations {
          edges {
            node {
              id
              name
            }
          }
        }
        services {
          edges {
            node {
              id
              name
              image
              status
            }
          }
        }
        regionOrderModes {
          edges {
            node {
              id
              isOpen
              isPayOnDelivery
              status
              startsAt
              orderMode {
                id
                name
              }
            }
          }
        }
        image
        delivery
        takeaway
        dinein
      }
    }
  `;
};

export const GET_POPULAR_FOOD = (mode) => {
  if (mode === "DELIVERY") {
    return gql`
      query stocks($regionId: ID) {
        stocks(
          first: 24
          sortBy: "trending"
          inStock: true
          delivery: true
          forRegion: $regionId
        ) {
          edges {
            node {
              referenceName
              trending
              stockSla
              inStock
              referenceCategory
              categoryDisplayOrder
              id
              delivery
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains
                  }
                }
              }
              sku {
                id
                name
                image
                quantity
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
                noBgUrl
                fontColor
                selectedColor
                mask
                secondaryUrl
                product {
                  id
                  contains
                }
              }
              store {
                id
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
  } else if (mode === "DINEIN" || mode === ORDER_MODES.tableService) {
    return gql`
      query stocks($regionId: ID) {
        stocks(
          first: 24
          sortBy: "trending"
          inStock: true
          dinein: true
          forRegion: $regionId
        ) {
          edges {
            node {
              referenceName
              trending
              stockSla
              inStock
              referenceCategory
              categoryDisplayOrder
              id
              delivery
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains
                  }
                }
              }
              sku {
                id
                name
                image
                quantity
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
                noBgUrl
                fontColor
                selectedColor
                mask
                secondaryUrl
                product {
                  id
                  contains
                }
              }

              store {
                id
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
  }
  return gql`
    query stocks($regionId: ID) {
      stocks(
        first: 24
        sortBy: "trending"
        inStock: true
        takeaway: true
        forRegion: $regionId
      ) {
        edges {
          node {
            referenceName
            trending
            stockSla
            referenceCategory
            categoryDisplayOrder
            id
            inStock
            delivery
            salePrice
            customizations(level: 0) {
              edges {
                node {
                  description
                  id
                  defaultSelected
                  name
                  maxSelectable
                  minSelectable
                  salePrice
                  depth
                  children {
                    edges {
                      node {
                        id
                        defaultSelected
                        maxSelectable
                        salePrice
                        depth
                        name
                        contains
                        minSelectable
                      }
                    }
                  }
                  contains
                }
              }
            }
            sku {
              id
              name
              image
              quantity
              maximumRetailPrice
              maximumRetailPriceCurrency
              description
              noBgUrl
              fontColor
              selectedColor
              mask
              secondaryUrl
              product {
                id
                contains
              }
            }

            store {
              id
              name
              image
              isOpen
              pickupPoints {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_DATA_FOR_REGION = (mode) => {
  if (mode === ORDER_MODES.dineIn) {
    return gql`
      query productCategorySkuStock($storeIds: [ID]) {
        productCategorySkuStock(
          forStores: $storeIds
          inStock: true
          dinein: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              store
              inStock
              stockSalePrice
              quantity
              category
              categoryName
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  } else if (mode === "DELIVERY") {
    return gql`
      query productCategorySkuStock($storeIds: [ID]) {
        productCategorySkuStock(
          forStores: $storeIds
          forStores: $forStores
          inStock: true
          delivery: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              store
              inStock
              stockSalePrice
              quantity
              category
              categoryName
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query productCategorySkuStock($storeIds: [ID]) {
        productCategorySkuStock(
          forStores: $storeIds
          forStores: $forStores
          inStock: true
          takeaway: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              store
              inStock
              stockSalePrice
              quantity
              category
              categoryName
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  }
};

/**
 *
 * @param {String} mode - order mode for which to fetch the stores
 * @param {String} regionId - region id
 */
export const GET_STORES_FOR_REGION = (mode = null) => {
  if (mode === ORDER_MODES.dineIn) {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, dinein: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  } else if (mode === ORDER_MODES.delivery) {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, delivery: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, takeaway: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  }
};

/**
 *
 * @param {String} mode - order mode to get the popular food for
 * @param {String} storeId - store id
 * @param {String} forRegion - Region ID
 * @param {String} searchText - Search text
 */
export const GET_PRODUCT_SKU_FOR_CATEGORY = (mode) => {
  if (mode === ORDER_MODES.dinein || mode === ORDER_MODES.tableService) {
    return gql`
      query productCategorySkuStock(
        $storeId: ID
        $forRegion: ID
        $searchText: String
      ) {
        productCategorySkuStock(
          forStore: $storeId
          forRegion: $forRegion
          searchText: $searchText
          inStock: true
          dinein: true
        ) {
          edges {
            node {
              skuName
              skuId
              store
              storeName
              storeImage
              productName
              productImage
              pickuppoint
              stockSla
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              productDescription
              categoryName
              categoryImage
              categoryStatus
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
              skuMask
              skuCardColor
              skuFontColor
              skuLabel
            }
          }
        }
      }
    `;
  } else if (mode === ORDER_MODES.delivery) {
    return gql`
      query productCategorySkuStock(
        $storeId: ID
        $forRegion: ID
        $searchText: String
      ) {
        productCategorySkuStock(
          forStore: $storeId
          forRegion: $forRegion
          searchText: $searchText
          inStock: true
          delivery: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              productImage
              stockSla
              store
              storeName
              storeImage
              pickuppoint
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              categoryName
              productDescription
              categoryImage
              categoryStatus
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
              skuMask
              skuCardColor
              skuFontColor
              skuLabel
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query productCategorySkuStock(
        $storeId: ID
        $forRegion: ID
        $searchText: String
      ) {
        productCategorySkuStock(
          forStore: $storeId
          forRegion: $forRegion
          searchText: $searchText
          inStock: true
          takeaway: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              productImage
              stockSla
              store
              storeName
              storeImage
              pickuppoint
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              categoryName
              categoryImage
              categoryStatus
              productDescription
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
              skuMask
              skuCardColor
              skuFontColor
              skuLabel
            }
          }
        }
      }
    `;
  }
};

/**
 * @param {String} stockId - stock id for which to fetch the customizations
 */
export const GET_CUSTOMIZATIONS_FOR_STOCK = () => {
  return gql`
    query stock($stockId: ID!) {
      stock(id: $stockId) {
        referenceName
        trending
        id
        inStock
        stockSla
        stockText
        salePrice
        customizations(level: 0, sortBy: "display_order") {
          edges {
            node {
              description
              id
              defaultSelected
              name
              maxSelectable
              minSelectable
              displayOrder
              salePrice
              depth
              children {
                edges {
                  node {
                    id
                    defaultSelected
                    maxSelectable
                    salePrice
                    depth
                    name
                    contains
                    minSelectable
                  }
                }
              }
              contains
            }
          }
        }
        sku {
          id
          name
          image
          product {
            id
            contains
            name
          }
          quantity
          maximumRetailPrice
          maximumRetailPriceCurrency
          description
        }
        store {
          id
          name
          pickupPoints {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `;
};

/**
 * @param {String} productId - product id
 * @param {String} storeId - store id
 */
export const GET_PRODUCT_FOR_ID = () => {
  return gql`
    query product($productId: ID!, $storeId: ID!) {
      product(id: $productId) {
        name
        id
        image
        description
        contains
        category {
          id
          name
        }
        skus(sortBy: "maximum_retail_price") {
          edges {
            node {
              name
              groupLabel
              defaultSelected
              quantity
              id
              stocks(inStock: true, store: $storeId) {
                edges {
                  node {
                    referenceName
                    trending
                    id
                    store {
                      id
                      name
                      pickupPoints {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                    inStock
                    stockSla
                    sku {
                      name
                      id
                      image
                      quantity
                    }
                    stockText
                    salePrice
                    customizations(level: 0, sortBy: "display_order") {
                      edges {
                        node {
                          description
                          id
                          defaultSelected
                          name
                          maxSelectable
                          minSelectable
                          displayOrder
                          salePrice
                          depth
                          children {
                            edges {
                              node {
                                id
                                defaultSelected
                                maxSelectable
                                salePrice
                                depth
                                name
                                contains
                                minSelectable
                              }
                            }
                          }
                          contains
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

/**
 * @param {String} regionId - region id to get configurations for
 */
export const GET_REGION_CONFIG = () => {
  return gql`
    query region($regionId: ID!) {
      region(id: $regionId) {
        id
        name
        delivery
        takeaway
        dinein
        kioskOfferImage
        hasMultiCheckoutPayment
        isOnlinePayment
        isPayAtCounter
        deliveryLocations {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `;
};

/**
 * @param {[String]} forStocks - get customization data for an array of stock ids
 */
export const GET_CUSTOMIZATIONS_FOR_STOCKS = () => {
  return gql`
    query stocks($forStocks: [ID]) {
      stocks(forStocks: $forStocks) {
        edges {
          node {
            id
            inStock
            stockSla
            stockText
            salePrice
            customizations(level: 0, sortBy: "display_order") {
              edges {
                node {
                  description
                  id
                  defaultSelected
                  name
                  maxSelectable
                  minSelectable
                  displayOrder
                  salePrice
                  depth
                  children {
                    edges {
                      node {
                        id
                        defaultSelected
                        maxSelectable
                        salePrice
                        depth
                        name
                        contains
                        minSelectable
                      }
                    }
                  }
                  contains
                }
              }
            }
          }
        }
      }
    }
  `;
};

/**
 * @param {String} store - store id to get pickuppoints for
 */
export const GET_PICKUP_POINTS_FOR_STORE = () => {
  return gql`
    query pickupPoints($store: ID) {
      pickupPoints(store: $store) {
        edges {
          node {
            id
            store {
              id
              name
              image
            }
          }
        }
      }
    }
  `;
};

// To-Do: Query for user profile data
export const GET_USER_PROFILE = () => {
  return gql`
    query userProfile {
      userProfile {
        firstName
        lastName
        user {
          email
          isGhost
          phone
        }
      }
    }
  `;
};

export const GET_LAST_ORDERS = (onlyActive = false) => {
  if (onlyActive) {
    return gql`
      query orders {
        orders(
          status_In: "NEW,PUSHED,READY,ACCEPTED,DISPATCHED,ATDELIVERYPOINT,DELIVERED"
          first: 25
        ) {
          edges {
            node {
              id
              orderId
              status
              createdAt
              updatedAt
              deliveryOtp
              cart {
                id
                total
                subtotal
                extra
                cartSla
                specialInstructions
                pickupPoint {
                  id
                  name
                  sla {
                    id
                    preparationTime
                  }
                  store {
                    id
                    name
                    isDeliveryOtp
                  }
                }
                cartItems {
                  edges {
                    node {
                      id
                      total
                      quantity
                      customizations {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      stock {
                        id
                        stockSla
                        sku {
                          id
                          image
                          name
                          variation
                        }
                      }
                    }
                  }
                }
                checkout {
                  id
                  total
                  subtotal
                  extra
                  deliveryTime
                  deliveryLocation
                  seatNo
                  boardingGate
                  token
                  mode
                  region {
                    id
                    name
                  }
                  payments {
                    edges {
                      node {
                        id
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
  }
  return gql`
    query orders {
      orders(first: 40, status_In: "DELIVERED,REJECTED,PICKEDUP") {
        edges {
          node {
            id
            status
            createdAt
            updatedAt
            cart {
              id
              total
              subtotal
              extra
              cartSla
              specialInstructions
              pickupPoint {
                id
                name
                sla {
                  id
                  preparationTime
                }
                store {
                  id
                  name
                }
              }
              cartItems {
                edges {
                  node {
                    id
                    total
                    customizations {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    quantity
                    stock {
                      id
                      stockSla
                      sku {
                        id
                        image
                        name
                        variation
                      }
                    }
                  }
                }
              }
              checkout {
                id
                total
                subtotal
                extra
                deliveryTime
                deliveryLocation
                token
                mode
                seatNo
                boardingGate
                payments {
                  edges {
                    node {
                      id
                      status
                    }
                  }
                }
                region {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

// export const GET_ORDER_FOR_ID = () => {};

export const GET_REDIRECTION_DATA = () => {
  return gql`
    query redirectionUrl($redirectionId: String!) {
      redirectionUrl(id: $redirectionId) {
        name
        appUrl
        imageUrl
        skipRedirection
        welcomeText
        seatNo
        id
        orderMode
        deliveryLocation
        deliveryLocationType
        canChangeRegion
        canChangeStore
        canChangeLocation
        canChangeOrderMode
        shouldAskCode
        region {
          id
          app
          kiosk
          hasMultiCheckoutPayment
          isOnlinePayment
          isPayAtCounter
          name
          welcomeText
          isAirport
          isFidsAvailable
          checkoutHeader
          trendingLogo
          trendingName
          shouldShowTrending
          canShareCheckout
          canChangeOrderMode
          canChangeRegion
          productDisplayView
          terminal
          appBrandingLogo
          domestic
          international
          isDeliveryAtSticker
          deliveryScheduleOffset
          deliveryLocations {
            edges {
              node {
                id
                name
              }
            }
          }
          city
          appHideGngLogos
          services {
            edges {
              node {
                id
                name
                image
                status
              }
            }
          }
          supportPhoneNumber
          regionOrderModes {
            edges {
              node {
                id
                isOpen
                isPayOnDelivery
                status
                startsAt
                orderMode {
                  id
                  name
                }
              }
            }
          }
          image
          delivery
          takeaway
          dinein
        }
        store {
          id
          name
        }
        stock {
          id
        }
        offerId
        landmark {
          id
          name
        }
      }
    }
  `;
};

export const GET_RECOMMENDATIONS_FOR_CHECKOUT = () => {
  return gql`
    query checkoutRecommendation($userId: ID) {
      checkoutRecommendation(userId: $userId) {
        id
        stock {
          id
          inStock
          referenceName
          trending
          stockSla
          inStock
          referenceCategory
          categoryDisplayOrder
          delivery
          takeaway
          dinein
          salePrice
          customizations(level: 0) {
            edges {
              node {
                description
                id
                defaultSelected
                name
                maxSelectable
                minSelectable
                salePrice
                depth
                children {
                  edges {
                    node {
                      id
                      defaultSelected
                      maxSelectable
                      salePrice
                      depth
                      name
                      contains
                      minSelectable
                    }
                  }
                }
                contains
              }
            }
          }
          sku {
            id
            name
            image
            quantity
            maximumRetailPrice
            maximumRetailPriceCurrency
            description
            noBgUrl
            fontColor
            selectedColor
            mask
            secondaryUrl
            product {
              id
              contains
            }
          }
          store {
            id
            name
            image
            isOpen
            pickupPoints {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_USER_CHECKOUT = () => {
  return gql`
    query checkout($id: ID!) {
      checkout(id: $id) {
        id
        deliveryTime
        deliveryLocation
        seatNo
        status
        mode
        subtotal
        total
        subtotalCurrency
        extra
        user {
          phone
        }
        code
        codeText
        token
        carts {
          edges {
            node {
              id
              cartSla
              total
              subtotal
              specialInstructions
              pickupPoint {
                id
                name
                store {
                  id
                  name
                }
              }
              cartItems {
                edges {
                  node {
                    id
                    subtotal
                    total
                    extra
                    quantity
                    customizations {
                      edges {
                        node {
                          id
                          name
                          price
                          quantity
                        }
                      }
                    }
                    stock {
                      id
                      price
                      inStock
                      sku {
                        id
                        name
                        quantity
                      }
                    }
                  }
                }
              }
              status
              extra
            }
          }
        }
      }
    }
  `;
};

export const GET_USER_ADDRESS = () => {
  return gql`
    query {
      userLocations {
        edges {
          node {
            id
            name
            landmark
            house
            latitude
            longitude
          }
        }
      }
    }
  `;
};

export const GET_OFFER_CODE_DETAILS = () => {
  return gql`
    query offerCode($code: String!) {
      offerCode(code: $code) {
        id
        code
        usedRedemptions
        stockAllowedQuantity
        offerStocks
      }
    }
  `;
};
