import { getDataFromLocalStorage, saveDataToLocalStorage } from "./helpers";

const LOCAL_STORAGE_KEYS = {
  selectedRegionKey: "GNG_USER_SELECTED_REGION", // contains region object
  selectedOrderModeKey: "GNG_USER_SELECTED_ORDER_MODE", //contains order mode string
  selectedFlightKey: "GNG_SELECTED_FLIGHT", // contains selected flight object
  selectedSeatNoKey: "GNG_USER_SELECTED_SEAT_NO", // contains selected seat no string
  selectedTableNoKey: "GNG_USER_SELECTED_TABLE_NO", // selected table no string
  selectedDeliveryTimeKey: "GNG_USER_SELECTED_DELIVERY_TIME", // selected delivery time
  selectedDeliveryLocationKey: "GNG_USER_SELECTED_DELIVERY_LOCATION", // selected delivery location string
  redirectionURLDataKey: "GNG_USER_SCANNED_REDIRECTION_URL_DATA", // scanned redirection url for the user
  checkoutSpecialInstructionsKey: "GNG_USER_SELECTED_SPECIAL_INSTRUCTIONS",
  userProfileKey: "GNG_LOCAL_USER_PROFILE",
  userAuthTokenKey: "GNG_LOCAL_AUTH_TOKEN_KEY",
  userCartLocalKey: "GNG_LOCAL_CART_FOR_USER",
  appConfigurationsKey: "GNG_LOCAL_APP_CONFIGURATION",
  vegonlyConfigKey: "GNG_LOCAL_VEG_ONLY_KEY",
  checkoutFromServerKey: "GNG_CHECKOUT_FROM_SERVER",
  deliveryLocationTypeKey: "GNG_DELIVERY_LOCATION_TYPE_KEY",
  takeAwayDate: "TAKEAWAY_DATE",
  takeAwayTime: "TAKEAWAY_TIME",
};

export default class StateManager {
  /**
   * @type object
   */
  region;
  /**
   * @type string
   */
  orderMode;
  /**
   * @type object
   */
  flight;
  /**
   * @type string
   */
  seatNo;
  /**
   * @type string
   */
  tableNo;
  /**
   * @type Date
   */
  deliveryTime;
  /**
   * @type string
   */
  deliveryLocation;
  /**
   * @type string
   */
  deliveryLocationType;
  /**
   * @type string
   */
  redirectionURL;
  /**
   * @type object
   */
  specialInstructions;
  /**
   * @type object
   */
  userProfile;
  /**
   * @type string
   */
  authToken;
  /**
   * @type object
   */
  carts;
  /**
   * @type number
   */
  totalItem = 0;
  /**
   * @type string
   */
  bookingDate;
  /**
   * @type string
   */
  timeSlot;

  checkoutFromServer;

  appConfig = getDataFromLocalStorage(
    LOCAL_STORAGE_KEYS.appConfigurationsKey
  ) || {
    canChangeOrderMode: true,
    canChangeRegion: true,
    canChangeDeliveryLocation: true,
    canShareCheckout: false,
    productDisplayView: "CARDVIEW",
    isFidsAvailable: false,
    isAirport: false,
    checkoutHeader: "",
    shouldShowTrending: true,
    trendingName: "Trending",
    trendingLogo: "",
    terminal: "",
  };

  /**
   * @type boolean
   */
  isVegOnly = false;
  /**
   * @param {any} data
   */
  get timeSlot() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.takeAwayTime);
  }

  set timeSlot(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.takeAwayTime, data);
  }
  /**
   * @param {any} data
   */
  get bookingDate() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.takeAwayDate);
  }

  set bookingDate(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.takeAwayDate, data);
  }

  get region() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedRegionKey);
  }

  /**
   * @param {any} data
   */
  set region(data) {
    // TO-DO : data validation here for the region
    this.clearDataForRegion();
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.selectedRegionKey, data);
  }

  get orderMode() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedOrderModeKey);
  }

  /**
   * @param {string} data
   */
  set orderMode(data) {
    this.clearDataForOrderMode();
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.selectedOrderModeKey,
      data
    );
  }

  get flight() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedFlightKey);
  }

  /**
   * @param {any} data
   */
  set flight(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.selectedFlightKey, data);
  }

  get seatNo() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedSeatNoKey);
  }

  /**
   * @param {string} data
   */
  set seatNo(data) {
    this.clearDataForDeliveryLocation();
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.selectedSeatNoKey, data);
  }

  get tableNo() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedTableNoKey);
  }

  /**
   * @param {string} data
   */
  set tableNo(data) {
    this.clearDataForDeliveryLocation();
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.selectedTableNoKey, data);
  }

  get deliveryTime() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey);
  }

  /**
   * @param {Date} data
   */
  set deliveryTime(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey,
      data
    );
  }

  get deliveryLocation() {
    return getDataFromLocalStorage(
      LOCAL_STORAGE_KEYS.selectedDeliveryLocationKey
    );
  }

  /**
   * @param {string} data
   */
  set deliveryLocation(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.selectedDeliveryLocationKey,
      data
    );
  }

  get redirectionURL() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.redirectionURLDataKey);
  }

  /**
   * @param {string} data
   */
  set redirectionURL(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.redirectionURLDataKey,
      data
    );
  }

  get specialInstructions() {
    return getDataFromLocalStorage(
      LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey
    );
  }

  /**
   * @param {object} data
   */
  set specialInstructions(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey,
      data
    );
  }

  get userProfile() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.userProfileKey);
  }

  /**
   * @param {object} data
   */
  set userProfile(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.userProfileKey, data);
  }

  get authToken() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.userAuthTokenKey);
  }

  /**
   * @param {string} data
   */
  set authToken(data) {
    this.clearDataForUser();
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.userAuthTokenKey, data);
  }

  get carts() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.userCartLocalKey);
  }

  /**
   * @param {object} data
   */
  set carts(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.userCartLocalKey, data);
  }

  getTotalItemsInCart() {
    let quantityItem = 0;
    if (this.carts) {
      Object.entries(this.carts).forEach(([key, value]) => {
        let pickupPointId = value && value.pickupPoint && value.pickupPoint.id;
        if (pickupPointId) {
          value.cartItems.forEach((item) => {
            quantityItem = quantityItem + item.quantity;
          });
        }
      });
    }
    return quantityItem;
  }

  get totalItem() {
    return this.getTotalItemsInCart();
  }

  /**
   * @param{Number} total
   */
  set totalItem(total) {}

  get appConfig() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.appConfigurationsKey);
  }
  /**
   * @param {Object} data
   */
  set appConfig(data = {}) {
    let appConfigs =
      getDataFromLocalStorage(LOCAL_STORAGE_KEYS.appConfigurationsKey) || {};
    if (data.hasOwnProperty("canChangeRegion")) {
      appConfigs.canChangeRegion = data.canChangeRegion;
    }
    if (data.hasOwnProperty("canChangeOrderMode")) {
      appConfigs.canChangeOrderMode = data.canChangeOrderMode;
    }
    if (data.hasOwnProperty("canChangeDeliveryLocation")) {
      appConfigs.canChangeDeliveryLocation = data.canChangeDeliveryLocation;
    }
    if (data.hasOwnProperty("canShareCheckout")) {
      appConfigs.canShareCheckout = data.canShareCheckout;
    }

    if (data.hasOwnProperty("productDisplayView")) {
      appConfigs.productDisplayView = data.productDisplayView;
    }
    if (data.hasOwnProperty("isFidsAvailable")) {
      appConfigs.isFidsAvailable = data.isFidsAvailable;
    }
    if (data.hasOwnProperty("isAirport")) {
      appConfigs.isAirport = data.isAirport;
    }
    if (data.hasOwnProperty("checkoutHeader")) {
      appConfigs.checkoutHeader = data.checkoutHeader;
    }
    if (data.hasOwnProperty("shouldShowTrending")) {
      appConfigs.shouldShowTrending = data.shouldShowTrending;
    }
    if (data.hasOwnProperty("trendingName")) {
      appConfigs.trendingName = data.trendingName;
    }
    if (data.hasOwnProperty("trendingLogo")) {
      appConfigs.trendingLogo = data.trendingLogo;
    }
    if (data.hasOwnProperty("terminal")) {
      appConfigs.terminal = data.terminal;
    }
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.appConfigurationsKey,
      appConfigs
    );
  }

  get checkoutFromServer() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.checkoutFromServerKey);
  }

  set checkoutFromServer(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.checkoutFromServerKey,
      data
    );
  }

  get isVegOnly() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.vegonlyConfigKey);
  }

  /**
   * @param {Boolean} data
   */
  set isVegOnly(data) {
    return saveDataToLocalStorage(LOCAL_STORAGE_KEYS.vegonlyConfigKey, data);
  }

  get deliveryLocationType() {
    return getDataFromLocalStorage(LOCAL_STORAGE_KEYS.deliveryLocationTypeKey);
  }

  set deliveryLocationType(data) {
    return saveDataToLocalStorage(
      LOCAL_STORAGE_KEYS.deliveryLocationTypeKey,
      data
    );
  }

  /**
   * @description when we want to clear everything that is there on the local storage
   */
  clearLocalData = () => {
    localStorage.clear();
  };

  clearDataForRegion = () => {
    console.log("ckear region data called");
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedOrderModeKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedFlightKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedSeatNoKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedTableNoKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryLocationKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.redirectionURLDataKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userCartLocalKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.takeAwayDate);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.takeAwayTime);
  };

  clearDataForOrderMode = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedFlightKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedSeatNoKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedTableNoKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryLocationKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userCartLocalKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.takeAwayDate);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.takeAwayTime);
  };

  clearDataForFlight = () => {
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedSeatNoKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedTableNoKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryLocationKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.userCartLocalKey);
  };

  clearDataForDeliveryLocation = () => {
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.selectedDeliveryTimeKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutSpecialInstructionsKey);
    // localStorage.removeItem(LOCAL_STORAGE_KEYS.userCartLocalKey);
  };

  /**
   * @description only removes the user auth token and user profile from the local storage
   */
  clearDataForUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userProfileKey);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.userAuthTokenKey);
  };

  clearLocalData = () => {
    try {
      localStorage.removeItem(this.localCartKey);
      localStorage.removeItem(this.localRegionKey);
      localStorage.removeItem(this.localAuthTokenKey);
      localStorage.removeItem(this.localUserProfileKey);
      localStorage.removeItem(this.localProductDataKey);
      localStorage.removeItem(this.localUserPreferenceKey);
      localStorage.removeItem(this.localOrderModeKey);
      return true;
    } catch (error) {
      console.log("Error: clearLocalData", error, "\n");
      return false;
    }
  };

  setLocalRegion = (data) => {
    try {
      localStorage.setItem(this.localRegionKey, JSON.stringify(data));
      this.clearOrderMode();
      this.clearLocalCart();
      this.clearLocalFlightData();
      this.clearLocalUserPreference();
      return true;
    } catch (error) {
      console.log("Error: setLocalRegion", error, "\n");
      return false;
    }
  };

  getLocalRegion = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localRegionKey));
    } catch (error) {
      console.log("Error: getLocalRegion", error, "\n");
      return null;
    }
  };

  clearLocalRegion = () => {
    localStorage.removeItem(this.localRegionKey);
    this.clearOrderMode();
    this.clearLocalCart();
    this.clearLocalFlightData();
    this.clearLocalUserPreference();
    return true;
  };

  setOrderMode = (mode) => {
    localStorage.setItem(this.localOrderModeKey, JSON.stringify(mode));
    this.clearLocalCart();
    this.clearLocalFlightData();
    this.clearLocalUserPreference();
  };

  getOrderMode = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localOrderModeKey));
    } catch (error) {
      console.log("error in getting data", error);
      return null;
    }
  };

  clearOrderMode = () => {
    localStorage.removeItem(this.localOrderModeKey);
    this.clearLocalCart();
    this.clearLocalFlightData();
    this.clearLocalUserPreference();
  };

  setLocalRedirectionData = (data) => {
    try {
      localStorage.setItem(this.localRedirectionDataKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalRedirectionData", error, "\n");
      return false;
    }
  };

  getLocalRedirectionData = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localRedirectionDataKey));
    } catch (error) {
      console.log("Error: getLocalRedirectionData", error, "\n");
      return null;
    }
  };

  clearLocalRedirectionData = () => {
    localStorage.removeItem(this.localRedirectionDataKey);
    return true;
  };

  setLocalCart = (data) => {
    try {
      localStorage.setItem(this.localCartKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalCart", error, "\n");
      return false;
    }
  };

  getLocalCart = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localCartKey));
    } catch (error) {
      console.log("Error: getLocalCart", error, "\n");
      return null;
    }
  };

  clearLocalCart = () => {
    localStorage.removeItem(this.localCartKey);
    return true;
  };

  setLocalAuthToken = (data) => {
    try {
      localStorage.setItem(this.localAuthTokenKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalAuthToken", error, "\n");
      return false;
    }
  };

  getLocalAuthToken = () => {
    try {
      return localStorage.getItem(this.localAuthTokenKey);
    } catch (error) {
      console.log("Error: getLocalAuthToken", error, "\n");
      return null;
    }
  };

  clearLocalAuthToken = () => {
    localStorage.removeItem(this.localAuthTokenKey);
    return true;
  };

  //1

  setLocalUserPreference = (data) => {
    try {
      localStorage.setItem(this.localUserPreferenceKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalUserPreference", error, "\n");
      return false;
    }
  };

  getLocalUserPreference = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localUserPreferenceKey));
    } catch (error) {
      console.log("Error: getLocalUserPreference", error, "\n");
      return null;
    }
  };

  clearLocalUserPreference = () => {
    localStorage.removeItem(this.localUserPreferenceKey);
    return true;
  };

  //2
  setLocalUserProfile = (data) => {
    try {
      localStorage.setItem(this.localUserProfileKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalUserProfile", error, "\n");
      return false;
    }
  };

  getLocalUserProfile = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localUserProfileKey));
    } catch (error) {
      console.log("Error: getLocalUserProfile", error, "\n");
      return null;
    }
  };

  clearLocalUserProfile = () => {
    localStorage.removeItem(this.localUserProfileKey);
    return true;
  };
  //3
  setLocalProductData = (data) => {
    try {
      localStorage.setItem(this.localProductDataKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalProductData", error, "\n");
      return false;
    }
  };

  getLocalProductData = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localProductDataKey));
    } catch (error) {
      console.log("Error: getLocalProductData", error, "\n");
      return null;
    }
  };

  clearLocalProductData = () => {
    localStorage.removeItem(this.localProductDataKey);
    return true;
  };

  getLocalFlightData = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localFlightData));
    } catch (error) {
      console.log("Error: getLocalProductData", error, "\n");
      return null;
    }
  };

  setLocalFlightData = (data) => {
    try {
      localStorage.setItem(this.localFlightData, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalProductData", error, "\n");
      return false;
    }
  };

  clearLocalFlightData = () => {
    localStorage.removeItem(this.localFlightData);
    return true;
  };

  getLocalCheckoutComment = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localCheckoutComment));
    } catch (error) {
      console.log("Error: getLocalProductData", error, "\n");
      return null;
    }
  };

  setLocalCheckoutComment = (data) => {
    try {
      localStorage.setItem(this.localCheckoutComment, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("Error: setLocalProductData", error, "\n");
      return false;
    }
  };

  clearLocalCheckoutComment = () => {
    localStorage.removeItem(this.localCheckoutComment);
    return true;
  };

  setLocalLastCheckoutKey = (data) => {
    try {
      localStorage.setItem(this.localLastCheckoutKey, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("error: setLocalLastCheckoutKey", error, "\n");
    }
  };

  getLocalLastCheckoutKey = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localLastCheckoutKey));
    } catch (error) {
      console.log("Error: getLocalLastCheckoutKey", error, "\n");
      return null;
    }
  };

  clearLocalLastCheckoutKey = () => {
    localStorage.removeItem(this.localLastCheckoutKey);
    localStorage.removeItem(this.localSpecialInstructions);
    return true;
  };

  setLocalLastSpecailInstructions = (data) => {
    try {
      localStorage.setItem(this.localSpecialInstructions, JSON.stringify(data));
      return true;
    } catch (error) {
      console.log("error: setLocalLastSpecailInstructions", error, "\n");
    }
  };

  getLocalLastSpecailInstructions = () => {
    try {
      return JSON.parse(localStorage.getItem(this.localSpecialInstructions));
    } catch (error) {
      console.log("Error: getLocalLastSpecailInstructions", error, "\n");
      return null;
    }
  };

  clearLocalLastSpecailInstructions = () => {
    localStorage.removeItem(this.localSpecialInstructions);
    return true;
  };

  _areArrayEqual = (array1 = [], array2 = []) => {
    if (array1.length !== array2.length) {
      return false;
    }
    let a1 = JSON.stringify(array1.sort());
    let a2 = JSON.stringify(array2.sort());
    if (a1 === a2) {
      return true;
    }
    return false;
  };
}
