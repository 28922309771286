import React, { PureComponent } from "react";
import classes from "./TopNav.module.css";
import { withRouter } from "react-router";
import { ReactComponent as UserOrangeIcon } from "../../assets/user.svg";
import { ReactComponent as UserWhiteIcon } from "../../assets/user.svg";
import { ReactComponent as TakeAway } from "../../assets/takeaway.svg";
import { ReactComponent as DineIn } from "../../assets/dinein.svg";
import { ReactComponent as Delivery } from "../../assets/delivery.svg";
import { FormPrevious } from "grommet-icons";
import StateManager from "../../utilities/StateManager";
import GlobalStore from "../../utilities/GlobalState";
import { ReactComponent as SearchIcon } from "../../assets/srch.svg";
// import { ReactComponent as UserOrangeIcon } from "../../assets/user_orange.svg";

const stateManager = new StateManager();

const renderLogo = (mode) => {
  if (mode === "DELIVERY") {
    return <Delivery height={28} width={28} fill={"#FFFFFF"} />;
  } else if (mode === "TAKEAWAY") {
    return <TakeAway height={28} width={28} fill={"#FFFFFF"} />;
  }
  return <DineIn height={28} width={28} fill={"#FFFFFF"} />;
};

class TopNav extends PureComponent {
  mounted = false;
  state = {
    region: null,
    currentOrderMode: null,
    showSearch: false,
    showUserIcon: true,
    showSearchIcon: false,
  };

  componentDidMount() {
    this.mounted = true;
    const region = stateManager.region;
    const orderMode = stateManager.orderMode;
    const { pathname } = this.props.location;
    console.log("Pathname", region);
    let showSearch = false;
    let showSearchIcon = false;
    let showUserIcon = false;
    if (pathname.includes("/store") || pathname.includes("/search")) {
      showSearchIcon = true;
      showUserIcon = false;
    } else {
      showSearchIcon = false;
      showUserIcon = true;
    }
    this.setState({
      showSearchIcon: showSearchIcon,
      showUserIcon: showUserIcon,
    });
    this.mounted &&
      this.setState(
        {
          region: region,
          currentOrderMode: orderMode,
          showSearch: showSearch,
        },
        () => {}
      );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log("Path has changed", this.props.location.pathname);
      const { pathname } = this.props.location;
      let showSearchIcon = false;
      let showUserIcon = false;
      if (pathname.includes("/store") || pathname.includes("/search")) {
        showSearchIcon = true;
        showUserIcon = false;
      } else {
        showSearchIcon = false;
        showUserIcon = true;
      }
      this.setState({
        showSearchIcon: showSearchIcon,
        showUserIcon: showUserIcon,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  orderModeChangeHandler = (msg, data) => {
    console.log("Data", data);
    this.setState({
      currentOrderMode: data.mode,
    });
  };

  onBackclick = () => {
    this.mounted && this.props.history.goBack();
  };

  onChangeRegion = () => {
    this.props.history.push("");
  };

  onCartClick = () => {
    console.log("props", this.props);
    if (
      this.props.location.pathname &&
      this.props.location.pathname.includes("/cart")
    ) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/cart");
    }
  };

  goToUserProfile = (e) => {
    if (
      this.props.location.pathname &&
      this.props.location.pathname.includes("/user")
    ) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/user");
    }
  };

  onSearchClick = () => {
    this.props.history.push("/search");
  };

  handleOnChange = (event) => {
    console.log(event.target.value);
  };

  render() {
    const {
      hideOrderLogo = false,
      hidebackbutton = false,
      // hideCartbutton = false,
      hideRegionName = false,
      isTransparent = false,
    } = this.props;
    const {
      region,
      currentOrderMode,
      showUserIcon,
      showSearchIcon,
    } = this.state;
    return (
      <header
        style={isTransparent ? { backgroundColor: "transparent" } : {}}
        className={classes.maindiv}
      >
        <GlobalStore.Consumer>
          {({ ordermode }) => {
            return (
              <>
                <div className={classes.firstdiv}>
                  {!hideOrderLogo && currentOrderMode && renderLogo(ordermode)}
                  {!hidebackbutton && (
                    <FormPrevious
                      onClick={this.onBackclick}
                      height={20}
                      width={20}
                      color={"#FFFFFF"}
                    />
                  )}
                </div>
                {
                  <div className={classes.seconddiv}>
                    {!hideRegionName && (
                      <p onClick={this.onChangeRegion}>
                        {region && region.name ? region.name : "Select region"}
                      </p>
                    )}
                  </div>
                }
                {!hideOrderLogo && showUserIcon && (
                  <div className={classes.thirddiv}>
                    <button onClick={this.goToUserProfile}>
                      {this.state.selected === "user" ? (
                        <UserOrangeIcon height={20} width={20} fill={"red"} />
                      ) : (
                        <UserWhiteIcon height={20} width={20} fill={"red"} />
                      )}
                    </button>
                  </div>
                )}
                {!hideOrderLogo && showSearchIcon && (
                  <div className={classes.thirddiv}>
                    <button onClick={this.onSearchClick}>
                      <SearchIcon height={20} width={20} />
                    </button>
                  </div>
                )}
              </>
            );
          }}
        </GlobalStore.Consumer>
      </header>
    );
  }
}

export default withRouter(TopNav);
