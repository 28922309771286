import React from "react";
import classes from "./Wallets.module.css";
import { PAYMENT_MODE_DISPLAY_NAMES, PAYMENT_MODE_CONSTANTS } from "./config";

const Wallets = ({ availableWallets = {}, onSubmit }) => {
  const onWalletClick = name => {
    onSubmit &&
      onSubmit({ method: PAYMENT_MODE_CONSTANTS.wallet, wallet: name });
  };
  return (
    <div className={classes.maindiv}>
      <p className={classes.heading}>Wallets</p>
      <ul className={classes.wallet_list}>
        {Object.entries(availableWallets).map(([name, isvalid], index) => {
          if (isvalid) {
            return (
              <li onClick={() => onWalletClick(name)} key={index}>
                {PAYMENT_MODE_DISPLAY_NAMES[name]}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default Wallets;
