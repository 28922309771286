// Available payment modes
export const PAYMENT_MODES = [
  "card",
  "upi",
  "netbanking",
  "wallet",
  "emi",
  "cardless_emi",
  "paylater",
  "emandate"
];

export const PAYMENT_MODE_CONSTANTS = {
  card: "card",
  upi: "upi",
  netbanking: "netbanking",
  wallet: "wallet",
  emi: "emi",
  cardless_emi: "cardless_emi",
  paylater: "paylater",
  emandate: "emandate"
};

export const PAYMENT_MODE_DISPLAY_NAMES = {
  wallet: "Wallet",
  card: "Credit, Debit & ATM Cards",
  netbanking: "Netbanking",
  upi: "UPI",
  airtelmoney: "Airtel Money",
  amazonpay: "Amazon Pay",
  freecharge: "Freecharge",
  jiomoney: "Jiomoney",
  phonepe: "PhonePe",
  mobikwik: "Mobikwik",
  payzapp: "PayZapp",
  upi_intent: "UPI Apps",
  emi: "EMI",
  prepaid_card: "Other Cards"
};
