import { IS_SMALL_SCREEN_DEVICE } from "../utilities/config";

// const minReqHeight = 780;
const availTrendingHeight = window.screen.availHeight - 220;
const ShortTrendingCardHeight = availTrendingHeight * 0.4;
const LongTrendingCardHeight = IS_SMALL_SCREEN_DEVICE
  ? availTrendingHeight - 20
  : availTrendingHeight * 0.6;

export const mainBG = "#0f1f39";

export const headerCss = {
  height: 50
};
export const bottomBar = {
  height: 50
};
export const boxtheme = {
  short: {
    height: ShortTrendingCardHeight,
    width: window.screen.width * 0.45 > 240 ? 240 : window.screen.width * 0.45
  },
  long: {
    height: LongTrendingCardHeight,
    width: window.screen.width * 0.45 > 240 ? 240 : window.screen.width * 0.45
  }
};

export const colorArray = [
  "#7090af",
  "#12386c",
  "#ed1615",
  "#d9cbc8",
  "#0372c9",
  "#e4debb",
  "#43301c",
  "#8c4023",
  "#c6b166",
  "#7090af",
  "#12386c",
  "#ed1615",
  "#d9cbc8",
  "#0372c9",
  "#e4debb",
  "#43301c",
  "#8c4023",
  "#c6b166"
];

export const svgShapes = {
  0: require("../assets/two.svg"),
  1: require("../assets/empty.svg"),
  2: require("../assets/two.svg"),
  3: require("../assets/empty.svg"),
  4: require("../assets/empty.svg"),
  5: require("../assets/empty.svg"),
  6: require("../assets/two.svg"),
  7: require("../assets/empty.svg"),
  8: require("../assets/empty.svg"),
  9: require("../assets/six.svg"),
  10: require("../assets/six.svg"),
  11: require("../assets/empty.svg"),
  12: require("../assets/eight.svg"),
  13: require("../assets/nine.svg"),
  14: require("../assets/empty.svg"),
  15: require("../assets/ten.svg"),
  16: require("../assets/empty.svg")
};

export const generateRandomShape = () => {
  const randomNo = Math.floor(Math.random() * 16) % 9;
  return svgShapes[randomNo];
};
