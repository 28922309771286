import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputBase,
  Button,
  Typography,
} from "@material-ui/core";
import classes from "./ApplyCode.module.css";

const ApplyCode = ({
  code = "",
  codeText = "",
  onApplyCouponCode = () => {},
  ...props
}) => {
  const [couponCode, setCouponCode] = useState(code);

  const onCouponCodeChange = (e) => {
    let text = e.currentTarget.value;
    text = text.toUpperCase();
    setCouponCode(text);
  };

  const onApplyCode = () => {
    onApplyCouponCode(couponCode);
  };

  const onClearCode = () => {
    setCouponCode("");
    onApplyCouponCode("");
  };

  console.log("Code for aoupin", code);

  return (
    <Card className={classes.main}>
      <CardHeader subheader="Apply coupon"></CardHeader>
      <CardContent>
        <div className={classes.input_div}>
          <InputBase
            value={couponCode}
            onChange={onCouponCodeChange}
            className={classes.input}
            placeholder="enter coupon code..."
            inputProps={{ "aria-label": "search google maps" }}
          />
          {(code.length === 0 || code !== couponCode) && (
            <Button
              variant="contained"
              color="Red"
              type="submit"
              disableElevation
              className={classes.apply_button}
              aria-label="search"
              onClick={onApplyCode}
            >
              Apply
            </Button>
          )}
          {code.length > 0 && code === couponCode && (
            <Button
              variant="contained"
              type="submit"
              disableElevation
              className={classes.apply_button}
              aria-label="search"
              onClick={onClearCode}
            >
              Clear
            </Button>
          )}
        </div>
        {codeText && codeText.length > 0 && <Typography>{codeText}</Typography>}
      </CardContent>
    </Card>
  );
};

export default ApplyCode;
