import React, { PureComponent } from "react";
import classes from "./AuthenticationModal.module.css";
import CountryPicker from "../CountryPicker/CountryPicker";
import { verifyAuthLocal } from "../../utilities/helpers";
import { verifyOtp, directLogin } from "../../apollo/api";
import { GET_USER_PROFILE } from "../../apollo/queries";
// import swal from "sweetalert";
import client from "../../apollo/client";
import Spinner from "react-spinkit";
import Swal from "sweetalert2";
import StateManager from "../../utilities/StateManager";

class AuthenticationModal extends PureComponent {
  state = {
    otpReceived: false,
    phone: "",
    countryCode: "+91",
    otp: "",
    otpVerified: false,
    loading: false,
    authVerified: verifyAuthLocal,
    isIos: false,
    isLoading: false,
  };

  // };
  /**
   * request the OTP for the provided number
   */
  _onPressNext = () => {
    const { countryCode, phone } = this.state;
    const stateManager = new StateManager();
    if (phone.length) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          // countryCode !== "+91" &&
          directLogin(`${countryCode}${phone}`)
            .then(async (res) => {
              console.log("Response for OTP", res.success);
              if (res.success) {
                const token = res.token;
                this.setState(
                  { otpVerified: true, authVerified: true, isLoading: false },
                  () => {
                    Swal.fire({
                      title: "Logged in",
                      customClass: {
                        confirmButton: classes.swal_close_button,
                      },
                    });
                    stateManager.authToken = token;
                  }
                );
                const data = await client.query({
                  query: GET_USER_PROFILE(),
                });
                stateManager.userProfile = data.data.userProfile;
                this.props.onHideModal();
                this.props.onAuthVerified();
              }
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
              });
              console.log("Error in getting the OTP", err);
            });
          // countryCode === "+91" &&
          //   getOtp(`${countryCode}${phone}`)
          //     .then(res => {
          //       console.log("Response for OTP request", res);
          //       if (res && res.success) {
          //         this.setState({
          //           otpReceived: true,
          //           isLoading: false
          //         });
          //       }
          //     })
          //     .catch(err => {
          //       this.setState({
          //         isLoading: false
          //       });
          //       console.log("error in getting the OTP", err);
          //     });
        }
      );
    } else {
      Swal.fire({
        text: "Please enter a valid phone number",
        title: "Error",
        icon: "error",
        confirmButtonColor: "rgba(229, 62, 36, 1)",
      });
    }
  };
  _onChangeOtp = (e) => {
    this.setState({ otp: e.target.value });
  };

  /** handle back press on otp screen */
  _onBackOtp = () => {
    this.setState({
      otpReceived: false,
    });
  };

  /**
   * Submit Otp for verification
   */
  _onSubmitOtp = async () => {
    const { otp, phone, countryCode } = this.state;
    const stateManager = new StateManager();
    this.setState(
      {
        isLoading: true,
      },
      () => {
        verifyOtp(`${countryCode}${phone}`, otp)
          .then((res) => {
            if (res.success) {
              console.log("Verification successful");
              const token = res.token;
              Swal.fire({
                title: "Success",
                customClass: {
                  confirmButton: classes.swal_close_button,
                },
              }).then(async () => {
                this.setState(
                  { otpVerified: true, authVerified: true, isLoading: false },
                  () => {
                    stateManager.authToken = token;
                  }
                );
                const data = await client.query({
                  query: GET_USER_PROFILE(),
                });

                stateManager.userProfile = data.data.userProfile;
                this.props.onHideModal();
                this.props.onAuthVerified();
              });
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            console.log("error in otp verification", err);
          });
      }
    );
  };

  /**
   * Handle the phone no change
   */
  _onPhonechange = (phone) => {
    this.setState({
      phone: phone,
    });
  };

  /**
   * handle the country code
   */
  _onCountryCodeChange = (code) => {
    this.setState({
      countryCode: code,
    });
  };

  _onClose = () => {
    this.props.onHideModal();
  };

  render() {
    const { otpReceived, phone, otp, countryCode, isLoading } = this.state;
    const { showModal } = this.props;
    if (showModal) {
      return (
        // <div className={classes.overlay_div} />
        <div className={classes.customization_modal}>
          {isLoading && (
            <div className={classes.overlaydiv}>
              {" "}
              <Spinner
                style={{ marginBottom: 16 }}
                name="folding-cube"
                color={"#e63e25"}
              />
              <p>Loading...</p>
            </div>
          )}
          <button onClick={this._onClose} className={classes.close}>
            <img
              height={"30px"}
              alt=""
              width={"30px"}
              style={{ objectFit: "cover" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGCSURBVGhD7ZjJSsNgFEarblVwngVfxAk3zhtfwrUvoIiIiIgT+DwOKycQZxSfwoUrPV/thZ/i0GpM/sA9cGhyoeE/JSRpCo7jOI7jOE6U1JY+k+Q/jvktc3iCbcW9ZBjEaxwo7qVAHSriDS8xiRhFvKCOuahBWrTiBSYRM4QWsYc1mCphzBW2Y7UMY6YRRgueoxai87sDKyWM2MXMIozfxIygRexg5hFGM1rMDXbiV4yiRWxjNBGGYs5QC7zFLiwnjNjC6CKMMOYOw5gxzEWE0YRhTDeGEZsYfYShmFPUwp8xlxGGYnQVU4Dcx1wyjq9oIffYg7liAi1C94nj0vYD5iZmEi1iQwNoxDCmF6MmjFjXIEAxRxh9zBRaxJoGnxDGPGJ0MdP4U4TRgGFMH0ZBGLGqQQVEFzODFrGiQRUo5hD13SfMLGYWfxthlMf0Y6qEEcsa/IF6zCRGLx/sv8eSBgmgmANM4oepCr1smP/YTAzFLGDuHiodx3Ecx3GcXFAovAOKRnlJxR5NYwAAAABJRU5ErkJggg=="
            />
          </button>
          <div className={classes.headerDiv}>
            <h3>Login to continue</h3>
          </div>
          {!otpReceived && (
            <div>
              <div className={classes.AuthHeadingDiv}>
                <CountryPicker
                  onCountryChange={this._onCountryCodeChange}
                  onPhoneChange={this._onPhonechange}
                  code={countryCode}
                  phone={phone}
                />
              </div>
              <div className={classes.AuthNextButton}>
                <button
                  className={classes.auth_button}
                  onClick={this._onPressNext}
                >
                  {/* {countryCode === "+91" ? "Next" : "Login"} */}
                  Login
                </button>
              </div>
            </div>
          )}
          {otpReceived && (
            <div>
              <div className={classes.AuthHeadingDiv}>
                <input
                  value={otp}
                  onChange={this._onChangeOtp}
                  placeholder="Enter 6 digit OTP"
                  className={classes.AuthOtpInput}
                />
              </div>
              <div>
                <button
                  className={classes.ResendButton}
                  onClick={this._onPressNext}
                  variant="outline-success"
                >
                  Resend OTP
                </button>
              </div>
              <div className={classes.AuthNextButton}>
                <button
                  className={classes.auth_button}
                  onClick={this._onSubmitOtp}
                >
                  Submit
                </button>
                <button
                  className={classes.auth_button}
                  variant="outlined"
                  onClick={this._onBackOtp}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

// {!otpReceived && (
//   <p className="AuthPhoneText">Enter your phone number to continue</p>
// )}
// {otpReceived && (
//   <p style={{ fontSize: "14px" }} className="AuthPhoneText">
//     Please enter the OTP sent to {phone}
//   </p>
// )}

export default AuthenticationModal;
