import React, { useState, useContext } from "react";
import classes from "./RedirectionCodeInput.module.css";
import { Button, Header, Portal, Segment } from "semantic-ui-react";
// import Swal from "sweetalert2";
import { AppConfigContext } from "../../data/AppConfiguration";
import client from "../../apollo/client";
import { GET_OFFER_CODE_DETAILS } from "../../apollo/queries";

const RedirectionCodeInput = ({ open = false, onClose = () => {} }) => {
  const [code, setCode] = useState("");
  const { setOfferDetails, setUserCode } = useContext(AppConfigContext);
  const [hasError, setError] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [offerData, setOfferData] = useState(null);

  const onPopClose = () => {
    onClose();
  };
  const onCodeChange = (e) => {
    let text = e.currentTarget.value;
    text = text.toUpperCase();
    setCode(text);
  };

  const onCodeSubmit = async () => {
    toggleLoading(true);
    if (code.length > 0) {
      try {
        const result = await client.query({
          query: GET_OFFER_CODE_DETAILS(),
          variables: { code: code },
        });
        if (result.data.offerCode) {
          setOfferData(result.data.offerCode);
          setOfferDetails(result.data.offerCode);
          toggleLoading(false);
          setUserCode(code);
          onConfirm();
        } else {
          throw new Error("Invalid coupon code");
        }
      } catch (error) {
        setOfferData(null);
        setOfferDetails(null);
        toggleLoading(false);
        setError(true);
      }
    } else {
      toggleLoading(false);
      setError(true);
    }
  };

  const onConfirm = () => {
    onClose();
  };

  const onEditCode = () => {
    setError(false);
  };

  return (
    <Portal
      size="fullscreen"
      closeOnEscape
      closeOnDimmerClick
      centered
      basic
      dimmer
      className={classes.main}
      open={open}
      onClose={onPopClose}
    >
      <div
        id="bottom-sheet"
        className={classes.overlay}
        //   onClick={this.togglePopup}
      >
        {!isLoading && !offerData && !hasError && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 16,
                width: "100%",
                marginTop: 16,
              }}
            >
              <Header
                as="p"
                style={{
                  color: "#868686",
                  textAlign: "left",
                  width: "60%",
                  fontSize: 14,
                }}
              >
                Enter PNR Code
              </Header>
              <Button
                style={{ height: 30, width: 30, backgroundColor: "silver" }}
                onClick={onClose}
              >
                <p style={{ fontSize: 16 }}>X</p>
              </Button>
            </div>
            <input
              className={classes.input}
              value={code}
              type="text"
              placeholder={"PNR code...."}
              onChange={onCodeChange}
            />
            {hasError && (
              <p style={{ color: "red", fontSize: 10 }}>
                {"Please enter a valid code."}
              </p>
            )}
          </div>
        )}
        {!isLoading && !offerData && !hasError && (
          <Button onClick={onCodeSubmit} className={classes.confirmBtn}>
            Confirm
          </Button>
        )}
        {hasError && (
          <Segment basic className={classes.loading_div}>
            <h3>Invalid Code</h3>
            <Button onClick={onEditCode} className={classes.change_btn}>
              Edit Code
            </Button>
            <p>OR</p>
            <Button onClick={onConfirm} className={classes.confirmBtn}>
              Proceed
            </Button>
          </Segment>
        )}
        {isLoading && (
          <div className={classes.loading_div}>
            <div className={classes.loader}></div>
            <p> Fetching offer details</p>
          </div>
        )}
      </div>
    </Portal>
  );
};

export default RedirectionCodeInput;
