import StateManager from "./StateManager";
import TagManager from "react-gtm-module";

const localState = new StateManager();

export default class CartManager {
  addStockToCart = (
    pickupPoint = { id: "", name: "" },
    stock = { id: "", name: "", price: 0 },
    quantity = 1,
    customization = []
  ) => {
    let carts = localState.carts || {};
    const pickupPointId = pickupPoint.id;
    try {
      if (!carts || !carts[pickupPointId]) {
        // creating a  cart for pickupPoint
        const cart = {
          pickupPoint: pickupPoint,
          cartItems: [
            {
              stockId: stock.id,
              stockName: stock.name,
              quantity: quantity,
              customization: customization,
              price: stock.price,
            },
          ],
        };
        carts[pickupPointId] = cart;
      } else {
        let pickupPointCart = carts[pickupPointId];
        let ppCartItems = pickupPointCart.cartItems;
        // get the cart items with stock id  equal to stock id in args
        let stockIndex = ppCartItems.findIndex(
          (item) =>
            item.stockId === stock.id &&
            localState._areArrayEqual(customization, item.customization)
        );
        // does item exist in the cartItems ? cool, lets increase the quantity by one
        if (stockIndex > -1) {
          let stockItem = ppCartItems[stockIndex];
          stockItem.quantity = stockItem.quantity + quantity;
        } else {
          let stockItem = {
            stockId: stock.id,
            stockName: stock.name,
            quantity: quantity,
            customization: customization,
            price: stock.price,
          };
          ppCartItems.push(stockItem);
        }
        // update the cart entry for pickupPoint cart
        pickupPointCart.cartItems = ppCartItems;
        carts[pickupPointId] = pickupPointCart;
      }
      localState.carts = carts;
      if (
        localState.region &&
        localState.region.city === "Bengaluru" &&
        localState.region.isAirport
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: "Add To Cart",
            stock: stock.name,
            store: pickupPoint.name,
            price: stock.price,
          },
        });
      }
      localStorage.removeItem("localCheckoutFromServer");
      return true;
    } catch (error) {
      console.log("Error: addStockToCart", error, "\n");
      return false;
    }
  };

  removeStockFromCart = (pickupPointId, stockId, customization = []) => {
    let carts = localState.carts;
    console.log(
      "ITEM FOR REMOVAL ----->",
      pickupPointId,
      stockId,
      customization
    );
    if (carts && carts[pickupPointId]) {
      //
      let pickupPointCart = carts[pickupPointId];
      let ppCartItems = pickupPointCart.cartItems;
      // see if the stock exists for the item
      const stockIndex = ppCartItems.findIndex(
        (item) =>
          item.stockId === stockId &&
          localState._areArrayEqual(item.customization, customization)
      );
      // if item exists then only we can remove it
      let stockItem = ppCartItems[stockIndex];
      try {
        console.log("Item for removal", stockItem);
        if (stockItem.quantity === 1) {
          ppCartItems = ppCartItems.filter((item) => {
            if (item.stockId === stockId) {
              return !localState._areArrayEqual(
                item.customization,
                customization
              );
            } else {
              return true;
            }
          });
          pickupPointCart.cartItems = ppCartItems;
          carts[pickupPointId] = pickupPointCart;
        } else if (stockItem.quantity >= 1) {
          stockItem.quantity = stockItem.quantity - 1;
          ppCartItems[stockIndex] = stockItem;
          pickupPointCart.cartItems = ppCartItems;
          carts[pickupPointId] = pickupPointCart;
        } else {
          return false;
        }
        localState.carts = carts;
        localStorage.removeItem("localCheckoutFromServer");
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  getAdddedStocksForProduct = (stockIds = [], pickupPointId) => {
    const carts = localState.carts;
    if (carts) {
      const pickupPointData = carts[pickupPointId];
      const cartItems = pickupPointData ? pickupPointData.cartItems : null;
      const productData = cartItems
        ? cartItems.filter((item) => stockIds.indexOf(item.stockId) > -1)
        : [];
      return productData;
    }
    return [];
  };

  createCheckoutFromCarts = () => {
    const carts = localState.carts;
    console.log("local carts", carts);
    let cartForCheckout = [];
    if (carts) {
      Object.entries(carts).forEach(([key, value]) => {
        let pickupPointId = value && value.pickupPoint && value.pickupPoint.id;
        if (pickupPointId) {
          let cartItems = value.cartItems.map((item) => {
            return {
              stockId: item.stockId,
              customizationIds: item.customization,
              quantity: item.quantity,
            };
          });
          cartForCheckout.push({ pickupPointId, cartItems });
        }
      });
    }
    return cartForCheckout;
  };

  generateCustomizationConfiguration = (stock) => {
    const customization = stock.customizations.edges;
    console.log("Customizations", customization);
    let customConfig = {};
    customization.forEach((it) => {
      const { node: item } = it;
      const name = item.name;
      const displayOrder = item.displayOrder;
      const id = item.id;
      const maxSelectable = item.maxSelectable;
      const minSelectable = item.minSelectable;
      const showRadio =
        maxSelectable === minSelectable && maxSelectable === 1 ? true : false;
      const selected = [];
      const available = [];
      item.children.edges.forEach((cus) => {
        const { node: cust } = cus;
        const custId = cust.id;
        const custName = cust.name;
        const salePrice = cust.salePrice;
        const contains = cust.contains;
        if (cust.defaultSelected) {
          selected.push(custId);
        }
        available.push({ id: custId, name: custName, salePrice, contains });
      });
      const isEditable =
        selected.length === maxSelectable && selected.length === minSelectable
          ? true
          : false;
      customConfig[id] = {
        id,
        name,
        displayOrder,
        maxSelectable,
        minSelectable,
        showRadio,
        selected,
        available,
        isEditable,
      };
    });
    console.log("Customization config for the stocks", customConfig);
    return customConfig;
  };

  getTotalItemsInCart = () => {
    let quantity = 0;
    const carts = localState.carts || {};
    Object.entries(carts).forEach(([key, value]) => {
      let pickupPointId = value && value.pickupPoint && value.pickupPoint.id;
      if (pickupPointId) {
        value.cartItems.forEach((item) => {
          quantity = quantity + item.quantity;
        });
      }
    });
    return quantity;
  };
}
