import React, { PureComponent } from "react";
import classes from "./ScreenLayout.module.css";
import TopNav from "../../components/TopNav/TopNav";
import BottomNav from "../../components/BottomNav/BottomNav";
import GlobalStore from "../../utilities/GlobalState";

/**
 * Screen layout to arrange things into the explore router
 */
class ScreenLayout extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <GlobalStore.Consumer>
        {({ handleCheckoutModal, screenDimensions }) => (
          <div
            style={{
              height: screenDimensions.height,
              width: screenDimensions.width
            }}
            className={classes.maindiv}
          >
            <TopNav closeCheckout={handleCheckoutModal} hidebackbutton />
            <div
              style={{
                height: screenDimensions.height - 106,
                width: screenDimensions.width
              }}
              className={classes.content_div}
            >
              {children}
            </div>
            <BottomNav closeCheckout={handleCheckoutModal} />
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default ScreenLayout;
