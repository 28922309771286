import React, { Component } from "react";
import Spinner from "react-spinkit";
import { Alert } from "grommet-icons";
import SwipeableViews from "react-swipeable-views";
import {
  handleProductData,
  makeCouples,
  filterProductsForCategory,
} from "../../utilities/helpers";
import ProductCard from "../../components/ProductCard/ProductCard";
import classes from "./StoreScreen.module.css";
import GlobalStore from "../../utilities/GlobalState";

import {
  IS_SMALL_SCREEN_DEVICE,
  ORDER_MODES,
  GHOST_TOKEN,
  PAYTM_RESPONSE_ORIGIN_URL,
  GNGCF_URL,
} from "../../utilities/config";
import StateManager from "../../utilities/StateManager";
import WaiterService from "../../components/WaiterService/WaiterService";
import { AppConfigContext } from "../../data/AppConfiguration";
import { Buzzer } from "../TrendingScreen/TrendingScreen";


const filterRegionServices = (region) => {
  let services = []
  if(region && region.services &&
    region.services.edges.length > 0){
      services = region.services.edges.filter((service) => service && service.node.status === 'ACTIVE')
    }
    else{
      services = []
    }
    return services
}

class StoreScreen extends Component {
  static contextType = AppConfigContext;
  mounted = false;
  state = {
    activeCat: null,
    products: [],
    categories: [],
    isLoading: false,
    hasError: false,
    errorMessage: "",
    storeData: null,
    index: 0,
    canCallWaiter: false,
    regionServices: [],
    isWaiterCalled: false,
  };
  componentDidMount() {
    const { selectedRegion } = this.context;
    this.mounted = true;
    const services = filterRegionServices(selectedRegion)
    this.setState(
      {
        isLoading: true,
        hasError: false,
        errorMessage: false,
        products: [],
        categories: [],
        activeCat: null,
        index: 0,
        storeData: null,
        canCallWaiter:services.length > 0,
        regionServices:services,
      },
      () => {
        this.getDataForStore();
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(
        {
          isLoading: true,
          hasError: false,
          errorMessage: false,
          products: [],
          categories: [],
          activeCat: null,
          index: 0,
          storeData: null,
        },
        () => {
          this.getDataForStore();
        }
      );
    }
  }

  getDataForStore = async () => {
    const stateManager = new StateManager();
    // const orderMode = stateManager.orderMode;
    const { id: storeId } = this.props.match.params;
    const { activeCat: cat } = this.state;
    const token = stateManager.authToken || GHOST_TOKEN;
    
    try {
/*      let data = await fetch(
        `${PAYTM_RESPONSE_ORIGIN_URL}/api/products/menu?store_id=${storeId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      data = await data.json();
      
      if (data.length > 0) {
        const { activeCat, products, categories } = handleProductData(
          data,
          cat
        );
*/
        let data = await fetch(`${GNGCF_URL}/cf_get_productcategoryskustock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({"store_id":`${storeId}`, "graphql":true})
        } 
        );
        data = await data.json();
        console.log('DATA from CF', data)

        if (data.result.length > 0) {
          const { activeCat, products, categories } = handleProductData(
            data.result,
            cat
          );       
        if (products.length >= 0) {
          this.setState({
            activeCat: activeCat,
            products: products,
            categories: categories,
            isLoading: false,
            hasError: false,
          });
        }
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        products: [],
        categories: [],
        errorMessage: error,
        hasError: "",
      });
    }
  };

  activeProductsFilter = (cat) => {
    const { products } = this.state;
    if (IS_SMALL_SCREEN_DEVICE) {
      return filterProductsForCategory(products, cat);
    }
    return makeCouples(filterProductsForCategory(products, cat));
  };

  onCategoryClick = (index) => {
    console.log("Current category", index);
    this.setActiveCat(index);
  };
  setActiveCat = (index) => {
    const { categories } = this.state;
    this.setState({
      activeCat: categories[index].id,
      index: index,
    });
    const item = document.getElementById(categories[index].id);
    item &&
      item.scrollIntoView({
        inline: "center",
        block: "center",
        behavior: "smooth",
      });
  };

  onCallWaiter = () => {
    this.setState({
      isWaiterCalled: true,
    });
  };

  onClose = () => {
    this.setState({
      isWaiterCalled: false,
    });
  };

  render() {
    const {
      categories,
      isLoading,
      activeCat,
      canCallWaiter,
      regionServices,
      isWaiterCalled,
      index: catIndex,
    } = this.state;
    const stateManager = new StateManager();
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    const orderMode = stateManager.orderMode;
    console.log("region services --->", regionServices, isWaiterCalled);
    return (
      <GlobalStore.Consumer>
        {({
          cart,
          updateCart,
          setCustomizationData,
          screenDimensions,
          isSmallScreenDevice,
          setDescriptionData,
        }) => (
          <div className={classes.main_div}>
            {categories.length > 1 && (
              <div className={classes.cat_indicator_div}>
                {categories.map((category, index) => (
                  <div
                    key={category.id}
                    id={category.id}
                    className={
                      activeCat === category.id
                        ? classes.active_cat_div
                        : classes.cat_div
                    }
                    onClick={() => this.onCategoryClick(index)}
                  >
                    <p>{category.name}</p>
                  </div>
                ))}
              </div>
            )}
            {!isLoading && categories.length > 0 && (
              <SwipeableViews
                style={{
                  width: screenDimensions.width,
                  height: screenDimensions.height - 186,
                  WebkitOverflowScrolling: "touch", // iOS momentum scrolling
                }}
                axis="y"
                resistance
                animateHeight
                enableMouseEvents
                onChangeIndex={this.setActiveCat}
                index={catIndex}
              >
                {categories.map((cat, selectindex) => {
                  return (
                    <div
                      style={{ height: screenDimensions.height - 186 }}
                      className={classes.infoDiv}
                      key={selectindex}
                    >
                      <p className={classes.cat_title}>{cat.name}</p>
                      {isSmallScreenDevice && (
                        <div className={classes.cardcontainer}>
                          {this.activeProductsFilter(cat.id).map(
                            (item, index) => (
                              <div
                                key={index}
                                style={{
                                  justifyContent: "center",
                                }}
                                className={classes.containerColumn}
                              >
                                {item && (
                                  <ProductCard
                                    key={`${index} xx`}
                                    type={"long"}
                                    availHeight={screenDimensions.height - 186}
                                    item={item}
                                    cart={cart}
                                    updateCart={updateCart}
                                    setCustomizationData={setCustomizationData}
                                    isSmallScreenDevice={isSmallScreenDevice}
                                    setDescriptionData={setDescriptionData}
                                    freeMealCount={freeMealCount}
                                    setFreeMealCount={setFreeMealCount}
                                    offerDetails={offerDetails}
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}
                      {!isSmallScreenDevice && (
                        <div className={classes.cardcontainer}>
                          {this.activeProductsFilter(cat.id).map(
                            (item, index) => (
                              <div
                                key={index}
                                className={classes.containerColumn}
                              >
                                {item[0] && (
                                  <ProductCard
                                    key={`${index} xx`}
                                    type={index % 2 !== 0 ? "short" : "long"}
                                    item={item[0]}
                                    cart={cart}
                                    updateCart={updateCart}
                                    availHeight={screenDimensions.height - 186}
                                    setCustomizationData={setCustomizationData}
                                    isSmallScreenDevice={isSmallScreenDevice}
                                    setDescriptionData={setDescriptionData}
                                    freeMealCount={freeMealCount}
                                    setFreeMealCount={setFreeMealCount}
                                    offerDetails={offerDetails}
                                  />
                                )}
                                {item[1] && (
                                  <ProductCard
                                    key={`${index}yy`}
                                    type={index % 2 === 0 ? "short" : "long"}
                                    item={item[1]}
                                    cart={cart}
                                    updateCart={updateCart}
                                    availHeight={screenDimensions.height - 186}
                                    setCustomizationData={setCustomizationData}
                                    isSmallScreenDevice={isSmallScreenDevice}
                                    setDescriptionData={setDescriptionData}
                                    freeMealCount={freeMealCount}
                                    setFreeMealCount={setFreeMealCount}
                                    offerDetails={offerDetails}
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </SwipeableViews>
            )}
            {isLoading && (
              <div className={classes.loadingdiv}>
                <Spinner name="folding-cube" color="coral" />
                <p>Loading...</p>
              </div>
            )}
            {!isLoading && categories.length < 1 && (
              <div className={classes.loadingdiv}>
                <Alert
                  height={30}
                  width={30}
                  color={"#e63e25"}
                  fill={"#e63e25"}
                />
                <h6>Looks like this store is not serviceable at this time.</h6>
              </div>
            )}
            {canCallWaiter && orderMode === ORDER_MODES.tableService && (
              <WaiterService
                serviceList={regionServices}
                open={isWaiterCalled}
                onClose={this.onClose}
                updateCart={updateCart}
              ></WaiterService>
            )}
            {canCallWaiter && orderMode === ORDER_MODES.tableService && (
              <Buzzer onClick={this.onCallWaiter}></Buzzer>
            )}
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default StoreScreen;
