import React, { PureComponent } from "react";
import classes from "./SearchScreen.module.css";
// import { Close } from "grommet-icons";
import client from "../../apollo/client";
import StateManager from "../../utilities/StateManager";
import { GET_PRODUCT_SKU_FOR_CATEGORY } from "../../apollo/queries";
import { handleProductData, makeCouples } from "../../utilities/helpers";
import ProductCard from "../../components/ProductCard/ProductCard";
import GlobalStore from "../../utilities/GlobalState";
import Spinner from "react-spinkit";
import Searchbar from "../../components/Searchbar/Searchbar";
import { ReactComponent as CartIcon } from "../../assets/shopping-cart.svg";

/**
 * To-Do:
 * 1. UI
 * 2. Search button to initiate the search
 * 3. Loading indicator while fetching search results
 * 4. If No region and order mode, redirect back to region screen
 * 5. Handle empty search results
 */

class SearchScreen extends PureComponent {
  mounted = false;
  state = {
    searchText: this.props.searchText,
    searchResults: [],
    regionId: null,
    isLoading: false,
    emptyItems: false,
    searchKeywords: [
      "Coffee",
      "Idli",
      "Water",
      "Tea",
      "Coffee",
      "Idli",
      "Water",
      "Tea"
    ]
  };
  componentDidMount() {
    this.mounted = true;
    const { ordermode } = this.props;
    const stateManager = new StateManager();
    const regionData = stateManager.region;
    this.setState({
      regionId: regionData.id
    });
    console.log("order mode for search", ordermode);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onSearchChange = e => {
    const searchText = e.target.value;
    console.log("Search change", searchText);
    if (searchText.length > 1) {
      this.setState(
        {
          searchText: searchText
        },
        () => {
          this.searchItem();
        }
      );
    }
  };

  searchItem = () => {
    this.setState({
      emptyItems: false,
      searchResults: []
    });
    const { ordermode } = this.props;
    const { regionId, searchText } = this.state;
    this.mounted &&
      this.setState(
        {
          isLoading: true
        },
        () => {
          console.log("Search data", searchText);
          client
            .query({
              query: GET_PRODUCT_SKU_FOR_CATEGORY(ordermode),
              variables: { forRegion: regionId, searchText: searchText }
            })
            .then(res => {
              const data = handleProductData(
                res.data.productCategorySkuStock.edges
              );
              console.log("Search data", data);
              if (data.products.length > 0) {
                this.mounted &&
                  this.setState({
                    isLoading: false,
                    searchResults: data.products,
                    emptyItems: false
                  });
              } else {
                this.setState({
                  emptyItems: true,
                  isLoading: false
                });
              }
            })
            .catch(err => {
              // console.log("error occured in fetching search data", err);
              this.setState({
                emptyItems: true,
                isLoading: false
              });
              this.mounted &&
                this.setState({
                  isLoading: false,
                  searchResults: []
                });
            });
        }
      );
  };

  handleTagSearch = value => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.searchItem();
      }
    );
  };

  onClose = () => {
    this.props.history.goBack();
  };
  handleBackClick = () => {
    this.props.history.goBack();
  };

  handGoToCart = () => {
    this.props.history.push("/cart");
  };

  render() {
    const { searchResults, isLoading, emptyItems, searchText } = this.state;
    // const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // alert(vw);
    // alert(vh);
    return (
      <GlobalStore.Consumer>
        {({
          cart,
          updateCart,
          setCustomizationData,
          screenDimensions,
          isSmallScreenDevice,
          setDescriptionData
        }) => (
          <div
            style={{
              height: screenDimensions.height,
              width: screenDimensions.width
            }}
            className={classes.maindiv}
          >
            <Searchbar setSearchText={this.onSearchChange} />
            <div className={classes.content_div}>
              <div className={classes.cardcontainer}>
                <>
                  {isSmallScreenDevice &&
                    !isLoading &&
                    searchResults.map((it, index) => (
                      <div className={classes.containerColumn} key={index}>
                        <ProductCard
                          cart={cart}
                          updateCart={updateCart}
                          setCustomizationData={setCustomizationData}
                          item={it}
                          type="long"
                          isSmallScreenDevice={isSmallScreenDevice}
                          key={it.id}
                          cardType="search"
                          availHeight={screenDimensions.height - 106}
                          setDescriptionData={setDescriptionData}
                        />
                      </div>
                    ))}
                  {!isSmallScreenDevice &&
                    makeCouples(searchResults).map((item, index) => (
                      <div className={classes.containerColumn} key={index}>
                        <ProductCard
                          cart={cart}
                          updateCart={updateCart}
                          setCustomizationData={setCustomizationData}
                          item={item[0]}
                          type={index % 2 !== 0 ? "short" : "long"}
                          key={`${index}yy`}
                          cardType="search"
                          availHeight={screenDimensions.height - 106}
                          isSmallScreenDevice={isSmallScreenDevice}
                          setDescriptionData={setDescriptionData}
                        />
                        <ProductCard
                          cart={cart}
                          updateCart={updateCart}
                          setCustomizationData={setCustomizationData}
                          item={item[1]}
                          type={index % 2 === 0 ? "short" : "long"}
                          key={`${index}xx`}
                          cardType="search"
                          availHeight={screenDimensions.height - 106}
                          isSmallScreenDevice={isSmallScreenDevice}
                          setDescriptionData={setDescriptionData}
                        />
                      </div>
                    ))}
                </>
                {/* )} */}
              </div>
              {isLoading && (
                <div className={classes.loadingdiv}>
                  <Spinner name="folding-cube" color="coral" />
                  <p>Searching...</p>
                </div>
              )}
              {emptyItems && (
                <div className={classes.loadingdiv}>
                  {/* <Spinner name="folding-cube" color="coral" /> */}
                  <p className={classes.noresultmessage}>
                    No search results for "{searchText}"
                  </p>
                  <p className={classes.noresultmessage1}>
                    Make sure you have spelled the word correctly
                  </p>
                </div>
              )}
              {searchText.length < 1 && (
                <div className={classes.loadingdiv}>
                  {/* <Spinner name="folding-cube" color="coral" /> */}
                  <p className={classes.noresultmessage2}>
                    What are you looking for ?
                  </p>
                </div>
              )}
            </div>
            {
              <div className={classes.btngroup}>
                <button onClick={this.handleBackClick}>Cancel</button>
                <button onClick={this.handGoToCart}>
                  <CartIcon height={20} width={20} />
                </button>
              </div>
            }
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default SearchScreen;
