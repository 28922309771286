// this screen is to book the slot for the screen
import React, { Component } from "react";
import classes from "./DeliverySlot.module.css";
import StateManager from "../../utilities/StateManager";
import Loading from "../../components/Loading";
import Button from "../../components/Button/Button";
import moment from "moment";
import Swal from "sweetalert2";

const stateManager = new StateManager();

/**
 * This screen should handle both, Region selection and redirection url
 */
class DeliverySlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCat: 0,
      products: [],
      categories: [],
      isLoading: false,
      hasError: false,
      errorMessage: "",
      storeData: null,
      index: 0,
      date: null,
      timeSlot: null,
      activeSlot: 0,
      activeCatIndex: 0,
      activeSlotIndex: 0,
      loading: false,
      startTime: null,
      endTime: null,
    };
  }
  //console.log(this.props.location.state.token);      }
  componentDidMount() {
    this.generateTimeSlots();
  }

  handleBookDeliverySlot = () => {
    // alert(this.state.date);
    const { timeSlot, date } = this.state;
    if (timeSlot) {
      const newDate = moment(date).date();
      const newTimeslot = timeSlot.date(newDate);
      console.log("-----", newTimeslot);
      stateManager.timeSlot = newTimeslot;
      stateManager.bookingDate = this.state.date;
      this.props.history.push("/cart");
    } else {
      Swal.fire("Error", "Please select a valid time slot");
    }
  };

  onCategoryClick = (index) => {
    console.log("Current category", index);
    this.setActiveCat(index);
  };

  onSlotClick = (index) => {
    this.setActiveSlot(index);
  };

  setActiveCat = (index) => {
    const { categories } = this.state;
    console.log("Active category ----", index, categories[index]);
    this.setState(
      {
        activeCat: categories[index],
        date: categories[index],
        index: index,
        activeCatIndex: index,
      },
      () => {
        const item = document.getElementById(categories[index].id);
        item &&
          item.scrollIntoView({
            inline: "center",
            block: "center",
            behavior: "smooth",
          });
      }
    );
  };

  setActiveSlot = (index) => {
    const { products } = this.state;
    console.log("products", products[index]);
    this.setState({
      activeSlot: products[index],
      timeSlot: products[index],
      activeSlotIndex: index,
    });
  };

  generateTimeSlots = () => {
    let today = new Date();
    const categories = [0, 1, 2].map((item) => {
      let manDate = today;
      manDate = manDate.setDate(today.getDate() + item * 1);
      return manDate;
    });
    const startTime = moment().set({ hour: 10, minute: 0 });
    const endTime = moment().set({ hour: 22, minute: 0 });

    const timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime));
      startTime.add(15, "minutes");
    }
    console.log("dates", timeStops);
    this.setState({
      categories: categories,
      products: timeStops,
      activeCat: categories[0],
      date: categories[0],
    });
    console.log(categories);
  };

  render() {
    const {
      categories,
      activeCat,
      activeSlotIndex,
      loading,
      startTime,
      products,
    } = this.state;
    console.log("ttt", startTime);
    if (!loading) {
      return (
        <div className={classes.wrapper}>
          {/* <header>Choose the time slot</header> */}
          {/* <section className={classes.block}>Available time slot</section> */}
          <section className={classes.horizontalScroll}>
            {categories.length > 1 && (
              <div className={classes.cat_indicator_div}>
                {categories.map((category, index) => {
                  console.log("Category", category);
                  return (
                    <div
                      className={classes.item}
                      key={index}
                      onClick={() => this.onCategoryClick(index)}
                    >
                      <div
                        className={
                          activeCat === category
                            ? classes.active_cat_div
                            : classes.cat_div
                        }
                      >
                        {`${moment(category).format("MMM DD")}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </section>
          <div className={classes.grid}>
            {products.map((item, index) => {
              if (activeCat === categories[0]) {
                if (
                  item.hour() >= moment().hour() &&
                  item.isAfter(moment(moment().add(30, "minute")))
                ) {
                  return (
                    <div
                      className={classes.grid_item}
                      key={index}
                      onClick={() => this.onSlotClick(index)}
                      style={
                        activeSlotIndex === index ? { color: "#f55000" } : {}
                      }
                    >
                      {item.format("HH:mm")}
                    </div>
                  );
                }
              }
              return (
                <div
                  className={classes.grid_item}
                  key={index}
                  onClick={() => this.onSlotClick(index)}
                  style={activeSlotIndex === index ? { color: "#f55000" } : {}}
                >
                  {item.format("HH:mm")}
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "50px" }}>
            <Button
              value={"Confirm"}
              name={"Confirm"}
              onClick={this.handleBookDeliverySlot}
            />
          </div>
          {/* <section className={classes.block}>This is a content block</section> */}
        </div>
      );
    } else {
      return (
        <div className={classes.layoverloading}>
          <Loading open />
        </div>
      );
    }
  }
}
export default DeliverySlot;
