import { SERVER_URL, GHOST_TOKEN } from "../utilities/config";
import ApolloClient, { InMemoryCache } from "apollo-boost";
import StateManager from "../utilities/StateManager";
import {generateHeaders, generateHeaderswithBasicAuthToken, generateEpochTimestamp, generateSignature} from '../utilities/helpers';
const cache = new InMemoryCache({
  addTypename: true,
});

const stateManager = new StateManager();
var vtimestamp = generateEpochTimestamp();
const client = new ApolloClient({
  uri: SERVER_URL,
  request: (operation) => {
    const token = stateManager.authToken || GHOST_TOKEN;
    operation.setContext({
      headers: {
        Authorization: `Token ${token}`,
        "Accept-Encoding": "gzip",
        Timestamp: vtimestamp,
        Signature: generateSignature(vtimestamp, token)            
      },
    });
  },
  cache: cache,
});

export default client;
