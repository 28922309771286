import React from "react";
import StateManager from "./StateManager";
import { IS_SMALL_SCREEN_DEVICE } from "./config";

const stateManager = new StateManager();

const GlobalStore = React.createContext({
  // cart
  cart: stateManager.carts,
  updateCart: () => {},
  // cart for checkout
  cartForCheckout: null,
  setCartForCheckout: () => {},
  // total items in cart
  total: stateManager.totalItem,
  // customization data for customization modal
  customizationData: {
    product: null,
    customizations: null,
    pickupoint: null,
    stock: null,
    isStock: false,
  },
  setCustomizationData: () => {},
  // is customization modal open?
  isCustomizationModalOpen: false,
  closeCustomization: () => {},
  // search text
  searchText: "",
  setSearchText: () => {},
  // order mode
  ordermode: stateManager.orderMode,
  setOrderMode: () => {},
  // is user authenticated
  isAuthenticated: false,
  setAuthState: () => {},
  showAuthModal: () => {},
  hideAuthModal: () => {},
  // screen dimensions
  screenDimensions: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
  setScreenDimensions: () => {},
  // is small screen device
  isSmallScreenDevice: IS_SMALL_SCREEN_DEVICE,
  // selected region
  region: stateManager.region,
  setRegion: () => {},
  //selected flight
  selectedFlight: null,
  setSelectedFlight: () => {},
  // selected seat No
  selectedSeatNo: "",
  setSelectedSeatNo: () => {},
  // selected table No
  selectedTableNo: "",
  setSelectedTableNo: () => {},
  //selected delivery time
  deliveryTime: null,
  setDeliveryTime: () => {},
  //selected delivery Location
  deliveryLocation: stateManager.deliveryLocation,
  setDeliveryLocation: () => {},
  deliveryLocationType: stateManager.deliveryLocationType,
  setDeliveryLocationType: () => {},
  // user details
  userDetails: null,
  setUserDetails: () => {},
  appConfiguration: stateManager.appConfig,
  setAppConfiguration: () => {},
  redirectionURL: stateManager.redirectionURL,
  setRedirectionURL: () => {},
  // description modal
  isDescriptionModalOpen: true,
  openDescription: () => {},
  closeDescription: () => {},
  descriptionData: {
    description: null,
  },
  hasPendingPayment: false,
  setHasPendingPayment: () => {},
});

export default GlobalStore;
