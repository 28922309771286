import React, { PureComponent } from "react";
import classes from "./CheckoutModal.module.css";
import { RUPEE_SYMBOL } from "../../utilities/config";

class CartItem extends PureComponent {
  state = {
    showCustomization: false,
  };

  toggleCustomization = () => {
    this.setState((prevState) => ({
      showCustomization: !prevState.showCustomization,
    }));
  };
  onRemoveItem = () => {
    const { item, pickupPoint } = this.props;
    this.props.onRemoveItem(item, pickupPoint);
  };
  onAddItem = () => {
    const { item, pickupPoint } = this.props;
    this.props.onAddItem(item, pickupPoint);
  };
  render() {
    const { item, canChangeQuantity } = this.props;
    const { showCustomization } = this.state;
    console.log("item in Cart ------> ", item);
    return (
      <div className={classes.order_content_main}>
        <div className={classes.order_item_div}>
          <p className={classes.order_item_name}>{item.stock.sku.name}</p>
          <div className={classes.order_qty_management}>
            {canChangeQuantity && (
              <button
                onClick={this.onRemoveItem}
                className={classes.order_qty_button}
              >
                -
              </button>
            )}
            <p className={classes.order_qty}>{item.quantity}</p>
            {canChangeQuantity && (
              <button
                onClick={this.onAddItem}
                className={classes.order_qty_button}
              >
                +
              </button>
            )}
          </div>
          <p className={classes.order_item_price}>
            <span>{RUPEE_SYMBOL}</span>
            {item.total >= 0 ? item.total.toFixed(2) : ""}
          </p>
        </div>
        <div className={classes.order_item_extra_content}>
          <p className={classes.sku_text}>
            {" "}
            {item.stock.sku.quantity ? `\n${item.stock.sku.quantity}` : ""}
          </p>
          {item.customizations.edges.length > 0 && (
            <div className={classes.customization_text_div}>
              <p
                onClick={this.toggleCustomization}
                className={classes.customization_toggle}
              >
                {showCustomization
                  ? "hide customizations"
                  : "show customizations"}
              </p>
              <p>
                {item.customizations.edges.length > 0 &&
                  showCustomization &&
                  item.customizations.edges.map(
                    (cust, index) =>
                      `${cust.node.name} ${
                        index < item.customizations.edges.length - 1 ? ", " : ""
                      }`
                  )}
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CartItem;
