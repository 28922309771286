import React, { PureComponent, useState } from "react";
import classes from "./OrderTrack.module.css";
import { ReactComponent as TickIcon } from "../../assets/right.svg";
import { isDelivery, isTakeaway } from "../../utilities/helpers";
import { ReactComponent as OneStar } from "../../assets/1star.svg";
import { ReactComponent as TwoStar } from "../../assets/2star.svg";
import { ReactComponent as ThreeStar } from "../../assets/3star.svg";
import { ReactComponent as FourStar } from "../../assets/4star.svg";
import { ReactComponent as FiveStar } from "../../assets/5star.svg";
import client from "../../apollo/client";
import {
  UPDATE_CHECKOUT_FEEDBACK,
  SEND_ORDER_INVOICE
} from "../../apollo/mutations";

const RatingComponent = ({ open, onClose, onSubmit }) => {
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState("");

  const onChangeComment = e => {
    setComment(e.target.value);
  };

  const onSubmitFeedback = () => {
    onSubmit(rating, comment);
  };

  return (
    <>
      <div className={classes.backdrop}></div>
      <div className={open ? classes.rating_modal : classes.rating_modal_close}>
        <p className={classes.ratingTitle}>
          How was your experience with GrabbnGo?
        </p>
        <div className={classes.emoji_container}>
          <OneStar
            className={rating === 1 ? classes.selected_rating : classes.rating}
            onClick={() => setRating(1)}
            alt=""
            height={40}
            fill={rating === 1 ? "#ffa500" : "#000000"}
          />
          <TwoStar
            className={rating === 2 ? classes.selected_rating : classes.rating}
            onClick={() => setRating(2)}
            alt=""
            height={40}
            fill={rating === 2 ? "#ffa500" : "#000000"}
          />
          <ThreeStar
            className={rating === 3 ? classes.selected_rating : classes.rating}
            onClick={() => setRating(3)}
            alt=""
            height={40}
            fill={rating === 3 ? "#ffa500" : "#000000"}
          />
          <FourStar
            className={rating === 4 ? classes.selected_rating : classes.rating}
            onClick={() => setRating(4)}
            alt=""
            height={40}
            fill={rating === 4 ? "#ffa500" : "#000000"}
          />
          <FiveStar
            className={rating === 5 ? classes.selected_rating : classes.rating}
            onClick={() => setRating(5)}
            alt=""
            height={40}
            fill={rating === 5 ? "#ffa500" : "#000000"}
          />
        </div>
        <div className={classes.input_div}>
          <textarea
            className={classes.text_area}
            value={comment}
            onChange={onChangeComment}
            placeholder="It's always good to say something..."
          />
        </div>
        <div className={classes.button_div}>
          <button onClick={onClose} className={classes.cancel_button}>
            Cancel
          </button>
          <button onClick={onSubmitFeedback} className={classes.submit_button}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

class OrderTrack extends PureComponent {
  state = {
    allPushed: false,
    pushedCount: 0,
    allAccepted: false,
    acceptedCount: 0,
    allReady: false,
    readyCount: 0,
    allDelivered: false,
    deliveredCount: 0,
    totalOrders: 0,
    showRating: false,
    isRated: false
  };
  componentDidMount() {
    this.handleStatus();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.handleStatus();
    }
  }

  updateOrderFeedback = (rating, comments) => {
    const { checkoutId } = this.props;
    client
      .mutate({
        mutation: UPDATE_CHECKOUT_FEEDBACK(),
        variables: {
          input: { checkoutId: checkoutId, rating: rating, comments: comments }
        }
      })
      .then(res => {
        this.setState(
          {
            showRating: false
          },
          () => {
            this.markOrderPickedUp();
          }
        );
      });
  };

  markOrderPickedUp = () => {
    const { carts } = this.props;
    let updates = [];
    carts.forEach(cart => {
      const orderId = cart.id;
      const input = { id: orderId, status: "PICKEDUP" };
      updates.push(
        client.mutate({
          mutation: SEND_ORDER_INVOICE(),
          variables: { input: input }
        })
      );
      Promise.all(updates)
        .then(res => {
          this.setState({
            isRated: true
          });
          console.log("response for the updates", res);
        })
        .catch(err => {
          console.log("Error in marking order as pickedup", err);
        });
    });
  };

  handleStatus = () => {
    const { status } = this.props;
    const { PUSHED, ACCEPTED, READY, DELIVERED } = status;
    const totalOrders =
      PUSHED && PUSHED > 0
        ? PUSHED
        : 0 + ACCEPTED && ACCEPTED > 0
        ? ACCEPTED
        : 0 + READY && READY > 0
        ? READY
        : 0 + DELIVERED && DELIVERED > 0
        ? DELIVERED
        : 0;
    let allPushed = PUSHED === totalOrders;
    let pushedCount = PUSHED && PUSHED > 0 ? PUSHED : 0;
    let allAccepted = ACCEPTED === totalOrders;
    let acceptedCount = ACCEPTED && ACCEPTED > 0 ? ACCEPTED : 0;
    let allReady = READY === totalOrders;
    let readyCount = READY && READY > 0 ? READY : 0;
    let allDelivered = DELIVERED === totalOrders;
    let deliveredCount = DELIVERED && DELIVERED > 0 ? DELIVERED : 0;
    if (
      (pushedCount === 0 || pushedCount === totalOrders) &&
      (acceptedCount > 0 || readyCount > 0 || deliveredCount > 0)
    ) {
      allPushed = true;
      pushedCount = totalOrders;
    }
    if (
      (acceptedCount === 0 || acceptedCount === totalOrders) &&
      (readyCount > 0 || deliveredCount > 0)
    ) {
      allAccepted = true;
      acceptedCount = totalOrders;
    }
    if (
      (readyCount === 0 || readyCount === totalOrders) &&
      deliveredCount > 0
    ) {
      allReady = true;
      readyCount = totalOrders;
    }
    this.setState({
      allPushed,
      pushedCount,
      allAccepted,
      acceptedCount,
      allReady,
      readyCount,
      allDelivered,
      deliveredCount,
      totalOrders
    });
  };

  handlePickUpStatus = () => {
    console.log("update checkout status!");
    this.props.handleCheckoutUpdate();
  };

  onPickedUpClick = () => {
    this.setState({
      showRating: true
    });
  };

  hideRatingClick = () => {
    this.setState({
      showRating: false
    });
  };

  render() {
    const { mode } = this.props;
    const {
      allAccepted,
      acceptedCount,
      allDelivered,
      deliveredCount,
      allPushed,
      allReady,
      pushedCount,
      readyCount,
      totalOrders,
      showRating,
      isRated
    } = this.state;
    if (isRated) {
      return "";
    } else if (allReady || deliveredCount > 0) {
      return (
        <div className={classes.pickedup_div}>
          <h6>Got you order?</h6>
          <button
            className={classes.pickedUpBtn}
            onClick={this.onPickedUpClick}
          >
            YES
          </button>
          {showRating && (
            <RatingComponent
              onSubmit={this.updateOrderFeedback}
              open={showRating}
              onClose={this.hideRatingClick}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.maindiv}>
          <div className={classes.status_indicator}>
            <div
              id="pushed"
              style={
                allPushed || pushedCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              className={classes.inactiveCircle}
            >
              {allPushed && <TickIcon height={10} width={10} />}
            </div>
            <div className={classes.status_text}>PLACED</div>
          </div>
          <div className={classes.line}>
            <div
              style={
                allPushed || pushedCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              id="pushline"
            ></div>
          </div>
          <div style={{ maxWidth: "25px" }}>
            <div
              id="accepted"
              style={
                allAccepted || acceptedCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              className={classes.inactiveCircle}
            >
              {allAccepted && <TickIcon height={10} width={10} />}
            </div>
            <div className={classes.status_text}>accepted</div>
          </div>
          <div className={classes.line}>
            <div
              style={
                allAccepted || acceptedCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              id="acceptedline"
            ></div>
          </div>
          <div>
            <div
              id="ready"
              style={
                allReady || readyCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              className={classes.inactiveCircle}
            >
              {allReady && <TickIcon height={10} width={10} />}
            </div>
            <div className={classes.status_text}>READY</div>
          </div>
          <div className={classes.line}>
            <div
              style={
                allReady || readyCount === totalOrders
                  ? { background: "#f55000" }
                  : {}
              }
              id="readyline"
            ></div>
          </div>
          <div>
            <div
              id="delivered"
              style={allDelivered ? { background: "#f55000" } : {}}
              className={classes.inactiveCircle}
            >
              {allDelivered && <TickIcon height={10} width={10} />}
            </div>
            <div className={classes.status_text}>
              {isDelivery(mode)
                ? "delivered"
                : isTakeaway(mode)
                ? "picked up"
                : "delivered"}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default OrderTrack;
