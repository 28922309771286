import {
  GENERATE_OTP_URL,
  DIRECT_LOGIN_URL,
  SUBMIT_OTP_URL,
  LOGOUT_URL,
  MERGE_CHECKOUT_URL,
} from "../utilities/config";
import StateManager from "../utilities/StateManager";
import {generateHeaders, generateHeaderswithBasicAuthToken, generateEpochTimestamp, generateSignature} from '../utilities/helpers';

export const getFlightList = async (
  flight_nature = "DD",
  sourceCity = "",
  terminal = null,
  airline = null,
  destination = null
) => {
  const url = "https://fitds.grabbngo.com/fitds/flights/";
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    let response = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify({
        flight_nature: flight_nature,
        terminal: terminal,
        destination: destination,
        airline: airline,
        source_city: sourceCity,
      }),
    });
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log("Error in getting the flight list", error);
    throw error;
  }
};
/**
 *
 * @param {String} phone - a valid phone no string
 */
export const getOtp = async (phone) => {
  try {
    let response = await fetch(GENERATE_OTP_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
      }),
    });
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const mergeCheckoutRequest = async () => {
  const stateManager = new StateManager();
  const token = stateManager.authToken;
  try {
    var vtimestamp = generateEpochTimestamp();
    let response = await fetch(MERGE_CHECKOUT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
        Timestamp: vtimestamp,
        Signature: generateSignature(vtimestamp, token)
      },
    });
    let responseJson = await response.json();
    console.log("Response for the merge checkout", responseJson);
    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const directLogin = async (phone) => {
  try {
    let response = await fetch(DIRECT_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
        skip: true,
      }),
    });
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {String} phone - a valid phone string
 * @param {*} otp  - a 6 digit numeric pin
 */
export const verifyOtp = async (phone, otp) => {
  try {
    let response = await fetch(SUBMIT_OTP_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: phone,
        otp: otp,
      }),
    });
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const stateManager = new StateManager();
    const token = stateManager.authToken;
    //eslint-disable-next-line
    var vtimestamp = generateEpochTimestamp();    
    let response = await fetch(LOGOUT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
        Timestamp: vtimestamp,
        Signature: generateSignature(vtimestamp, token)        
      },
    });
    let responseJson = "Done";
    return responseJson;
  } catch (error) {
    throw error;
  }
};
