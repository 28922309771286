import React, { PureComponent } from "react";
import Spinner from "react-spinkit";
import classes from "./OfferTnC.module.css";
import { Markdown } from "grommet";
import { OFFER_TERMS_URL } from "../../utilities/config";

class OfferTnC extends PureComponent {
  state = {
    isLaoding: false,
    offerContent: ""
  };
  componentDidMount() {
    this.setState(
      {
        isLaoding: true
      },
      () => {
        this.getOfferAndTc();
      }
    );
  }

  getOfferAndTc = async () => {
    const res = await fetch(OFFER_TERMS_URL);
    const body = await res.text();
    this.setState({ offerContent: body, isLaoding: false });
  };
  render() {
    const { offerContent, isLaoding } = this.state;
    return (
      <div className={classes.maindiv}>
        {!isLaoding && (
          <div>
            <Markdown
              style={{ width: "100%", overflowX: "hidden", marginLeft: 28 }}
              className={classes.markdown_div}
            >
              {offerContent}
            </Markdown>
          </div>
        )}
        {isLaoding && (
          <div className={classes.loadingdiv}>
            <Spinner name="folding-cube" color="coral" />
            <p>Loading...</p>
          </div>
        )}
      </div>
    );
  }
}

export default OfferTnC;
