import React from "react";
import classes from "../Button/Button.module.css";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      value: this.props.value
    };
  }

  initializeState = () => {
    return {
      spanStyles: {},
      count: 0
    };
  };

  showRipple = e => {
    const rippleContainer = e.currentTarget;
    const size = rippleContainer.offsetWidth;
    const pos = rippleContainer.getBoundingClientRect();
    const x = e.pageX - pos.x - size / 2;
    const y = e.pageY - pos.y - size / 2;
    const spanStyles = {
      top: y + "px",
      left: x + "px",
      height: size + "px",
      width: size + "px"
    };
    const count = this.state.count + 1;
    this.setState({
      spanStyles: { ...this.state.spanStyles, [count]: spanStyles },
      count: count
    });
  };

  renderRippleSpan = () => {
    const { spanStyles = {} } = this.state;
    const spanArray = Object.keys(spanStyles);
    if (spanArray && spanArray.length > 0) {
      return spanArray.map((key, index) => {
        return (
          <span
            key={"spanCount_" + index}
            className=""
            style={{ ...spanStyles[key] }}
          ></span>
        );
      });
    } else {
      return null;
    }
  };

  state = this.initializeState();
  render() {
    const { children = null, name, value } = this.props;
    return (
      <div
        ref="targetElement"
        className={[classes.ripple, classes.btn].join(" ")}
        onClick={e => {
          this.props.onClick(e);
        }}
      >
        {children}
        <div
          value={value}
          className={classes.rippleContainer}
          onMouseDown={this.showRipple}
        >
          {this.renderRippleSpan()}
        </div>
        <p>{name}</p>
      </div>
    );
  }
}

export default Button;
