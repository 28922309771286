import React, { PureComponent } from "react";
import classes from "./PreviousOrders.module.css";
import Spinner from "react-spinkit";
import { Alert } from "grommet-icons";
import client from "../../apollo/client";
import { GET_LAST_ORDERS } from "../../apollo/queries";
import { classifyOrdersForCheckout } from "../../utilities/helpers";
import CheckoutCard from "../../components/CheckoutCard/CheckoutCard";

class PreviousOrders extends PureComponent {
  mounted = false;
  state = {
    checkouts: null,
    isLoading: false,
    hasError: false,
    errorMsg: ""
  };
  componentDidMount() {
    this.mounted = true;
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.getLastOrders();
      }
    );
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  getLastOrders = () => {
    client
      .query({
        query: GET_LAST_ORDERS(),
        fetchPolicy: "network-only"
      })
      .then(res => {
        this.mounted &&
          this.setState({
            checkouts: classifyOrdersForCheckout(res.data.orders.edges),
            isLoading: false,
            hasError: false
          });
      })
      .catch(err => {
        this.mounted &&
          this.setState({
            isLoading: false,
            hasError: true,
            errorMsg: err
          });
      });
  };
  render() {
    const { checkouts, isLoading } = this.state;
    return (
      <div
        style={{ height: window.innerHeight - 120 }}
        className={classes.maindiv}
      >
        {!isLoading &&
          checkouts &&
          Object.entries(checkouts).map(([key, value]) => {
            return <CheckoutCard delivered={true} key={key} data={value} />;
          })}
        {isLoading && (
          <div className={classes.layoverloading}>
            <Spinner
              style={{ marginBottom: 16 }}
              name="folding-cube"
              color={"#e63e25"}
            />
            <p>Loading...</p>
          </div>
        )}
        {!isLoading && !checkouts && (
          <div className={classes.layoverloading}>
            <Alert height={30} width={30} color={"#e63e25"} fill={"#e63e25"} />
            <p>
              Loooks like you have not placed any order yet. <br /> Please place
              an order.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default PreviousOrders;
