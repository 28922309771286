import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import classes from "./UserProfileScreen.module.css";
import HelpScreen from "../HelpScreen/HelpScreen";
import MainUserPage from "./MainUserPage";
import LegalInfo from "../LegalInfo/LegalInfo";
import OfferTnC from "../OfferTnC/OfferTnC";
import PreviousOrders from "../PreviousOrders/PreviousOrders";
import AccountScreen from "../AccountScreen/AccountScreen";
import GlobalStore from "../../utilities/GlobalState";

class UserProfileScreen extends Component {
  mounted = false;
  state = {
    isLoading: false,
  };
  componentDidMount() {
    this.mounted = true;
    this.mounted &&
      this.setState({
        isLoading: true,
      });
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  render() {
    return (
      <div className={classes.maindiv}>
        <GlobalStore.Consumer>
          {({ isAuthenticated, setAuthState, updateCart }) => (
            <div>
              <Switch>
                <Route path="/user/previousorders" component={PreviousOrders} />
                <Route path="/user/help" component={HelpScreen} />
                <Route path="/user/legalinfo" component={LegalInfo} />
                {/* <Route path="/user/privacypolicy" component={HelpScreen} /> */}
                <Route path="/user/termsandconditions" component={OfferTnC} />
                <Route path="/user/account" component={AccountScreen} />
                <Route
                  path="/user/"
                  render={(props) => (
                    <MainUserPage
                      isLoggedIn={isAuthenticated}
                      changeAuthState={setAuthState}
                      updateCart={updateCart}
                      {...props}
                    />
                  )}
                />
              </Switch>
            </div>
          )}
        </GlobalStore.Consumer>
      </div>
    );
  }
}

export default UserProfileScreen;
