import React, { PureComponent } from "react";
import classes from "./CustomizationModal.module.css";
import { RadioButton, CheckBox } from "grommet";
import CartManager from "../../utilities/CartManager";
import { generateSkuLabels } from "../../utilities/helpers";
import { RUPEE_SYMBOL } from "../../utilities/config";
import { AppConfigContext } from "../../data/AppConfiguration";
import Swal from "sweetalert2";

class CustomizationModal extends PureComponent {
  static contextType = AppConfigContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedSku: props.isStock ? props.stock.id : props.product.stocks[0].id,
      errors: {},
      selectedCustomizations: {},
      customizationConfig: props.customizationConfig,
      product: props.product,
      skuLabels: generateSkuLabels(props.product),
    };
  }
  renderChild = (option, { checked }) => {
    return (
      <div className={classes.option_div}>
        <p>{option.name}</p>
      </div>
    );
  };
  onSkuChage = (sku) => {
    this.setState({
      selectedSku: sku.id,
    });
  };

  onItemAddToCart = () => {
    const { selectedSku, customizationConfig } = this.state;
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    const { product, store, isStock, stock } = this.props;
    const item = isStock
      ? [{ id: stock.id, name: stock.sku.name, price: stock.salePrice }]
      : product.stocks.filter((it) => it.id === selectedSku);
    const custConfig = [];
    !isStock &&
      customizationConfig[selectedSku] &&
      Object.entries(customizationConfig[selectedSku]).forEach(
        ([key, value]) => {
          value.selected.forEach((it) => {
            custConfig.push(it.node.id);
          });
        }
      );
    isStock &&
      customizationConfig[selectedSku] &&
      Object.entries(customizationConfig[selectedSku]).forEach(
        ([key, value]) => {
          value.selected.forEach((it) => {
            custConfig.push(it.node.id);
          });
        }
      );
    const cartManager = new CartManager();
    const itemDetails = {
      id: item[0].id,
      name: item[0].name,
      price: item[0].salePrice,
    };
    if (
      offerDetails &&
      offerDetails.offerStocks &&
      offerDetails.offerStocks.includes(itemDetails.id) &&
      freeMealCount < 1
    ) {
      console.log("came here ---- 1");
      Swal.fire({
        text: "You have exhausted complimentary meal quota",
        title: "Error",
        icon: "error",
        confirmButtonColor: "#ff5500",
      });
    } else {
      if (
        offerDetails &&
        offerDetails.offerStocks &&
        offerDetails.offerStocks.includes(itemDetails.id)
      ) {
        console.log("came here ---- 2");
        setFreeMealCount(freeMealCount - 1);
      }
      console.log("came here ---- 3");
      cartManager.addStockToCart(store, itemDetails, 1, custConfig);
    }
    console.log("came here ---- 4");
    this.props.updateCart();
    this.props.onClose();
  };

  onCustChange = (value, child) => {
    const { customizationConfig, selectedSku } = this.state;
    let custConf = JSON.parse(JSON.stringify(customizationConfig));
    let selectedCat = value;
    // let selectChild = child;
    const itemIndex = selectedCat.selected.findIndex(
      (it) => it.node.id === child.id
    );
    if (selectedCat.maxSelectable === 1 && selectedCat.minSelectable === 1) {
      // radio buttons
      let selected = selectedCat.selected;
      selected = [{ node: child }];
      selectedCat.selected = selected;
    } else {
      if (itemIndex > -1) {
        // incase item is already selected, we'll need to remove it
        let selected = selectedCat["selected"];
        selected.splice(itemIndex, 1);
        selectedCat.selected = selected;
      } else {
        // if item is not selected, add it to the item list
        let selected = selectedCat.selected;
        selected.push({ node: child });
        selectedCat.selected = selected;
      }
    }
    custConf[selectedSku][selectedCat.id] = selectedCat;
    this.setState({
      customizationConfig: custConf,
    });
  };

  isSelectedCustom = (custId, catId) => {
    const { customizationConfig, selectedSku } = this.state;
    const index = customizationConfig[selectedSku][catId]["selected"].findIndex(
      (it) => it.node.id === custId
    );
    return index;
  };

  onClose = () => [this.props.onClose()];

  render() {
    const {
      stock,
      // customization,
      product,
      // stocks,
      // isProductModal,
      store,
      isOpen,
    } = this.props;
    const { selectedSku, errors, customizationConfig, skuLabels } = this.state;
    console.log("Customization config", store, product, stock, skuLabels);
    if (isOpen) {
      return (
        <>
          <div className={classes.overlay_div} />
          <div className={classes.customization_modal}>
            <div className={classes.productInfo}>
              <div
                className={classes.productImage}
                style={{
                  backgroundColor: product
                    ? product.skuCardColor
                    : stock.sku.selectedColor,
                }}
              >
                <img
                  src={product ? product.image : stock.sku.image}
                  height={75}
                  alt=""
                  width={75}
                />
              </div>
              <div className={classes.productDescription}>
                <p className={classes.product_name}>
                  {product ? product.name : stock.sku.name}
                </p>
                <p className={classes.product_desc}>
                  {product ? product.description : stock.sku.description}
                </p>
              </div>
            </div>
            <div
              className={classes.form_style}
              id="customizationForm"
              name="customizationForm"
            >
              {product && product.stocks && skuLabels && (
                <div>
                  {Object.entries(skuLabels).map(([key, value]) => {
                    console.log("Sku lable", key, value);
                    return (
                      <div key={key} className={classes.sku_div}>
                        <label className={classes.sku_text}>{key}</label>
                        {value.map((stk) => (
                          <div key={stk.id} className={classes.list_item}>
                            <RadioButton
                              onChange={() => this.onSkuChage(stk)}
                              checked={stk.id === selectedSku}
                              name={"skupicker"}
                              label={stk.quantity || stk.name}
                              disabled={product.stocks.length === 1}
                              className={classes.radio_cust}
                            />
                            <p>
                              {RUPEE_SYMBOL} {stk.salePrice}
                            </p>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              )}
              {customizationConfig &&
                customizationConfig[selectedSku] &&
                Object.entries(customizationConfig[selectedSku]).map(
                  ([key, value]) => {
                    console.log("value for customization", key, "---->", value);
                    return (
                      <div className={classes.main_option_div} key={key}>
                        <label className={classes.cust_cat_name}>
                          {value.name}
                        </label>
                        <p
                          style={errors[value.id] ? { color: "red" } : {}}
                          className={classes.cust_cat_text}
                        >
                          {value.helptext}
                        </p>
                        {value.showradio &&
                          value.children.map((child) => (
                            <div key={child.id} className={classes.list_item}>
                              <RadioButton
                                label={child.name}
                                checked={
                                  this.isSelectedCustom(child.id, value.id) >= 0
                                }
                                name={value.name}
                                onChange={() => this.onCustChange(value, child)}
                                className={classes.radio_cust}
                                size={14}
                              />
                              {child.salePrice > 0 && (
                                <p>
                                  {RUPEE_SYMBOL} {child.salePrice}
                                </p>
                              )}
                            </div>
                          ))}
                        {!value.showradio &&
                          value.children.map((child) => (
                            <div key={child.id} className={classes.list_item}>
                              <CheckBox
                                label={child.name}
                                checked={
                                  this.isSelectedCustom(child.id, value.id) >= 0
                                }
                                name={value.name}
                                onChange={() => this.onCustChange(value, child)}
                                className={classes.check_cust}
                                size={14}
                                color={"#E63E25"}
                              />
                              {child.salePrice > 0 && (
                                <p>
                                  {RUPEE_SYMBOL} {child.salePrice}
                                </p>
                              )}
                            </div>
                          ))}
                      </div>
                    );
                  }
                )}
              <div className={classes.button_div}>
                <button
                  className={classes.cust_cancel_button}
                  type="submit"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className={classes.cust_add_button}
                  type="submit"
                  onClick={this.onItemAddToCart}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}

export default CustomizationModal;
