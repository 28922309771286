import React, { Component } from "react";
import classes from "./RedirectionScreen.module.css";
import { GET_REDIRECTION_DATA } from "../../apollo/queries";
import client from "../../apollo/client";
import StateManager from "../../utilities/StateManager";
import { Redirect } from "react-router-dom";
import Spinner from "react-spinkit";
import { AppConfigContext } from "../../data/AppConfiguration";

class RedirectionScreen extends Component {
  static contextType = AppConfigContext;
  mounted = false;
  state = {
    imageUrl: null,
    storeId: null,
    regionId: null,
    region: null,
    stockId: null,
    welcomeText: "",
    skipRedirection: false,
    loadedTimes: 0,
    isLoading: false,
    orderMode: "",
    seatNo: "",
    dataFetched: false,
  };

  componentDidMount() {
    this.mounted = true;
    const stateManager = new StateManager();
    const { redirectId } = this.props.match.params;
    let seatNo = this.props.location.pathname;
    seatNo = seatNo.replace(`${this.props.match.url}/`, "");
    console.log("mounted redirection url", seatNo);
    stateManager.redirectionURL = redirectId;
    this.mounted &&
      this.setState(
        {
          isLoading: true,
          seatNo: seatNo,
        },
        () => {
          client
            .query({
              query: GET_REDIRECTION_DATA(),
              variables: { redirectionId: redirectId },
            })
            .then((res) => {
              this._redirectionDataLoad(res.data);
            })
            .catch((err) => {
              console.log("Error in fetching redirection data", err);
              this.props.history.replace("/region");
            });
        }
      );
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  /**
   * @description Redirection data processing
   */
  _redirectionDataLoad = (data) => {
    const { setSelectedRegion } = this.context;
    const newData = data.redirectionUrl;
    if (newData.skipRedirection) {
      this.props.history.replace("/region");
    } else if (newData.appUrl) {
      window.location.assign(newData.appUrl);
    } else {
      const {
        canChangeLocation,
        canChangeOrderMode,
        canChangeRegion,
        canChangeStore,
        deliveryLocation,
        deliveryLocationType,
        orderMode,
        region,
      } = newData;
      const {
        canShareCheckout,
        checkoutHeader,
        trendingLogo,
        shouldShowTrending,
        trendingName,
        terminal,
        isAirport,
        isFidsAvailable,
        productDisplayView,
      } = region;
      setSelectedRegion(region);
      const stateManager = new StateManager();
      stateManager.region = region;
      stateManager.orderMode = orderMode;
      stateManager.deliveryLocationType = deliveryLocationType;
      stateManager.deliveryLocation = deliveryLocation;
      const config = {
        canChangeLocation,
        canChangeOrderMode,
        canChangeRegion,
        canChangeStore,
        canShareCheckout,
        checkoutHeader,
        trendingLogo,
        shouldShowTrending,
        trendingName,
        terminal,
        isAirport,
        isFidsAvailable,
        productDisplayView,
      };
      this.props.onRedirectionSelect(newData, config);
      this.setState({
        isLoading: false,
        skipRedirection: newData.skipRedirection,
        dataFetched: true,
      });
    }
  };

  render() {
    const { skipRedirection, isLoading, dataFetched, imageUrl } = this.state;
    if (isLoading) {
      return (
        <div className={classes.layoverloading}>
          <Spinner
            style={{ marginBottom: 16 }}
            name="folding-cube"
            color={"#e63e25"}
          />
          <p>Loading...</p>
        </div>
      );
    }
    if (!isLoading && !skipRedirection && dataFetched) {
      return (
        <Redirect
          to={{ pathname: "/boarding", state: { imageUrl: imageUrl } }}
        />
      );
    }
    return null;
  }
}

export default RedirectionScreen;
