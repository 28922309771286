import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../apollo/client";
import BoardingScreen from "../screens/BoardingScreen/BoardingScreen";
import LandingScreen from "../screens/LandingScreen/LandingScreen";
import RedirectionScreen from "../screens/RedirectionScreen/RedirectionScreen";
import ExploreRouter from "./ExploreRouter/ExploreRouter";
import StateManager from "../utilities/StateManager";
import GlobalStore from "../utilities/GlobalState";
import AuthenticationModal from "../components/AuthenticationModal/AuthenticationModal";
import {
  IS_SMALL_SCREEN_DEVICE,
  ORDER_MODES,
  FSTR_GTM_TAG,
} from "../utilities/config";
import { AppConfigProvider } from "../data/AppConfiguration";
import TagManager from "react-gtm-module";
import RegionScreen from "../screens/RegionScreen";

const tagManagerArgs = {
  gtmId: FSTR_GTM_TAG,
};

const stateManager = new StateManager();

const initialCustomizations = {
  item: null,
  pickupoint: null,
  stock: null,
  isStock: false,
  customizations: null,
};

const initialState = () => ({
  showAuthModal: false,
  isAuthenticated: false,
  searchText: "",
  screenDimensions: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
  isSmallScreenDevice: IS_SMALL_SCREEN_DEVICE,
  cart: stateManager.carts,
  total: stateManager.totalItem,
  customizationData: initialCustomizations,
  orderMode: stateManager.orderMode,
  region: stateManager.region,
  selectedFlight: stateManager.flight,
  seatNo: stateManager.seatNo,
  tableNo: stateManager.tableNo,
  deliveryTime: stateManager.deliveryTime,
  deliveryLocation: stateManager.deliveryLocation,
  deliveryLocationType: stateManager.deliveryLocationType,
  userDetails: stateManager.userProfile,
  appConfiguration: stateManager.appConfig,
  redirectionURL: stateManager.redirectionURL,
  descriptionData: {},
  hasPendingPayment: false,
});
console.log("initial state", initialState());
class MainRouter extends Component {
  state = initialState();
  componentDidMount() {
    console.log("Initial state", initialState());
    window.addEventListener("clearauth", this._onLogout);
    window.addEventListener("resize", this.handleScreenResize);
    const stateManager = new StateManager();
    if (
      stateManager.region &&
      stateManager.region.city === "Bengaluru" &&
      stateManager.region.isAirport
    ) {
      TagManager.initialize(tagManagerArgs);
    }
    const authtoken = stateManager.authToken;
    const isLogged = authtoken ? true : false;
    this.setState({
      isAuthenticated: isLogged,
    });
    const appversion = localStorage.getItem("APP_VERSION");
    if (!appversion) {
      localStorage.clear();
      window.location.reload();
      localStorage.setItem("APP_VERSION", "v2");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleScreenResize);
    window.removeEventListener("clearauth", this._onLogout);
  }

  handleScreenResize = () => {
    const screenDimensions = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    this.setState({
      screenDimensions,
      isSmallScreenDevice: window.innerHeight < 720,
    });
  };

  _onLogout = () => {
    localStorage.removeItem("localCheckoutFromServer");
    stateManager.authToken = null;
    stateManager.userProfile = null;
    this.setState({
      showAuthModal: true,
      isAuthenticated: false,
    });
  };

  onAuthVerified = (isVerified = true) => {
    console.log("Authentication Verified", isVerified);
    this.setState({
      isAuthenticated: isVerified,
    });
  };

  setCart = () => {
    localStorage.removeItem("localCheckoutFromServer");
    const newTotal = stateManager.totalItem;
    this.setState({
      cart: Object.assign({}, stateManager.carts),
      total: newTotal,
    });
  };

  setCustomizationData = (openCustomization = false, data) => {
    console.log("^^^^^^^^^^^^^^^^^^");
    console.log(data);
    this.setState({
      isCustomizationModalOpen: openCustomization,
      customizationData: data,
    });
  };

  closeCustomizationModal = () => {
    this.setState({
      isCustomizationModalOpen: false,
    });
  };

  setOrderMode = () => {
    const stateManager = new StateManager();
    this.setState({
      orderMode: stateManager.orderMode,
      total: 0,
    });
  };

  _hideAuthModal = () => {
    this.setState({
      showAuthModal: false,
    });
  };
  _showAuthModal = () => {
    this.setState({
      showAuthModal: true,
    });
  };

  clearCart = () => {
    localStorage.removeItem("localCheckoutFromServer");
    this.setState({
      total: 0,
      cart: stateManager.carts,
    });
  };

  setSearchText = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  handleRegionChange = (region, config = {}) => {
    stateManager.region = region;
    stateManager.appConfig = config;
    if (
      stateManager.region &&
      stateManager.region.city === "Bengaluru" &&
      stateManager.region.isAirport
    ) {
      TagManager.initialize(tagManagerArgs);
    }
    this.setState({
      ...initialState(),
      region: region,
      appConfiguration: stateManager.appConfig,
    });
  };

  handleOrderModeChange = (orderMode, location) => {
    stateManager.orderMode = orderMode;
    stateManager.deliveryLocation = location;
    if (orderMode === ORDER_MODES.tableService) {
      stateManager.deliveryLocationType = "TABLE";
    } else if (orderMode === ORDER_MODES.seatService) {
      stateManager.deliveryLocationType = "SEAT";
    }
    this.setState({
      orderMode: orderMode,
      deliveryLocation: location,
      ...initialState(),
    });
  };

  handleFlightChange = (flight) => {
    stateManager.flight = flight;
    this.setState({
      ...initialState(),
      flight: flight,
    });
  };

  handleSeatNoChange = (seatNo) => {
    stateManager.seatNo = seatNo;
    this.setState({
      ...initialState(),
      seatNo: seatNo,
    });
  };

  handleTableNoChange = (tableNo) => {
    stateManager.tableNo = tableNo;
    this.setState({
      ...initialState(),
      tableNo: tableNo,
    });
  };

  handleDeliveryTimeChange = (deliveryTime) => {
    stateManager.deliveryTime = deliveryTime;
    this.setState({
      ...initialState(),
      deliveryTime: deliveryTime,
    });
  };

  handleDeliveryLocationChange = (deliveryLocation) => {
    stateManager.deliveryLocation = deliveryLocation;
    this.setState({
      ...initialState(),
      deliveryLocation: deliveryLocation,
    });
  };

  handleUserDetailsChange = (userDetails) => {
    stateManager.userProfile = userDetails;
    this.setState({
      ...initialState(),
      userDetails: userDetails,
    });
  };

  handleAppConfigurationUpdate = (data) => {
    console.error("App config update");
    stateManager.appConfig = data;
    this.setState({
      ...initialState(),
      appConfiguration: stateManager.appConfig,
    });
  };

  handleRedirectionUrl = (data, config = {}) => {
    console.error("Changing state app");
    stateManager.redirectionURL = data;
    stateManager.appConfig = config;
    this.setState({
      ...initialState(),
      redirectionURL: data,
    });
    console.log("state data", this.state);
  };

  openDescriptionModal = () => {
    this.setState({
      isDescriptionModalOpen: true,
    });
  };

  closeDescriptionModal = () => {
    this.setState({
      isDescriptionModalOpen: false,
    });
  };

  setDescriptionData = (openDescription = false, data) => {
    this.setState(
      {
        isDescriptionModalOpen: openDescription,
        descriptionData: data,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  setHasPendingPayment = (hasIt = false) => {
    this.setState({
      hasPendingPayment: hasIt,
    });
  };

  render() {
    const {
      cart,
      total,
      customizationData,
      isCustomizationModalOpen,
      orderMode,
      showAuthModal,
      isAuthenticated,
      searchText,
      screenDimensions,
      isSmallScreenDevice,
      region,
      selectedFlight,
      seatNo,
      tableNo,
      deliveryTime,
      deliveryLocation,
      userDetails,
      appConfiguration,
      redirectionURL,
      isDescriptionModalOpen,
      descriptionData,
      deliveryLocationType,
      hasPendingPayment,
    } = this.state;
    console.log(
      "local auth token",
      stateManager.authToken ? stateManager.authToken : false
    );
    return (
      <ApolloProvider client={client}>
        <AppConfigProvider>
          <GlobalStore.Provider
            value={{
              cart: cart,
              updateCart: this.setCart,
              isCustomizationModalOpen: isCustomizationModalOpen,
              total: total,
              customizationData: customizationData,
              setCustomizationData: this.setCustomizationData,
              closeCustomization: this.closeCustomizationModal,
              ordermode: orderMode,
              setOrderMode: this.handleOrderModeChange,
              isAuthenticated: isAuthenticated,
              setAuthState: this.onAuthVerified,
              handleCheckoutModal: this.handleCheckoutModal,
              showAuthModal: this._showAuthModal,
              hideAuthModal: this._hideAuthModal,
              searchText: searchText,
              setSearchText: this.setSearchText,
              screenDimensions: screenDimensions,
              isSmallScreenDevice: isSmallScreenDevice,
              // newly added
              region: region,
              setRegion: this.handleRegionChange,
              selectedFlight: selectedFlight,
              setSelectedFlight: this.handleFlightChange,
              selectedSeatNo: seatNo,
              setSelectedSeatNo: this.handleSeatNoChange,
              selectedTableNo: tableNo,
              setSelectedTableNo: this.handleTableNoChange,
              deliveryTime: deliveryTime,
              setDeliveryTime: this.handleDeliveryTimeChange,
              deliveryLocation: deliveryLocation,
              setDeliveryLocation: this.handleDeliveryLocationChange,
              deliveryLocationType: deliveryLocationType,
              userDetails: userDetails,
              setUserDetails: this.handleUserDetailsChange,
              appConfiguration: appConfiguration,
              setAppConfiguration: this.handleAppConfigurationUpdate,
              redirectionURL: redirectionURL,
              setRedirectionURL: this.handleRedirectionUrl,
              //showing description modal
              isDescriptionModalOpen: isDescriptionModalOpen,
              closeDescriptionModal: this.closeDescriptionModal,
              openDescriptionModal: this.openDescriptionModal,
              setDescriptionData: this.setDescriptionData,
              descriptionData: descriptionData,
              hasPendingPayment: hasPendingPayment,
              setHasPendingPayment: this.setHasPendingPayment,
            }}
          >
            <Router>
              <div id="devicediv">
                <Switch>
                  <Route
                    exact
                    path="/boarding"
                    render={(props) => (
                      <BoardingScreen
                        onOrderModeUpdate={this.handleOrderModeChange}
                        region={region}
                        appConfig={appConfiguration}
                        redirectionURL={redirectionURL}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/region/:url"
                    render={(props) => (
                      <RegionScreen
                        onRegionSelect={this.handleRegionChange}
                        onOrderModeUpdate={this.handleOrderModeChange}
                        region={region}
                        appConfig={appConfiguration}
                        redirectionURL={redirectionURL}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/region"
                    render={(props) => (
                      <LandingScreen
                        onRegionSelect={this.handleRegionChange}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/redirect/:redirectId"
                    render={(props) => (
                      <RedirectionScreen
                        onRedirectionSelect={this.handleRedirectionUrl}
                        appConfig={appConfiguration}
                        {...props}
                      />
                    )}
                  />
                  <Route path="/" component={ExploreRouter} />
                </Switch>
                {showAuthModal && (
                  <AuthenticationModal
                    onHideModal={this._hideAuthModal}
                    onShowModal={this._showAuthModal}
                    showModal={showAuthModal}
                    onAuthVerified={this.onAuthVerified}
                  />
                )}
              </div>
            </Router>
          </GlobalStore.Provider>
        </AppConfigProvider>
      </ApolloProvider>
    );
  }
}

export default MainRouter;
