import React, { PureComponent } from "react";
import { AccordionPanel, Accordion } from "grommet";
import StateManager from "../../utilities/StateManager";
import { MailOption } from "grommet-icons";
import classes from "./CheckoutCard.module.css";
import OrderTrack from "../OrderTrack/OrderTrack";
import Countdown from "../Countdown/Countdown";
import { Image } from "grommet";
import SeatInfoModal from "../SeatInfoModal/SeatInfoModal";
import { ReactComponent as LocationIcon } from "../../assets/location.svg";
import client from "../../apollo/client";
import {
  UPDATE_CHECKOUT_SEAT_NO,
  SEND_ORDER_INVOICE,
} from "../../apollo/mutations";
import Swal from "sweetalert2";
import moment from "moment";
import {
  ORDER_MODES,
  CUSTOMER_ORDER_STATUS,
  RUPEE_SYMBOL,
} from "../../utilities/config";

const msg = {
  delivery: "Order delivering in",
  takeaway: "Takeaway order ready in",
  dinein: "Order ready in",
  table_service: "Table service order ready in",
  seat_service: "Delivering order at seat in",
};
const paid = require("../../assets/paidStamp.png");
const pending = require("../../assets/pendingStamp.png");

const generateCustomizationNames = (customizations) => {
  let names = [];
  names = customizations.map((cust) => {
    return cust.node.name;
  });
  return names.join(", ");
};

const Stamp = (props) => {
  return (
    <div style={{ height: "50px" }}>
      <Image
        height={"75px"}
        src={props.pending ? pending : paid}
        fit="contain"
      />
    </div>
  );
};

class CheckoutCard extends PureComponent {
  state = {
    isOpen: false,
    activeIndex: null,
    countdownTime: null,
    orderMode: this.props.data.checkout.mode.toLowerCase(),
    updatedSeat:
      this.props.data.checkout.seatNo ||
      this.props.data.checkout.boardingGate ||
      this.props.data.checkout.deliveryLocation ||
      this.props.data.checkout.boardingGate
        ? true
        : false,
    openSeatInfoModal: false,
    seatNo: this.props.data.checkout.seatNo
      ? this.props.data.checkout.seatNo
      : this.props.data.checkout.deliveryLocation
      ? this.props.data.checkout.deliveryLocation
      : this.props.data.checkout.boardingGate
      ? this.props.data.checkout.boardingGate
      : null,
    checkoutId: this.props.data.checkout.id,
    createdAt: moment(this.props.data.carts[0].createdAt).format(
      "ddd, MMM Do YY, h:mm a"
    ),
    isLoading: false,
    delivered:
      this.props.data.status === "DELIVERED" ||
      ("DELIVERED" in this.props.data.status &&
        this.props.data.status.DELIVERED === this.props.data.carts.length),
  };

  componentDidMount() {
    console.log("props", this.props);
    this.getCountDownTime();
  }

  handleData = (e) => {
    this.setState({
      activeIndex: e[0],
    });
  };

  getCountDownTime = () => {
    const {
      checkout: { deliveryTime },
      carts,
    } = this.props.data;
    // console.log("Delivery time for the checkout is", deliveryTime);
    if (deliveryTime) {
      this.setState({
        countdownTime: deliveryTime,
      });
    } else {
      let max = 0;
      let createdAt = new Date();
      carts.forEach((element) => {
        if (element.cart.cartSla > max) {
          max = element.cart.cartSla;
          createdAt = new Date(element.createdAt);
        }
      });
      let countdownTime = createdAt.setSeconds(createdAt.getSeconds() + max);
      this.setState({
        countdownTime,
      });
    }
  };

  handleSeatUpdate = (data) => {
    const cartId = this.state.checkoutId;
    const input = {
      checkoutId: cartId,
      seatNo: data,
    };
    client
      .mutate({
        mutation: UPDATE_CHECKOUT_SEAT_NO(),
        variables: { input: input },
      })
      .then((res) => {
        this.setState({
          seatNo: data,
          updatedSeat: true,
          openSeatInfoModal: false,
        });
      })
      .catch((err) => {
        console.log("Error in updating the seat no", err);
      });
  };

  handleUpdateLater = () => {
    this.setState({
      openSeatInfoModal: false,
    });
  };

  sendMultiInvoice = () => {
    const { data: value } = this.props;
    let updates = [];
    const stateManager = new StateManager();
    const userProfile = stateManager.userProfile;
    const hasEmail = userProfile && userProfile.user && userProfile.user.email;
    console.log("has email", hasEmail);
    if (hasEmail) {
      value.carts.forEach((it) => {
        const orderId = it.id;
        const input = { id: orderId, sendemail: true };
        updates.push(
          client.mutate({
            mutation: SEND_ORDER_INVOICE(),
            variables: { input: input },
          })
        );
      });
      Promise.all(updates).then((res) => {
        console.log("response", res);
        Swal.fire({
          position: "center",
          html: `<p class="textI">Successfully sent the receipt for your order on your registered email.</p>`,
          confirmButtonColor: "#f55000",
          confirmButtonText: "Done",
        });
        this.setState({
          isLoading: false,
        });
      });
    } else {
      Swal.fire({
        title: "Oops!",
        text:
          "You have not set your email yet. Please update your email address in Accounts screen and try again.",
        icon: "info",
        confirmButtonColor: "#f55000",
      });
    }
  };

  onSendInvoice = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.sendMultiInvoice();
      }
    );
  };

  _openSeatInfoModal = () => {
    // this.setState({ openSeatInfoModal: true });
  };

  handleCheckoutUpdate = () => {
    console.error("update the status of picked up takeaway orders.");
  };

  isPaymentPending = (payments) => {
    for (const payment of payments.edges) {
      if (payment.node.status === "SUCCESS") return false;
    }
    return true;
  };

  render() {
    const { data: value, active } = this.props;
    const {
      activeIndex,
      countdownTime,
      orderMode,
      createdAt,
      delivered,
    } = this.state;
    console.log("Status of the checkout: ", value);
    return (
      <div className={classes.checkout_div}>
        <Accordion
          animate={true}
          activeIndex={activeIndex}
          onActive={this.handleData}
        >
          {/* Showing payment stamp if pending or paid*/}
          {value.checkout.mode === ORDER_MODES.tableService && (
            <Stamp pending={this.isPaymentPending(value.checkout.payments)} />
          )}
          {/* Need to be replaced dummy region*/}
          <div className={classes.timediv}>{createdAt}</div>
          {value.checkout.region && (
            <div className={classes.region}>
              <div>
                <LocationIcon height={15} width={15} fill={"orange"} />
              </div>
              <div className={classes.regiontext}>
                {value.checkout.region && value.checkout.region.name
                  ? value.checkout.region.name
                  : ""}
              </div>
            </div>
          )}
          <div></div>
          {!delivered &&
            orderMode &&
            value.checkout.mode !== ORDER_MODES.tableService &&
            active && <div className={classes.timerText}>{msg[orderMode]}</div>}
          {!delivered &&
            value.checkout.mode !== ORDER_MODES.tableService &&
            active &&
            countdownTime && <Countdown time={countdownTime} />}
          {active && value.checkout.mode !== ORDER_MODES.tableService && (
            <div className={classes.indicator_div}>
              <OrderTrack
                status={value.status}
                mode={value.checkout.mode}
                checkoutId={value.checkout.id}
                handleCheckoutUpdate={this.handleCheckoutUpdate}
                carts={value.carts}
              />
            </div>
          )}
          {/* {active && !this.state.updatedSeat && orderMode === "delivery" && (
            <button
              className={classes.updateSeatBtn}
              onClick={this._openSeatInfoModal}
            >
              UPDATE DELIVERY LOCATION
            </button>
          )} */}
          {this.state.updatedSeat && orderMode === ORDER_MODES.delivery && (
            <div className={classes.seatInfo}>
              <p className={classes.seatLabel}>
                {active ? "Delivering at" : "Delivered at"}
              </p>
              <button
                className={classes.seatNo}
                onClick={this._openSeatInfoModal}
              >
                {this.state.seatNo}
                {/* <EditIcon height={7} width={7} /> */}
              </button>
            </div>
          )}
          {this.state.openSeatInfoModal && (
            <SeatInfoModal
              isOpen={true}
              onNext={(s) => this.handleSeatUpdate(s)}
              onUpdateLater={this.handleUpdateLater}
            />
          )}
          <AccordionPanel
            header={
              <div className={classes.accordion_label}>
                {activeIndex && activeIndex > -1 ? "Hide" : "Show More"}
              </div>
            }
            className={classes.accordion_panel}
          >
            {value.carts.map((order) => (
              <div key={order.id} className={classes.store_div}>
                <div className={classes.store_detail_div}>
                  <p className={classes.store_text}>Order No.</p>
                  <p className={classes.status_text}>{order.orderId}</p>
                </div>
                <div className={classes.store_detail_div}>
                  <p className={classes.store_text}>Delivery OTP</p>
                  <p className={classes.status_text}>{order.cart.pickupPoint.store.isDeliveryOtp ? order.deliveryOtp : 'Not Required'}</p>
                </div>
                <div className={classes.store_detail_div}>
                  <p className={classes.store_text}>
                    {order.cart.pickupPoint.store.name}
                  </p>
                  <p className={classes.status_text}>
                    {CUSTOMER_ORDER_STATUS[order.status]}
                  </p>
                </div>
                {order.cart.cartItems.edges.map((prod) => {
                  return (
                    <div key={prod.node.id}>
                      <div className={classes.table_row} key={prod.node.id}>
                        <div className={classes.product_div}>
                          {prod.node.stock.sku.name} ({prod.node.quantity})
                        </div>
                        <div className={classes.price_div}>
                          {RUPEE_SYMBOL} {prod.node.total.toFixed(2)}
                        </div>
                      </div>
                      <div>
                        <p style={{ fontSize: 8, marginLeft: 12 }}>
                          {prod.node.stock.sku.variation
                            ? prod.node.stock.sku.variation
                            : ""}
                        </p>
                        <p style={{ fontSize: 8, marginLeft: 12 }}>
                          {generateCustomizationNames(
                            prod.node.customizations.edges
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {order.cart.specialInstructions && (
                  <div style={{ marginLeft: 4, marginTop: 12 }}>
                    <p style={{ color: "#ff5500", fontSize: 10 }}>
                      Special Instructions
                    </p>
                    <p style={{ fontSize: 10 }}>
                      {order.cart.specialInstructions}
                    </p>
                  </div>
                )}
              </div>
            ))}
            {value.extraData &&
              Object.entries(value.extraData).map(([key1, value1]) => (
                <div className={classes.total_row} key={key1}>
                  <div className={classes.product_div}>{key1}</div>
                  <div className={classes.qty_div}>{""}</div>
                  <div className={classes.price_div}>
                    {RUPEE_SYMBOL} {value1.toFixed(2)}
                  </div>
                </div>
              ))}
            <div className={classes.total_row} key={value.checkout.id}>
              <div className={classes.product_div}>Total</div>
              <div className={classes.qty_div}>{""}</div>
              <div className={classes.price_div}>
                {RUPEE_SYMBOL} {value.checkout.total.toFixed(2)}
              </div>
            </div>
            {!active && (
              <div>
                <button
                  className={classes.invoice_button}
                  onClick={this.onSendInvoice}
                >
                  <MailOption
                    height={14}
                    style={{ marginRight: 10 }}
                    color={"#f55000"}
                  />
                  Email Receipt
                </button>
              </div>
            )}
          </AccordionPanel>
        </Accordion>
      </div>
    );
  }
}

export default CheckoutCard;
