import React, { PureComponent } from "react";
import classes from "./CountryPicker.module.css";
import countryCodes from "../../utilities/countrycodes.json";

class CountryPicker extends PureComponent {
  _onChange = e => {
    this.props.onPhoneChange(`${e.target.value}`);
  };

  _countryChange = e => {
    // this.setState({ code: e.target.value });
    this.props.onCountryChange(e.target.value);
  };
  render() {
    const { code, phone } = this.props;
    return (
      <div className={classes.countryCodePicker}>
        <div className={classes.styled_select}>
          <select onChange={this._countryChange} value={code}>
            {countryCodes.map(country => (
              <option
                key={`${country.code}${country.name}`}
                value={country.code}
              >{`${country.flag} ${country.name}`}</option>
            ))}
          </select>
        </div>
        <p
          style={{
            width: "50px",
            fontSize: "24px",
            margin: 0,
            padding: 0,
            lineHeight: "40px"
          }}
        >
          {code}
        </p>
        <input
          onChange={this._onChange}
          value={`${phone}`}
          maxLength={10}
          autoFocus
          type={"number"}
          placeholder={"99XXXXX123"}
        />
      </div>
    );
  }
}

// CountryPicker.propTypes = {
//     code: "+91",
//     phone: ""
// };

export default CountryPicker;
