import React, { Component } from "react";
import classes from "./LandingScreen.module.css";
import { ReactComponent as GNGLogo } from "../../assets/gnglogo.svg";
import client from "../../apollo/client";
import { GET_AVAILABLE_REGIONS } from "../../apollo/queries";
import StateManager from "../../utilities/StateManager";
import Loading from "../../components/Loading";
import CategorisedList from "../../components/CategorisedList/CategorisedList";
import { categoriseRegionData } from "../../utilities/helpers";
import { AppConfigContext } from "../../data/AppConfiguration";

const stateManager = new StateManager();
class LandingScreen extends Component {
  static contextType = AppConfigContext;
  mounted = false;
  state = {
    isLoading: false,
    regions: {},
    serverData: [],
    selectedRegion: null,
    hasError: false,
    errorMessage: "",
    regionName: "",
    activeIndex: null,
  };
  componentDidMount() {
    this.mounted = true;
    const region = stateManager.region;
    this.mounted &&
      this.setState(
        {
          regionName: region && region.node && region.node.name,
        },
        () => {
          this.setLoadingState(this.getRegions);
        }
      );
  }

  componentDidCatch() {
    this.mounted &&
      this.setState({
        isLoading: false,
        hasError: true,
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  getRegions = () => {
    client
      .query({
        query: GET_AVAILABLE_REGIONS(),
      })
      .then((res) => {
        let data = res.data.regions.edges;
        this.mounted &&
          this.setState({
            isLoading: false,
            serverData: data,
            regions: categoriseRegionData(data),
          });
      })
      .catch((err) => {
        this.mounted &&
          this.setState({
            isLoading: false,
            hasError: true,
            errorMessage: err,
          });
      });
  };

  onRegionSelect = (regionData) => {
    const { setSelectedRegion } = this.context;
    const region = regionData.node;
    setSelectedRegion(region);
    console.log("Selected region", region);
    const {
      canChangeOrderMode,
      canChangeRegion,
      canShareCheckout,
      checkoutHeader,
      trendingLogo,
      shouldShowTrending,
      trendingName,
      terminal,
      isAirport,
      isFidsAvailable,
      productDisplayView,
    } = region;
    const config = {
      canChangeOrderMode,
      canChangeRegion,
      canShareCheckout,
      checkoutHeader,
      trendingLogo,
      shouldShowTrending,
      trendingName,
      terminal,
      isAirport,
      isFidsAvailable,
      productDisplayView,
    };
    this.props.onRegionSelect(region, config);

    this.mounted &&
      this.setState(
        {
          selectedRegion: region,
        },
        () => {
          this.onNextClick();
        }
      );
  };

  setLoadingState = (cb = () => {}) => {
    this.mounted &&
      this.setState(
        {
          isLoading: true,
        },
        () => {
          cb();
        }
      );
  };

  onNextClick = () => {
    this.props.history.push("/boarding");
  };

  render() {
    const { isLoading, regions, selectedRegion } = this.state;
    return (
      <div style={{ height: window.innerHeight }} className={classes.maindiv}>
        {!selectedRegion && !isLoading && (
          <div>
            <div className={classes.topdiv}>
              <div className={classes.logodiv}>
                <GNGLogo height={40} />
              </div>
              <div className={classes.spandiv}>
                <p className={classes.titleText}>Region</p>
              </div>
            </div>
            <div className={classes.content_div}>
              {Object.entries(regions).map(([key, values], index) => (
                <CategorisedList
                  onRegionSelect={this.onRegionSelect}
                  catName={key}
                  data={values}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
        {!selectedRegion && isLoading && <Loading open />}
      </div>
    );
  }
}

export default LandingScreen;
