import React, { PureComponent } from "react";
import classes from "./BottomNav.module.css";
import { withRouter } from "react-router";
import { ReactComponent as ExploreIcon } from "../../assets/gglogo.svg";
import { ReactComponent as CartIcon } from "../../assets/shopping-cart-color.svg";
import { ReactComponent as HomeWhiteIcon } from "../../assets/home_white.svg";
import { ReactComponent as HomeOrangeIcon } from "../../assets/home_orange.svg";
import GlobalStore from "../../utilities/GlobalState";
import { mergeCheckoutRequest } from "../../apollo/api";
import client from "../../apollo/client";
import { GET_USER_CHECKOUT } from "../../apollo/queries";
// import { generateCartFromCheckout } from "../../utilities/helpers";
import Swal from "sweetalert2";

class BottomNav extends PureComponent {
  state = {
    styleClasses: [classes.cta, classes.ctaClick].join(" "),
    home: false,
    explore: false,
    search: false,
    selected: "home",
  };

  componentDidMount() {
    const path = this.props.location.pathname || "";
    if (path.includes("/store")) {
      this.setState({
        selected: "explore",
      });
    } else if (path.includes("user")) {
      this.setState({
        selected: "user",
      });
    } else if (
      (path.includes("home") || path.includes("/")) &&
      !path.includes("/cart")
    ) {
      this.setState({
        selected: "home",
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const path = this.props.location.pathname || "";
      if (path.includes("/store")) {
        this.setState({
          selected: "explore",
        });
      } else if (path.includes("user")) {
        this.setState({
          selected: "user",
        });
      } else if (
        (path.includes("home") || path.includes("/")) &&
        !path.includes("/cart")
      ) {
        this.setState({
          selected: "home",
        });
      }
    }
  }

  goToHome = (e) => {
    this.setState(
      {
        selected: "home",
      },
      () => {
        this.props.history.push("/");
      }
    );
  };

  goToExplore = (e) => {
    // this.props.closeCheckout(false);
    if(this.state.selected !== 'explore'){
      this.setState(
        {
          selected: "explore",
        },
        () => {
          this.props.history.push("/store");
        }
      );
    }
  };

  onCartClick = () => {
    if (
      this.props.location.pathname &&
      this.props.location.pathname.includes("/cart")
    ) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/cart");
    }
  };

  onPaybill = async () => {
    let checkoutID = await mergeCheckoutRequest();
    checkoutID = checkoutID.checkout_id;
    if (checkoutID) {
      client
        .query({
          query: GET_USER_CHECKOUT(),
          variables: { id: checkoutID },
        })
        .then((res) => {
          // const checkoutFromServer = res.data.checkout;
          // const data = generateCartFromCheckout(checkoutFromServer);
          localStorage.setItem(
            "localCheckoutFromServer",
            JSON.stringify(res.data.checkout)
          );
          this.props.history.push("/cart");
        });
    } else {
      Swal.fire({
        position: "center",
        html: `<p class="textI">No payment pending</p>`,
        showCloseButton: true,
        confirmButtonColor: "#ff5500",
        confirmButtonText: "Okay",
      });
    }
  };

  render() {
    return (
      <GlobalStore.Consumer>
        {({ total, hasPendingPayment }) => (
          <div className={classes.maindiv}>
            <div className={classes.firstdiv}>
              <button onClick={this.goToHome}>
                {this.state.selected === "home" ? (
                  <HomeOrangeIcon height={20} width={20} fill={"red"} />
                ) : (
                  <HomeWhiteIcon height={20} width={20} fill={"red"} />
                )}
              </button>
            </div>
            <div className={classes.second_div}>
              <button
                onClick={this.goToExplore}
                className={[
                  classes.cta,
                  classes.ctaClick,
                  classes.explore,
                ].join(" ")}
              >
                <ExploreIcon height={15} width={15} fill={"red"} />
                Explore
              </button>
            </div>
            <div className={classes.firstdiv}>
              <button
                className={classes.cart_button}
                onClick={this.onCartClick}
              >
                <CartIcon height={20} width={20} fill={"#FFFFFF"} />
                <p className={classes.item_indicator}>{total}</p>
              </button>
            </div>
            {hasPendingPayment && (
              <div className={classes.firstdiv}>
                <button
                  className={classes.pay_bill_button}
                  onClick={this.onPaybill}
                >
                  <p>Pay bill</p>
                </button>
              </div>
            )}
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default withRouter(BottomNav);
