import React, { PureComponent } from "react";
import { ReactComponent as ExploreIcon } from "../../assets/gglogo.svg";
import classes from "./Searchbar.module.css";

class Searchbar extends PureComponent {
  render() {
    const { setSearchText } = this.props;
    return (
      <div className={classes.searchbar}>
        <div className={classes.searchbox}>
          <button className={classes.btn_search}>
            <ExploreIcon />
          </button>
          <input
            id="search"
            type="text"
            placeholder="Search..."
            name="search"
            onChange={setSearchText}
            className={classes.search}
          />
        </div>
      </div>
    );
  }
}

export default Searchbar;
