import React, { Component } from "react";
import classes from "./Countdown.module.css";

class Countdown extends Component {
  mounted = false;
  state = {
    time: this.props.time,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    showCountdown: true
  };
  componentDidMount() {
    this.mounted = true;
    this.getMinutes();
  }
  componentWillUnmount() {
    this.mounted = false;
    // clearTimeout(this.timeout);
  }

  getMinutes = () => {
    const { showCountdown } = this.state;
    let days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0;
    const difference =
      new Date(this.props.time).getTime() - new Date().getTime();
    if (difference > 0) {
      days = Math.floor(difference / (1000 * 60 * 60 * 24));
      hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((difference % (1000 * 60)) / 1000);
    } else {
      days = Math.ceil(difference / (1000 * 60 * 60 * 24));
      hours = Math.ceil(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      minutes = Math.ceil((difference % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.ceil((difference % (1000 * 60)) / 1000);
    }

    this.mounted &&
      this.setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      });
    if (showCountdown) {
      this.timeout =
        this.mounted &&
        setTimeout(() => {
          this.mounted && this.getMinutes();
        }, 1000);
    }
  };

  render() {
    const { days, minutes, hours, seconds } = this.state;

    return (
      <div className={classes.block}>
        {seconds >= 0 && minutes >= 0 && (
          <div className={classes.countdownBlock}>
            {days > 0 && (
              <div className={classes.countdownElement}>
                <p className={classes.countdownDigit}>{days}</p>
                <p className={classes.countdownDigitLabel}>DAYS</p>
              </div>
            )}
            {hours > 0 && (
              <div className={classes.countdownElement}>
                <p className={classes.countdownDigit}>{hours}</p>
                <p className={classes.countdownDigitLabel}>HOURS</p>
              </div>
            )}
            {minutes >= 0 && (
              <div className={classes.countdownElement}>
                <p className={classes.countdownDigit}>{minutes}</p>
                <p className={classes.countdownDigitLabel}>MINS</p>
              </div>
            )}
            {seconds >= 0 && (
              <div className={classes.countdownElement}>
                <p className={classes.countdownDigit}>{seconds}</p>
                <p className={classes.countdownDigitLabel}>SECONDS</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Countdown;
