import React, { createContext, useState } from "react";
import {
  LOCAL_USER_CODE,
  LOCAL_OFFER_DETAILS,
  LOCAL_FREE_MEAL_COUNT,
} from "../utilities/config";

export const AppConfigContext = createContext();

export const AppConfigProvider = ({ ...props }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedOrderMode, setSelectedOrderMode] = useState(null);
  const [redirectionUrl, setRedirectionUrl] = useState(null);
  const [userCode, setUserCode] = useState(
    JSON.parse(localStorage.getItem(LOCAL_USER_CODE)) || ""
  );
  const [offerDetails, setOfferDetails] = useState(
    JSON.parse(localStorage.getItem(LOCAL_OFFER_DETAILS)) || null
  );
  const [freeMealCount, setFreeMealCount] = useState(
    JSON.parse(localStorage.getItem(LOCAL_FREE_MEAL_COUNT)) || 0
  );

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    setSelectedOrderMode(null);
    setRedirectionUrl(null);
  };

  const handleRedirectionUrlChange = (url, region) => {
    setRedirectionUrl(url);
    setSelectedRegion(region);
    setSelectedOrderMode(null);
  };

  const handleofferDetailChange = (offerData) => {
    localStorage.setItem(LOCAL_OFFER_DETAILS, JSON.stringify(offerData));
    setOfferDetails(offerData);
    handleFreeMealChange(getFreeMealCount(offerData));
  };

  const handleFreeMealChange = (count) => {
    localStorage.setItem(LOCAL_FREE_MEAL_COUNT, JSON.stringify(count));
    setFreeMealCount(count);
  };

  const handleUserCodeChange = (code) => {
    localStorage.setItem(LOCAL_USER_CODE, JSON.stringify(code));
    setUserCode(code);
  };

  const getFreeMealCount = (offerData) => {
    if (offerData) {
      const { stockAllowedQuantity, usedRedemptions } = offerData;
      if (stockAllowedQuantity) {
        if (usedRedemptions) {
          return stockAllowedQuantity - usedRedemptions;
        }
        return stockAllowedQuantity;
      }
      return 0;
    }
    return 0;
  };

  return (
    <AppConfigContext.Provider
      value={{
        selectedRegion,
        setSelectedRegion: handleRegionChange,
        selectedOrderMode,
        setSelectedOrderMode,
        userCode,
        setUserCode: handleUserCodeChange,
        redirectionUrl,
        setRedirectionUrl: handleRedirectionUrlChange,
        offerDetails,
        setOfferDetails: handleofferDetailChange,
        freeMealCount,
        setFreeMealCount: handleFreeMealChange,
      }}
    >
      {props.children}
    </AppConfigContext.Provider>
  );
};
