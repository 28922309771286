export const APP_ENVIRONMENTS = {
  dev: "development",
  prod: "production",
};

//URLS
export const PAYTM_RESPONSE_ORIGIN_URL = process.env.REACT_APP_BASE_URL;
// export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const GNGCF_URL = process.env.REACT_APP_CF_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const HELP_URL = process.env.REACT_APP_HELP_URL;
export const GENERATE_OTP_URL = process.env.REACT_APP_GENERATE_OTP_URL;
export const DIRECT_LOGIN_URL = process.env.REACT_APP_DIRECT_LOGIN;
export const SUBMIT_OTP_URL = process.env.REACT_APP_SUBMIT_OTP_URL;
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const TERMS_OF_USE_URL = process.env.REACT_APP_TERMS_OF_USE_URL;
export const REFUND_POLICY_URL = process.env.REACT_APP_REFUND_POLICY_URL;
export const OFFER_TERMS_URL = process.env.REACT_APP_OFFER_TERMS_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const MERGE_CHECKOUT_URL = process.env.REACT_APP_MERGE_CHECKOUT_URL;

//KEYS
export const GHOST_TOKEN = process.env.REACT_APP_GHOST_AUTH_TOKEN;
export const RAZOR_RAZOR_KEY = process.env.REACT_APP_REACT_APP_RAZORPAY_KEY;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const GA_KEY = process.env.REACT_APP_GA_KEY;
export const USER_PROFILE_ON_DEVICE_KEY =
  process.env.REACT_APP_USER_PROFILE_ON_DEVICE_KEY;
export const REGION_KEY_LOCAL = process.env.REACT_APP_REGION_KEY_LOCAL; // stores the region id
export const PREVIOUS_ORDERS_LOCAL_KEY =
  process.env.REACT_APP_PREVIOUS_ORDERS_LOCAL_KEY; // stores an array of current orders
export const CHECKOUT_LOCAL_KEY = process.env.REACT_APP_CHECKOUT_LOCAL_KEY;
export const CHECKOUT_ITEM_COUNT = process.env.REACT_APP_CHECKOUT_ITEM_COUNT;
export const AUTH_TOKEN_KEY = process.env.REACT_APP_LOCAL_AUTH_TOKEN_KEY;
export const LOCAL_REDIRECTION_DATA_KEY =
  process.env.REACT_APP_LOCAL_REDIRECTION_DATA_KEY;
export const LOCAL_CHECKOUT_COMMENT =
  process.env.REACT_APP_LOCAL_CHECKOUT_COMMENT;
export const LOCAL_SELECTED_FLIGHT =
  process.env.REACT_APP_LOCAL_SELECTED_FLIGHT;
export const LOCAL_ORDER_MODE = process.env.REACT_APP_LOCAL_GNG_ORDER_MODE;

export const FSTR_GTM_TAG = process.env.REACT_APP_FSTR_GTM_TAG;

export const LOCAL_USER_CODE = "LOCAL_USER_CODE";
export const LOCAL_OFFER_DETAILS = "LOCAL_OFFER_DETAILS";
export const LOCAL_FREE_MEAL_COUNT = "LOCAL_FREE_MEAL_COUNT";
/**
 * Was meant to take over the world but nowadays relies heavily on the whims of a highly volatile POTUS
 */
export const RUPEE_SYMBOL = "₹";

// export const RUPEE_SYMBOL = "$";

/**
 * Payment status for updatePayment mutation
 */
export const PAYMENT_STATUS = {
  init: "INIT",
  success: "SUCCESS",
  failure: "FAILURE",
};
/**
 * Payment Mode for the order
 */
export const PAYMENT_MODES = {
  paytm: "PAYTM",
  wallet: "WALLET",
  razorpay: "RAZORPAY",
  paylater: "PAYLATER",
  payOnDelivery: "PAY_ON_DELIVERY",
  payAtCounter: "PAY_AT_COUNTER",
  zeropayment: "ZERO PAYMENNT",
};

/**
 * Paytm Payment responses Enum
 */
export const PAYTM_STATUS = {
  success: "TXN_SUCCESS",
  failure: "TXN_FAILURE",
};

export const ORDER_MODES = {
  dinein: "DINEIN",
  takeaway: "TAKEAWAY",
  delivery: "DELIVERY",
  tableService: "TABLE_SERVICE",
  seatService: "SEAT_SERVICE",
};

export const AVAILABLE_ORDER_MODES = [
  "DINEIN",
  "TAKEAWAY",
  "DELIVERY",
  "TABLE_SERVICE",
];

export const ORDER_STATUS = {
  new: "New",
  pushed: "Pushed",
  accepted: "Accepted",
  ready: "Ready",
  dispatched: "Dispatched",
  delivered: "Delivered",
  rejected: "Rejected",
};

/**
 *
 * @param {Number} factorW - a number b/w 0-1
 * @param {Number} factorH - a number b/w 0-1
 * @rerurn factored device screen width, and height
 */
export const SCREEN_PARAMS = (factorW = 1, factorH = 1) => {
  return {
    Width: Math.floor(window.screen.width * factorW),
    Height: Math.floor(window.screen.height * factorH),
  };
};

export const IOS_DEVICE_VALUES = ["iPhone", "iPod", "iPad"];

export const generateDeviceId = () => {
  return Math.floor(Math.random() * 1000000000);
};

/**
 * Firebase Configuration
 */

export const ADD_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const SHOW_CUSTOMIZATION_MODAL = "SHOW_CUSTOMIZATION_MODAL";
export const HIDE_CUSTOMIZATION_MODAL = "HIDE_CUSTOMIZATION_MODAL";

// Events
export const STOCK_ADDED = "STOCK_ADDED";
export const STOCK_REMOVED = "STOCK_REMOVED";
export const CUSTOMIZE = "CUSTOMIZE";
export const CART_CLEARED = "CART_CLEARED";
export const CHANGE_ORDER_MODE = "CHANGE_ORDER_MODE";

export const DELIVERY_ORDER_STATES = [
  "ACCEPTED",
  "READY",
  "DISPATCHED",
  "DELIVERED",
];

export const TAKEAWAY_ORDER_STATES = ["ACCEPTED", "READY", "DELIVERED"];

export const CITY_CODE_LIST = {
  Bengaluru: "BLR",
  Mumbai: "BOM",
  Delhi: "DEL",
};

export const CUSTOMER_ORDER_STATUS = {
  NEW: "Ordered",
  PUSHED: "Placed",
  REJECTREQUEST: "Rejected",
  ACCEPTED: "In kitchen",
  REJECTED: "Rejected",
  READY: "Ready",
  DISPATCHED: "Out for delivery",
  DELIVERED: "Delivered",
  ATDELIVERYPOINT: "At delivery location",
  PICKEDUP: "Received",
};

export const CUSTOMER_ORDER_MODE = {
  DELIVERY: "DELIVERY",
  TABLE_SERVICE: "TABLE SERVICE",
  TAKEAWAY: "TAKEAWAY",
  DINEIN: "DINEIN",
  SEAT_SERVICE: "SEAT SERVICE",
};

export const IS_SMALL_SCREEN_DEVICE = window.innerHeight < 720;

export const AVAILABLE_PAYMENT_MODES = [
  "card",
  "upi",
  "upi_intent",
  "netbanking",
  "wallet",
  "emi",
  "nach",
  "prepaid_card",
];

export const CUSTOMER_PAYMENT_MODES = {
  wallet: "Wallet",
  card: "Credit, Debit & ATM Cards",
  netbanking: "Netbanking",
  upi: "UPI",
  airtelmoney: "Airtel Money",
  amazonpay: "Amazon Pay",
  freecharge: "Freecharge",
  jiomoney: "Jiomoney",
  phonepe: "PhonePe",
  mobikwik: "Mobikwik",
  payzapp: "PayZapp",
  upi_intent: "UPI Apps",
  emi: "EMI",
  prepaid_card: "Other Cards",
};

export const PRODUCT_DISPLAY_VIEW = {
  card: "CARDVIEW",
  list: "LISTVIEW",
};

export const DELIVERY_LOCATION_TYPE = {
  table: "TABLE",
  seat: "SEAT",
  apartment: "APARTMENT",
};
