import React, { PureComponent } from "react";
import { Prompt } from "react-router-dom";
import classes from "./CheckoutModal.module.css";
import Spinner from "react-spinkit";
import { ReactComponent as GNGLogo } from "../../assets/gnglogo.svg";
import CartManager from "../../utilities/CartManager";
import {
  GET_UPDATED_CHECKOUT,
  INIT_PAYMENT,
  UPDATE_SPECIAL_INSTRUCTIONS,
  // UPDATE_SPECIAL_INSTRUCTIONS
} from "../../apollo/mutations";
import { GET_USER_ADDRESS } from "../../apollo/queries";
import {
  calculateCheckoutExtra,
  createSpecialInstructionInput,
  generateCheckoutInput,
} from "../../utilities/helpers";
import client from "../../apollo/client";
import {
  PAYMENT_STATUS,
  PAYMENT_MODES,
  RAZOR_RAZOR_KEY,
  ORDER_MODES,
  CUSTOMER_ORDER_MODE,
  RUPEE_SYMBOL,
} from "../../utilities/config";
import StateManager from "../../utilities/StateManager";
import SeatInfoModal from "../SeatInfoModal/SeatInfoModal";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import CartItem from "./CartItem";
import Recommendation from "../Recommendation/Recommendation";
import { AppConfigContext } from "../../data/AppConfiguration";
import TagManager from "react-gtm-module";
import ApplyCode from '../ApplyCode';

const fassaiLogo = require("../../assets/logo_fassai.webp");

const shouldShowOnlinePayment = (region, localCheckoutData, orderMode) => {
  // region doesn't have online payment enabled and localcheckout data is there and region has multi checkout
  if (
    region &&
    region.hasMultiCheckoutPayment &&
    orderMode === ORDER_MODES.tableService &&
    (localCheckoutData || !region.isOnlinePayment)
  ) {
    return false;
  }
  return true;
};

const shouldShowPayAtCounter = (region, localCheckoutData, orderMode) => {
  if (
    region &&
    region.isPayAtCounter &&
    region.hasMultiCheckoutPayment &&
    orderMode === ORDER_MODES.tableService &&
    localCheckoutData
  ) {
    return true;
  }
  return false;
};

const shouldShowPayLater = (region, localCheckoutData, orderMode) => {
  if (
    region &&
    region.hasMultiCheckoutPayment &&
    orderMode === ORDER_MODES.tableService &&
    !localCheckoutData
  ) {
    return true;
  }
  return false;
};

const stateManager = new StateManager();
class CheckoutModal extends PureComponent {
  static contextType = AppConfigContext;
  mounted = false;
  state = {
    isLoading: false,
    isAuthError: false,
    checkoutFromServer: null,
    isEditingItem: false,
    isFetchingPayment: false,
    hasError: false,
    errorMsg: null,
    paymentMode: null,
    paymentInitRes: null,
    showPayButtons: false,
    razorPayOrderId: null,
    token: null,
    specialInstructions: {},
    isModeDelivery: false,
    deliverySeatNumber: null,
    shouldShowSeatModal: false,
    isPayOnDeliveryAvailable: false,
    isPaymentLoading: false,
    requestCount: 0,
    flightNo: "",
    boardingGate: "",
    seatNo: "",
    orderMode: "",
    region: null,
    gateOnly: false,
    gateNumber: "",
    timeSlotsAvailable: false,
    timeSlot: null,
    date: null,
    addressNames: [],
    code:"",
  };

  componentDidMount() {
    this.getUserAddresses();
    this.mounted = true;
    const localCheckoutData = JSON.parse(
      localStorage.getItem("localCheckoutFromServer")
    );
    const { orderMode } = this.props;
    const stateManager = new StateManager();
    const gate = stateManager.deliveryLocation;
    const timeSlot = stateManager.timeSlot;
    console.log("time slot- ---", timeSlot);
    if (timeSlot) {
      this.setState({
        timeSlot: timeSlot,
        timeSlotsAvailable: true,
      });
    }
    if (!orderMode || orderMode === undefined) {
      this.props.history.replace("/boarding");
    } else {
      if (gate) {
        this.setState({
          gateOnly: true,
          gateNumber: gate,
        });
      }
      const selectedFlight = stateManager.flight;
      const region = stateManager.region;
      const flightNo =
        selectedFlight && selectedFlight.flight_number
          ? selectedFlight.flight_number
          : "";
      const boardingGate = stateManager.deliveryLocation;
      const seatNo =
        orderMode === "DELIVERY" ? stateManager.seatNo : stateManager.tableNo;

      this.mounted &&
        this.setState(
          {
            isLoading: localCheckoutData ? false : true,
            flightNo: flightNo,
            boardingGate: boardingGate,
            seatNo: seatNo,
            orderMode: orderMode,
            region: region,
            checkoutFromServer: localCheckoutData,
          },
          () => {
            console.log("Checkout data ------->", localCheckoutData);
            if (!localCheckoutData) {
              this.getCheckOut();
            }
          }
        );
    }
  }
  setStateValue = (vcode) =>{
    console.log('setStateValue', vcode)
    this.setState({
      code: vcode,
    });
    /*console.log('setStateValue-2', vcode)
    console.log('setStateValue-3', this.state.code)
    */
  }
  onApplyCouponCode = (ncode) =>{
    this.setStateValue(ncode);
    /*console.log('onApplyCouponCode', this.state.code)*/
    this.getCheckOut(ncode);
  }

  getUserAddresses = () => {
    client
      .query({
        query: GET_USER_ADDRESS(),
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        const addresses = res.data.userLocations.edges;
        if (addresses) this.getAddressNames(addresses);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAddressNames = (addresses) => {
    const addressNames = [];
    for (const address of addresses) {
      addressNames.push(address.node.name);
    }
    this.setState({
      addressNames: addressNames,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.isUserAuthenticated !== prevProps.isUserAuthenticated &&
      this.props.isUserAuthenticated
    ) {
      console.log("Auth state has changes");
      this.setState(
        {
          hasError: false,
          isAuthError: false,
        },
        () => {
          this.getCheckOut();
        }
      );
    } else if (this.props.cart !== prevProps.cart) {
      console.log("gettting the checkout");
      this.getCheckOut();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.closeAuthModal();
  }

  generatePaymentConfig = () => {
    const stateManager = new StateManager();
    const region = stateManager.region;
    const orderMode = stateManager.orderMode;
    console.log("Payment related config", region, orderMode);
    let shouldShowSeatModal = false;
    let isPayOnDeliveryAvailable = false;
    if (
      (orderMode === ORDER_MODES.delivery && region.isDeliveryAtSticker) ||
      orderMode === ORDER_MODES.tableService
    ) {
      //make it true for delivery at sticker
      shouldShowSeatModal = false;
    }
    region &&
      region.regionOrderModes.edges.forEach((mode) => {
        if (mode.node.orderMode.name === orderMode) {
          isPayOnDeliveryAvailable = mode.node.isPayOnDelivery;
        }
      });
    this.setState({
      shouldShowSeatModal,
      isPayOnDeliveryAvailable,
    });
  };

  getCartIds = (carts) => {
    let specialInstructions = {};
    let localSpecialinst = stateManager.specialInstructions;
    console.log("special instructions", localSpecialinst);
    localSpecialinst &&
      carts.carts.edges.map(
        (i) =>
          (specialInstructions[i.node.id] = {
            isOpen: false,
            text: localSpecialinst[i.node.pickupPoint.id]
              ? localSpecialinst[i.node.pickupPoint.id].text
              : "",
          })
      );
    return specialInstructions;
  };

  getCheckOut = (ncode) => {
    const cartManager = new CartManager();
    const carts = cartManager.createCheckoutFromCarts();
    const { userCode } = this.context;
    const stateManager = new StateManager();
    const selectedFlight = stateManager.flight;
    let {
      orderMode,
      deliveryLocationType,
      deliveryLocation,
      region,
    } = this.props;
    console.log("delivery llcation ---->", deliveryLocation);
    const { boardingGate, seatNo, gateNumber, timeSlot } = this.state;
    const flightNumber =
      selectedFlight && selectedFlight.flight_number
        ? selectedFlight.flight_number
        : "";
    const boardingGates = boardingGate ? boardingGate : gateNumber;
    const deliveryTime = timeSlot;
    const userDeliveryLocation = `${
      deliveryLocationType ? deliveryLocationType : ""
    } ${deliveryLocation}`;

    const input = generateCheckoutInput({
      orderMode,
      carts,
      regionId: region.id,
      flightNumber,
      boardingGates,
      seatNo,
      deliveryTime,
      deliveryLocation: userDeliveryLocation,
      //code: userCode,
      code: ncode
    });
    console.log("input for the checkout", input);
    const localCheckoutData = JSON.parse(
      localStorage.getItem("localCheckoutFromServer")
    );
    !localCheckoutData &&
      client
        .mutate({
          mutation: GET_UPDATED_CHECKOUT(),
          variables: { input: input },
        })
        .then((res) => {
          console.log(
            "got checkout from server",
            res.data.updateCheckout.checkout
          );
          if (
            res.data.updateCheckout.checkout &&
            !res.data.updateCheckout.isGhost
          ) {
            this.mounted &&
              this.setState({
                isLoading: false,
                isEditingItem: false,
                checkoutFromServer: res.data.updateCheckout.checkout,
                token: res.data.updateCheckout.checkout.user.phone.slice(-4),
                specialInstructions: this.getCartIds(
                  res.data.updateCheckout.checkout
                ),
              });
          } else {
            this.mounted &&
              this.setState(
                {
                  isLoading: false,
                  isEditingItem: false,
                  hasError: true,
                  isAuthError: true,
                },
                () => {
                  const logoutEvent = new CustomEvent("clearauth");
                  window.dispatchEvent(logoutEvent);
                }
              );
          }
        })
        .catch((err) => {
          if (
            err.message === "GraphQL error: You must be logged in to proceed!"
          ) {
            this.mounted &&
              this.setState(
                {
                  isLoading: false,
                  isEditingItem: false,
                  hasError: true,
                  isAuthError: true,
                },
                () => {
                  const logoutEvent = new CustomEvent("clearauth");
                  window.dispatchEvent(logoutEvent);
                }
              );
          } else {
            this.mounted &&
              this.setState({
                isLoading: false,
                isEditingItem: false,
                hasError: true,
              });
          }
          console.log("Error in update Checkout", err, err.message);
        });
  };

  onAddItem = (item, pickupPoint) => {
    const cartManager = new CartManager();
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    const stock = item.stock;
    this.setState(
      {
        isEditingItem: true,
      },
      () => {
        const customizations =
          item.customizations && item.customizations.edges
            ? item.customizations.edges.map((it) => {
                return it.node.id;
              })
            : [];
        if (
          offerDetails &&
          offerDetails.offerStocks &&
          offerDetails.offerStocks.includes(stock.id) &&
          freeMealCount < 1
        ) {
          Swal.fire({
            text: "You have exhausted complimentary meal quota",
            title: "Error",
            icon: "error",
            confirmButtonColor: "#ff5500",
          });
        } else {
          const shouldUpdate = cartManager.addStockToCart(
            { id: pickupPoint.id, name: pickupPoint.store.name },
            { id: stock.id, name: stock.sku.name, price: stock.price },
            1,
            customizations
          );
          if (shouldUpdate) {
            if (
              offerDetails &&
              offerDetails.offerStocks &&
              offerDetails.offerStocks.includes(stock.id)
            ) {
              setFreeMealCount(freeMealCount - 1);
            }
            this.props.updateCart();
            // this.getCheckOut();
          }
        }
      }
    );
  };

  onRemoveItem = (item, pickupPoint) => {
    const cartManager = new CartManager();
    const stock = item.stock;
    const { setFreeMealCount, freeMealCount, offerDetails } = this.context;
    this.setState(
      {
        isEditingItem: true,
      },
      () => {
        const customizations =
          item.customizations && item.customizations.edges
            ? item.customizations.edges.map((it) => {
                return it.node.id;
              })
            : [];
        const shouldUpdate = cartManager.removeStockFromCart(
          pickupPoint.id,
          stock.id,
          customizations
        );
        if (shouldUpdate) {
          if (
            offerDetails &&
            offerDetails.offerStocks &&
            offerDetails.offerStocks.includes(stock.id)
          ) {
            setFreeMealCount(freeMealCount + 1);
          }
          this.props.updateCart();
          // this.getCheckOut();
        }
      }
    );
  };

  onPaymentInit = (payMode) => {
    const { checkoutFromServer, specialInstructions } = this.state;
    const { redirectionURL } = this.props;
    let mode = payMode;
    console.log("amount", checkoutFromServer.total);
    if (checkoutFromServer.total < 1) {
      mode = PAYMENT_MODES.zeropayment;
    }
    const specialForUpdate = createSpecialInstructionInput(specialInstructions);
    this.mounted &&
      this.setState(
        {
          isFetchingPayment: true,
          hasError: false,
          paymentMode: mode,
          isPaymentLoading: true,
        },
        () => {
          client
            .mutate({
              mutation: UPDATE_SPECIAL_INSTRUCTIONS(),
              variables: {
                input: { specialInstructionsList: specialForUpdate },
              },
            })
            .then((res) => {
              // get locally stored data
              const stateManager = new StateManager();
              const redirectionId = redirectionURL ? redirectionURL.id : "";
              const orderMode = stateManager.orderMode;
              console.log("order mode", orderMode);
              const input = {
                amount: checkoutFromServer.total,
                status: PAYMENT_STATUS.init,
                mode: mode,
                token: checkoutFromServer.token,
                redirectionUrl: redirectionId,
              };
              this.mounted &&
                client
                  .mutate({
                    mutation: INIT_PAYMENT(),
                    variables: { input: input },
                  })
                  .then((res) => {
                    console.log("Payment response", res.data.updatePayment);
                    this.setState(
                      {
                        isFetchingPayment: true,
                        hasError: false,
                        paymentInitRes: res.data.updatePayment.payment,
                        razorPayOrderId: res.data.updatePayment.razorpayOrderId,
                        isPaymentLoading: false,
                      },
                      () => {
                        if (
                          mode === PAYMENT_MODES.payOnDelivery ||
                          mode === PAYMENT_MODES.paylater ||
                          mode === PAYMENT_MODES.zeropayment
                        ) {
                          this.checkPaymnetStatus();
                        } else if (mode === PAYMENT_MODES.payAtCounter) {
                          this.oncompletepay();
                          this.props.setHasPendingPayment(false);
                        } else {
                          this.onRazorpayInit(
                            this,
                            res.data.updatePayment.razorpayOrderId,
                            checkoutFromServer.total
                          );
                          this.razorpay.open();
                          this.props.setHasPendingPayment(false);
                        }
                      }
                    );
                  })
                  .catch((err) => {
                    console.log(
                      "error in getting payment for this checkout",
                      err
                    );
                    this.setState({
                      isFetchingPayment: true,
                      hasError: true,
                      errorMsg: err,
                      isPaymentLoading: false,
                    });
                  });
            })
            .catch((err) => {
              console.log("error in updating special instructions", err);
            });
        }
      );
  };

  checkPaymnetStatus = () => {
    const { paymentInitRes, paymentMode, requestCount, checkoutFromServer } = this.state;
    let maxRequestCount = 21;
    let timeInterval = 5;
    if (paymentMode === PAYMENT_MODES.payAtCounter) {
      timeInterval = 30;
      maxRequestCount = 30;
    }
    this.mounted &&
      this.setState(
        (prevState) => ({
          isPaymentLoading: true,
          requestCount: prevState.requestCount + 1,
        }),
        () => {
          const stateManager = new StateManager();
          const redirectionId = stateManager.redirectionURL
            ? stateManager.redirectionURL.id
            : "";
          const input = {
            amount: paymentInitRes.amount,
            status: PAYMENT_STATUS.success,
            mode: paymentMode,
            token: paymentInitRes.token,
            redirectionUrl: redirectionId,
          };

          client
            .mutate({
              mutation: INIT_PAYMENT(),
              variables: { input: input },
            })
            .then((res) => {
              let paymentText = "Payment Successful";
              if (paymentMode === PAYMENT_MODES.paylater) {
                paymentText = "Order Placed";
                this.props.setHasPendingPayment(true);
              }
              if(checkoutFromServer.total < 1){
                paymentText = 'Order Placed'
              }
              const payment = res.data.updatePayment.payment;
              if (
                payment.status === "SUCCESS" ||
                payment.status === "PENDING"
              ) {
                this.setState(
                  {
                    isPaymentLoading: false,
                  },
                  () => {
                    stateManager.carts = null;
                    stateManager.timeSlot = null;
                    if (
                      stateManager.region &&
                      stateManager.region.city === "Bengaluru" &&
                      stateManager.region.isAirport
                    ) {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "purchase",
                          transaction_id: payment.transactionId,
                          revenue: payment.amount,
                        },
                      });
                    }
                    localStorage.removeItem("localCheckoutFromServer");
                    Swal.fire({
                      position: "center",
                      html: `<p class="textI">${paymentText}</p>`,
                      showCloseButton: true,
                      confirmButtonColor: "#f55000",
                      confirmButtonText: "Done",
                      onClose: this.onSwalClose,
                    });
                  }
                );
              } else if (
                payment.status === "INIT" &&
                requestCount < maxRequestCount
              ) {
                this.paymentTimeOut = setTimeout(() => {
                  this.checkPaymnetStatus();
                }, timeInterval * 1000);
              } else {
                this.setState(
                  {
                    isPaymentLoading: false,
                  },
                  () => {
                    Swal.fire({
                      position: "center",
                      html: `<p class="textI">Payment failed</p>`,
                      showCloseButton: true,
                      confirmButtonColor: "red",
                      confirmButtonText: "Try again",
                    });
                  }
                );
              }
            })
            .catch((err) => {
              console.log("Error in getting payment status", err);
            });
        }
      );
  };

  oncompletepay = () => {
    Swal.fire({
      position: "center",
      html: `<p class="textI">Please proceed to counter to pay.</p>`,
      showCloseButton: true,
      confirmButtonColor: "#ff5500",
      confirmButtonText: "Okay",
    });
  };

  onSwalClose = () => {
    setTimeout(() => {
      const stateManager = new StateManager();
      stateManager.carts = null;
      this.props.updateCart();
      this.props.history.push("");
      // this.props.onClose(false);
    }, 500);
    console.log("payment closed");
  };

  closePaymentModal = () => {
    this.setState({
      showPayButtons: false,
    });
  };

  handleBookDeliverySlot = () => {
    this.props.history.replace("/slot-booking");
  };

  handleAddDeliveryAddress = () => {
    this.props.history.replace("/delivery-address");
  };

  onRazorpayInit = (that, orderId, amount) => {
    const stateManager = new StateManager();
    const userprofile = stateManager.userProfile;
    const newAmount = amount * 100;
    let options = {
      key: RAZOR_RAZOR_KEY,
      amount: newAmount, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
      currency: "INR",
      name: "GrabbnGo",
      image:
        "https://res.cloudinary.com/grabbngo/image/upload/v1543493769/Logos/highlighted-logo.png",
      order_id: orderId,
      prefill: {
        email: `${
          userprofile && userprofile.user.email ? userprofile.user.email : ""
        }`,
        contact: `${userprofile && userprofile.user.phone}`,
      },
      handler: function () {
        // const razorpay_payment_id = response.razorpay_payment_id;
        that.checkPaymnetStatus();
        // const razorpay_signature = response.razorpay_signature
      },
      modal: {
        ondismiss: function () {
          that.checkPaymnetStatus();
        },
      },
      theme: {
        color: "#F37254",
      },
    };
    this.razorpay = new window.Razorpay(options);
  };

  toggleInstruction = (id) => {
    this.setState((prevState) => ({
      specialInstructions: {
        ...this.state.specialInstructions,
        [id]: {
          isOpen: prevState.specialInstructions[id]
            ? !prevState.specialInstructions[id].isOpen
            : true,
          text: prevState.specialInstructions[id]
            ? prevState.specialInstructions[id].text
            : "",
        },
      },
    }));
  };

  updateSpecialInstruction = (e, id) => {
    const text = e.target.value;
    console.log("Text for the special instruction", text);
    this.setState((prevState) => ({
      specialInstructions: {
        ...this.state.specialInstructions,
        [id]: {
          text: text,
          isOpen: prevState.specialInstructions[id].isOpen,
        },
      },
    }));
  };

  onSubmitSpecialInstruction = (id, text, ppid) => {
    this.toggleInstruction(id);
    let localSpIns = stateManager.specialInstructions || {};
    localSpIns[ppid] = { id: id, text: text };
    stateManager.specialInstructions = localSpIns;
  };

  handleSeatUpdate = (s) => {
    this.setState(
      {
        deliverySeatNumber: s,
        isModeDelivery: false,
        showPayButtons: true,
      },
      () => {
        this.getCheckOut();
      }
    );
  };

  updateLater = () => {
    this.setState({
      isModeDelivery: false,
      showPayButtons: true,
    });
  };

  onExploreclick = () => {
    this.props.history.replace("/store");
    this.props.closeAuthModal();
    this.props.onExploreClick && this.props.onExploreClick();
  };

  onLoginclick = () => {
    this.props.onLoginClick();
  };

  render() {
    const {
      isLoading,
      hasError,
      checkoutFromServer,
      isModeDelivery,
      isAuthError,
      paymentMode,
      shouldShowSeatModal,
      isPaymentLoading,
      region,
    } = this.state;
    const localCheckoutData = JSON.parse(
      localStorage.getItem("localCheckoutFromServer")
    );
    console.log("local checkout", localCheckoutData, checkoutFromServer);
    const { orderMode, deliveryLocation } = this.props;
    return (
      <div className={classes.checkout_modal_open}>
        <Prompt
          when={isPaymentLoading}
          message={() =>
            `Your payment is in progress. Leaving this page while payment is in process might cause payment failure. Are you sure you want to leave?`
          }
        />
        {isPaymentLoading && (
          <div className={classes.background_cover_checkout}>
            <Spinner name="folding-cube" color={"#e63e25"} />
            <p>Loading...</p>
          </div>
        )}
        {isAuthError && (
          <div className={classes.loadingDiv}>
            Please login to continue.
            <button onClick={this.onLoginclick}>Login</button>
          </div>
        )}
        {!isLoading &&
          checkoutFromServer &&
          (!checkoutFromServer.carts ||
            !checkoutFromServer.carts.edges.length > 0) && (
            <div className={classes.loadingDiv}>
              Seems like you have not made any choice yet.
              <button onClick={this.onExploreclick}>Explore</button>
            </div>
          )}
        {!isLoading &&
          !hasError &&
          checkoutFromServer &&
          checkoutFromServer &&
          checkoutFromServer.carts &&
          checkoutFromServer.carts.edges.length > 0 && (
            <>
              <div className={classes.checkout_top_div}>
                <div className={classes.logo_div}>
                  {!region.appHideGngLogos && <GNGLogo height={60} />}
                  {region.appBrandingLogo && (
                    <img
                      alt=""
                      src={region.appBrandingLogo}
                      className={classes.brand_logo}
                    />
                  )}
                </div>
                <div className={classes.cart_header}>
                  {
                    <div className={classes.delivery_location_div}>
                      <p className={classes.top_div_title}>
                        {CUSTOMER_ORDER_MODE[orderMode]}
                      </p>
                      {/* commented for liquor demo */}
                      {(orderMode === ORDER_MODES.tableService ||
                        orderMode === ORDER_MODES.seatService ||
                        orderMode === ORDER_MODES.delivery) && (
                        <p className={classes.top_div_value}>
                          {`${
                            orderMode === ORDER_MODES.seatService
                              ? "Seat"
                              : orderMode === ORDER_MODES.tableService
                              ? "Table "
                              : ""
                          } ${deliveryLocation}`}
                        </p>
                      )}
                    </div>
                  }
                </div>
              </div>
              <div className={classes.checkout_detail_div}>
                {checkoutFromServer.carts.edges.map((it) => (
                  <div key={it.node.id} className={classes.order_detail_div}>
                    <div className={classes.store_name_div}>
                      <p className={classes.store_name}>
                        {it.node.pickupPoint.store.name}
                      </p>
                      {/* <div className={classes.sla_div}>
                        <Clock
                          className={classes.clock_icon}
                          capHeight={8}
                          color={"#000"}
                          height={8}
                          width={8}
                        />
                        <p
                          style={{
                            color: "#000",
                          }}
                        >
                          {Math.floor(it.node.cartSla / 60)} Min
                        </p>
                      </div> */}
                    </div>
                    {it.node.cartItems.edges.map((pl) => (
                      <CartItem
                        key={pl.node.id}
                        canChangeQuantity={!localCheckoutData}
                        onAddItem={this.onAddItem}
                        onRemoveItem={this.onRemoveItem}
                        item={pl.node}
                        pickupPoint={it.node.pickupPoint}
                      />
                    ))}
                    {
                      <div className={classes.instruction_div}>
                        <button
                          className={classes.instructionBtn}
                          onClick={() => this.toggleInstruction(it.node.id)}
                        >
                          <span>Any allergy or special request?</span>
                        </button>
                        {this.state.specialInstructions[it.node.id] &&
                          this.state.specialInstructions[it.node.id].isOpen && (
                            <div>
                              <textarea
                                className={classes.instructionTextarea}
                                value={
                                  this.state.specialInstructions[it.node.id]
                                    .text
                                }
                                onChange={(e) =>
                                  this.updateSpecialInstruction(e, it.node.id)
                                }
                              />
                              <div className={classes.instructionBtnGrp}>
                                <button
                                  onClick={() =>
                                    this.onSubmitSpecialInstruction(
                                      it.node.id,
                                      this.state.specialInstructions[it.node.id]
                                        .text,
                                      it.node.pickupPoint.id
                                    )
                                  }
                                  className={classes.submit}
                                >
                                  ok
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    }
                  </div>
                ))}
                {!localCheckoutData && (
                  <Recommendation
                    onAdd={this.getCheckOut}
                    checkout={checkoutFromServer}
                    orderMode={orderMode}
                  />
                )}
                <div>
                <ApplyCode
                    codeText='' //{checkoutFromServer.codeText}
                    code={this.state.code}
                    onApplyCouponCode =  {this.onApplyCouponCode} // {this.setState({setCouponCode})}
                    //{setcode}
                />
                </div>
                <div className={classes.extra_content_div}>
                  <div className={classes.cart_total_div}>
                    {Object.entries(
                      calculateCheckoutExtra(checkoutFromServer)
                    ).map(([key, value], index) => (
                      <div className={classes.cart_total_item} key={index}>
                        <p className={classes.cart_total_item_title}>{key}</p>
                        <p className={classes.cart_total_item_value}>
                          <span>{RUPEE_SYMBOL}</span>
                          {value.toFixed(2)}
                        </p>
                      </div>
                    ))}
                    <div className={classes.cart_total_item}>
                      <p
                        className={classes.cart_total_item_title}
                        style={{ fontWeight: "bold" }}
                      >
                        Total
                      </p>
                      <p
                        className={classes.cart_total_item_value}
                        style={{ fontWeight: "bold" }}
                      >
                        <span>{RUPEE_SYMBOL}</span>
                        {checkoutFromServer.total >= 0
                          ? checkoutFromServer.total.toFixed(2)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.fassaidiv}>
                  <img alt="" src={fassaiLogo} />
                  <p>10019043002878</p>
                </div>
                {/* Adding the delivery slot button if at the store level if we have delivery slot */}
              </div>
              {shouldShowOnlinePayment(
                region,
                localCheckoutData,
                orderMode
              ) && (
                <div className={classes.pay_button_div}>
                  <button
                    onClick={() => this.onPaymentInit(PAYMENT_MODES.razorpay)}
                    className={classes.pay_button}
                  >
                   {checkoutFromServer.total > 1 ? "Proceed to Pay" : "Place Order"}
                  </button>
                </div>
              )}
              {shouldShowPayLater(region, localCheckoutData, orderMode) && (
                <div className={classes.pay_button_div}>
                  <button
                    onClick={() => this.onPaymentInit(PAYMENT_MODES.paylater)}
                    className={classes.pay_button}
                  >
                    Place Order
                  </button>
                </div>
              )}
              {shouldShowPayAtCounter(region, localCheckoutData, orderMode) && (
                <div className={classes.pay_button_div}>
                  <button
                    onClick={() =>
                      this.onPaymentInit(PAYMENT_MODES.payAtCounter)
                    }
                    className={classes.pay_button}
                  >
                    Pay At Counter
                  </button>
                </div>
              )}
            </>
          )}
        {isLoading && (
          <div
            onClick={(e) => e.stopPropagation()}
            className={classes.layoverloading}
          >
            <Spinner name="folding-cube" color={"#e63e25"} />
            <p>
              {paymentMode === PAYMENT_MODES.payAtCounter
                ? "Please contact store to complete your payment."
                : "Processing..."}
            </p>
          </div>
        )}
        {shouldShowSeatModal && isModeDelivery && (
          <SeatInfoModal
            isOpen={true}
            onNext={(s) => this.handleSeatUpdate(s)}
            onUpdateLater={() => this.updateLater()}
          />
        )}
      </div>
    );
  }
}

export default withRouter(CheckoutModal);
