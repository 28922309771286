import React, { useState } from "react";
import classes from "./UPI.module.css";

const UPI = ({ razorpay, onSubmit }) => {
  const [vpa, setVpa] = useState("");
  const [hasError, setHasError] = useState("");

  const verifyVpa = () => {
    razorpay
      .verifyVpa(vpa)
      .then(res => {
        console.log("Response for the VPA", res);
        const isValid = res.success;
        setVpa(res.vpa);
        setHasError(!isValid);
        if (isValid) {
          onSubmit && onSubmit({ method: "upi", vpa: vpa });
        }
      })
      .catch(err => {
        console.log("error in vpa", err);
        setHasError(true);
      });
  };
  return (
    <div className={classes.maindiv}>
      <p className={classes.heading}>UPI</p>
      <input
        placeholder="enter your UPI address"
        value={vpa}
        onChange={e => setVpa(e.target.value)}
        className={classes.upi_input}
      />
      {hasError && (
        <p className={classes.error_message}>
          *Please enter a valid UPI address.
        </p>
      )}
      <button className={classes.verify_button} onClick={verifyVpa}>
        VERIFY AND PAY
      </button>
    </div>
  );
};

export default UPI;
