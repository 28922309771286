import React, { Component, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import classes from "./TrendingScreen.module.css";
import Trending from "./Trending";
import StoreScreen from "../StoreScreen/StoreScreen";
import { GET_STORES_FOR_REGION } from "../../apollo/queries";
import client from "../../apollo/client";
import GlobalStore from "../../utilities/GlobalState";
import WaiterService from "../../components/WaiterService/WaiterService";
import { AppConfigContext } from "../../data/AppConfiguration";
import { ReactComponent as BuzzerIcon } from "../../assets/buzzer.svg";
import { ORDER_MODES } from "../../utilities/config";


const filterRegionServices = (region) => {
  let services = []
  if(region && region.services &&
    region.services.edges.length > 0){
      services = region.services.edges.filter((service) => service && service.node.status === 'ACTIVE')
    }
    else{
      services = []
    }
    return services
}


const _trendingData = (name, logo) => {
  return {
    pickuppoint: {
      id: "1563516vvhvhvvjbj1bj31",
      name: "",
      store: {
        id: "/store",
        name: name || "Trending",
        image: logo || require("../../assets/trending.svg"),
        isOpen: true,
      },
    },
  };
};

const StorePicker = ({ stores, orderMode }) => {
  const history = useHistory();
  const params = useLocation();
  const [activeStore, setActiveStore] = useState("");

  useEffect(() => {
    const storeId = params.pathname.replace("/store/", "");
    let item = document.getElementById(storeId);
    storeId.length > 2 ? setActiveStore(storeId) : setActiveStore("/store");
    item &&
      item.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    if (!item) {
      item = document.getElementById("/store/");
      item &&
        item.scrollIntoView(true, {
          behavior: "smooth",
          inline: "center",
        });
    }
  }, [params]);

  const onStoreClick = (store) => {
    const { id, name } = store;
    if (name === "Trending") {
      history.replace(`/store`);
    } else {
      history.replace(`/store/${id}`);
    }
  };

  return (
    <div className={classes.storepicker}>
      {stores.map((item, index) => (
        <div
          onClick={() => onStoreClick(item.pickuppoint.store)}
          className={
            activeStore === item.pickuppoint.store.id
              ? classes.active_storeItem
              : classes.storeItem
          }
          key={index}
          id={item.pickuppoint.store.id}
        >
          <img
            fit="contain"
            alt={item.pickuppoint.store.name}
            src={item.pickuppoint.store.image}
            className={classes.storeimage}
          />
          <p>{item.pickuppoint.store.name}</p>
          {orderMode !== ORDER_MODES.tableService && (
            <p>{item.pickuppoint.name}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export const Buzzer = (props) => {
  return (
    <button className={classes.buzzerBtn} onClick={props.onClick}>
      <BuzzerIcon height={30} width={30}></BuzzerIcon>
      <p style={{ fontSize: 8 }}>Services</p>
    </button>
  );
};

class TrendingScreen extends Component {
  mounted = false;
  static contextType = AppConfigContext;
  state = {
    region: null,
    stores: [],
    hasNoRegion: false,
    hasSetTrending: false,
    appConfiguration: null,
    callWaiter: false,
    isCallWaiter: false,
    regionServices: [],
  };
  componentDidMount() {
    this.mounted = true;
    const { appConfiguration, orderMode, region } = this.props;
    const regionServices = filterRegionServices(region)
    const { shouldShowTrending, trendingName, trendingLogo } = appConfiguration;
    (!region || !orderMode) &&
      this.mounted &&
      this.setState({
        hasNoRegion: true,
        regionServices: regionServices,
        isCallWaiter: regionServices && regionServices.length > 0,
      });
    this.setState({
      regionServices: regionServices,
      isCallWaiter: regionServices && regionServices.length > 0,
    });
    this.mounted &&
      region &&
      client
        .query({
          query: GET_STORES_FOR_REGION(orderMode),
          variables: { regionId: region.id },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          console.log("Came here for region data")
          let stores = res.data ? res.data.pickupPointsForRegion : [];
          // TO-DO: Functionality below can be made more efficient as this is iterating array twice, one for filter and other for sort
          stores = stores
            .filter((it) => it.pickuppoint.store.isOpen)
            .sort(
              (a, b) =>
                b.pickuppoint.store.displayOrder -
                a.pickuppoint.store.displayOrder
            );
          const midlen = Math.floor(stores.length / 2);
          const trendingData = _trendingData(trendingName, trendingLogo);
          shouldShowTrending &&
            stores.filter(
              (it) => it.pickuppoint.id === trendingData.pickuppoint.id
            ).length <= 0 &&
            stores.splice(midlen, 0, trendingData);
          this.mounted &&
            this.setState(
              {
                stores: stores,
                region: region,
                hasSetTrending: true,
              },
              () => {
                if (!shouldShowTrending) {
                  const storeid =
                    stores &&
                    stores.length > 0 &&
                    stores[0].pickuppoint.store.id
                      ? stores[0].pickuppoint.store.id
                      : "";
                  this.props.history.replace(`/store/${storeid}`);
                }
              }
            );
        })
        .catch((err) => {
          console.log("error in getting regions", err);
        });
  }

  callWaiter = () => {
    this.setState({
      callWaiter: !this.state.callWaiter,
    });
  };

  onClose = () => {
    this.setState({
      callWaiter: !this.state.callWaiter,
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      stores,
      hasNoRegion,
      callWaiter,
      isCallWaiter,
      regionServices,
    } = this.state;
    const { freeMealCount, setFreeMealCount, offerDetails } = this.context;
    return (
      <>
        {!hasNoRegion && (
          <GlobalStore.Consumer>
            {({ screenDimensions, region, ordermode, updateCart }) => (
              <>
                <div
                  style={{ height: screenDimensions.height - 106 }}
                  className={classes.maindiv}
                >
                  {isCallWaiter && ordermode === ORDER_MODES.tableService && (
                    <Buzzer onClick={this.callWaiter}></Buzzer>
                  )}
                  <div
                    style={{ height: screenDimensions.height - 186 }}
                    id="trendingscreens"
                    className={classes.layoutdiv}
                  >
                    <Switch>
                      <Route path="/store/:id" component={StoreScreen} />
                      <Route
                        path="/store"
                        render={(props) => (
                          <Trending
                            orderMode={ordermode}
                            region={region}
                            freeMealCount={freeMealCount}
                            setFreeMealCount={setFreeMealCount}
                            offerDetails={offerDetails}
                            {...props}
                          />
                        )}
                      />
                    </Switch>
                  </div>
                  {this.mounted && stores.length > 0 && this.mounted && (
                    <StorePicker orderMode={ordermode} stores={stores} />
                  )}
                </div>
                <div>
                  {callWaiter && (
                    <WaiterService
                      serviceList={regionServices}
                      open={true}
                      onClose={this.onClose}
                      updateCart={updateCart}
                    ></WaiterService>
                  )}
                </div>
              </>
            )}
          </GlobalStore.Consumer>
        )}
        {hasNoRegion && (
          <Redirect to="/region" from={this.props.location.pathname} />
        )}
      </>
    );
  }
}

export default TrendingScreen;
