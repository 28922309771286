import React, { Component } from "react";
import classes from "./AccountScreen.module.css";
import { GET_USER_PROFILE } from "../../apollo/queries";
import { UPDATE_USER_EMAIL } from "../../apollo/mutations";
import client from "../../apollo/client";
import StateManager from "../../utilities/StateManager";
import Spinner from "react-spinkit";
import Swal from "sweetalert2";

class AccountScreen extends Component {
  mounted = false;
  state = {
    isLoading: false,
    userProfile: null,
    isUpdatingUserProfile: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.getUserProfile();
      }
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getUserProfile = () => {
    client
      .query({
        query: GET_USER_PROFILE(),
        fetchPolicy: "cache-first",
      })
      .then((res) => {
        console.log("Response for the user profile", res.data.userProfile);
        this.mounted &&
          this.setState(
            {
              userProfile: res.data.userProfile,
              isLoading: false,
            },
            () => {
              const stateManager = new StateManager();
              stateManager.userProfile = res.data.userProfile;
            }
          );
      })
      .catch((err) => {
        console.log("Error in getting the user profile", err);
        this.mounted &&
          this.setState({
            isLoading: false,
          });
      });
  };

  handleOnChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    if (id === "email") {
      const user = this.state.userProfile.user;
      user.email = value;
      this.setState({
        userProfile: {
          user: user,
        },
      });
      return true;
    } else if (id === "firstName" || id === "lastName") {
      const userProfile = this.state.userProfile;
      userProfile[id] = value;
      this.setState({
        userProfile: userProfile,
      });
      return true;
    }

    return false;
  };

  handleOnSave = () => {
    this.mounted &&
      this.setState(
        {
          isUpdatingUserProfile: true,
        },
        () => {
          const userProfile = this.state.userProfile;
          const input = {};
          input.firstName = userProfile.firstName;
          input.lastName = userProfile.lastName;
          input.email = userProfile.user.email;
          input.deviceId = "";
          input.deviceToken = "";
          input.device = "mobile";
          client
            .mutate({
              mutation: UPDATE_USER_EMAIL(),
              variables: { input: input },
            })
            .then((res) => {
              console.log("response for user profile");
              const stateManager = new StateManager();
              stateManager.userProfile = res.data.updateUserProfile.userProfile;
              this.mounted &&
                this.setState(
                  {
                    isUpdatingUserProfile: false,
                  },
                  () => {
                    Swal.fire({
                      title: "Success",
                      text: "Your changes have saved successfully.",
                      customClass: {
                        confirmButton: classes.swal_close_button,
                      },
                    });
                  }
                );
            })
            .catch((err) => {
              console.log("Error in updating the user profile", err);
              this.mounted &&
                this.setState({
                  isUpdatingUserProfile: false,
                });
            });
        }
      );
  };

  render() {
    const { isLoading, userProfile, isUpdatingUserProfile } = this.state;
    return (
      <div className={classes.maindiv}>
        {!isLoading && userProfile && (
          <>
            {isUpdatingUserProfile && (
              <div className={classes.overlaydiv}>
                {" "}
                <Spinner
                  style={{ marginBottom: 16 }}
                  name="folding-cube"
                  color={"#e63e25"}
                />
                <p>Updating...</p>
              </div>
            )}
            <div className={classes.phonediv}>
              <label>{`Phone`}</label>
              <input
                className={classes.phoneinput}
                value={userProfile.user.phone}
                disabled={"disabled"}
              />
            </div>
            <div className={classes.inputdiv}>
              <div className={classes.labeldiv} label="Email">
                <label>Email</label>
                <input
                  type="email"
                  id="email"
                  className={classes.inputfield}
                  value={userProfile.user.email}
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className={classes.inputdiv}>
              <div className={classes.labeldiv} label="First Name">
                <label>First Name</label>
                <input
                  type="text"
                  id="firstName"
                  className={classes.inputfield}
                  value={userProfile.firstName}
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className={classes.inputdiv}>
              <div className={classes.labeldiv}>
                <label>Last Name</label>
                <input
                  input="text"
                  id="lastName"
                  className={classes.inputfield}
                  value={userProfile.lastName}
                  onChange={this.handleOnChange}
                />
              </div>
            </div>
            <div className={classes.buttondiv}>
              <button
                className={classes.savebutton}
                onClick={this.handleOnSave}
              >
                {" "}
                Save
              </button>
            </div>
          </>
        )}
        {isLoading && (
          <div className={classes.layoverloading}>
            <Spinner
              style={{ marginBottom: 16 }}
              name="folding-cube"
              color={"#e63e25"}
            />
            <p>Loading...</p>
          </div>
        )}
      </div>
    );
  }
}

export default AccountScreen;
