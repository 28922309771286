import React, { PureComponent } from "react";
import classes from "./Recommendation.module.css";
import client from "../../apollo/client";
import { GET_RECOMMENDATIONS_FOR_CHECKOUT } from "../../apollo/queries";
import GlobalStore from "../../utilities/GlobalState";
import TrendingCard from "../TrendingCard/TrendingCard";

class Recommendation extends PureComponent {
  mounted = false;
  state = {
    isLoading: false,
    items: []
  };
  componentDidMount() {
    this.mounted = true;
    this.mounted &&
      this.setState(
        {
          isLoading: true
        },
        () => {
          setTimeout(() => {
            this.getRecommendations();
          }, 2000);
        }
      );
  }

  componentDidUpdate(prevProps) {
    if (this.props.checkout !== prevProps.checkout) {
      this.getRecommendations();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getRecommendations = () => {
    const { orderMode } = this.props;
    client
      .query({
        query: GET_RECOMMENDATIONS_FOR_CHECKOUT(),
        fetchPolicy: "no-cache"
      })
      .then(res => {
        console.log(
          "Response for the recommendations",
          res.data.checkoutRecommendation
        );

        let recomms = [];
        try {
          recomms = res.data.checkoutRecommendation;
          recomms = recomms.filter(item => {
            if (orderMode !== "TABLE_SERVICE") {
              return orderMode && item.stock[orderMode.toLowerCase()];
            } else {
              return item.stock["dinein"];
            }
          });
        } catch (error) {
          console.log("error in recommendations", error);
        }
        this.mounted &&
          this.setState({
            items: recomms,
            isLoading: false
          });
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  };
  render() {
    const { items, isLoading } = this.state;
    return (
      <GlobalStore.Consumer>
        {({
          screenDimensions,
          setCustomizationData,
          cart,
          updateCart,
          isSmallScreenDevice
        }) => (
          <div
            style={{
              visibility: !isLoading && items.length >= 1 ? "visible" : "hidden"
            }}
            className={classes.main_div}
          >
            <div className={classes.title_div}>
              <p>You may also like</p>
            </div>
            <div className={classes.content_div}>
              {items.map(item => (
                <div key={item.id}>
                  <TrendingCard
                    type={"short"}
                    item={{ node: item.stock }}
                    cart={cart}
                    updateCart={updateCart}
                    setCustomizationData={setCustomizationData}
                    availHeight={screenDimensions.height - 200}
                    isSmallScreenDevice={isSmallScreenDevice}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default Recommendation;
