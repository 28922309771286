import StateManager from "./StateManager";
import { ORDER_MODES } from "./config";
// import base64 from "base-64";
import crypto from 'crypto';
/**
 * @param {Array} arr - an array of items
 * @return {Array} a 2D array with each element of the array containing 2 items
 */
export const makeCouples = (arr = []) => {
  let newArr = [];
  for (let i = 0; i < arr.length; i = i + 2) {
    let first = arr[i];
    let second = arr[i + 1] ? arr[i + 1] : undefined;
    newArr.push([first, second]);
  }
  return newArr;
};

/**
 * @return {Boolean} If the auth token is in local storage pr not
 */
export const verifyAuthLocal = () => {
  const stateManager = new StateManager();
  const token = stateManager.authToken;
  console.log("verifying the auth", token);
  if (token !== null && token) {
    return true;
  }
  return false;
};

export const handleProductData = (data, activeCat) => {
  let prodArr = {};
  let categories = {};
  data.forEach((element) => {
    let ele = element;
    if (
      prodArr[ele.product_id] &&
      prodArr[ele.product_id].stocks &&
      ele.in_stock
    ) {
      let item = prodArr[ele.product_id];
      let stocks = item.stocks;
      stocks.push({
        id: ele.stock_id,
        salePrice: ele.stock_sale_price,
        inStock: ele.in_stock,
        name: ele.sku_name,
        image: ele.sku_image,
        quantity: ele.quantity,
        skuLabel: ele.sku_label,
      });
      item.stocks = stocks;
      prodArr[ele.product_id] = item;
    } else if (ele.in_stock) {
      let item = {
        name: ele.product_name,
        image: ele.product_image,
        id: ele.product_id,
        stocks: [],
        store: ele.store_id,
        displayOrder: ele.product_display_order,
        description: ele.product_description,
        contains: ele.contains,
        category: ele.category_id,
        stockSla: ele.stock_sla,
        categoryName: ele.category_name,
        categoryDisplayOrder: ele.category_display_order,
        isCustomizable: ele.is_customizable,
        skuCardColor: ele.sku_card_color,
        skuFontColor: ele.sku_font_color,
        skuMask: ele.sku_mask,
        storeName: ele.store_name,
        storeImage: ele.store_image,
        pickuppoint: ele.pickuppoint_id,
      };
      if (!categories[ele.category_id]) {
        categories[ele.category_id] = {
          name: ele.category_name,
          id: ele.category_id,
          displayOrder: ele.category_display_order,
          image: ele.category_image,
        };
      }
      item.stocks.push({
        id: ele.stock_id,
        salePrice: ele.stock_sale_price,
        inStock: ele.in_stock,
        name: ele.sku_name,
        image: ele.sku_image,
        quantity: ele.quantity,
        skuLabel: ele.sku_label,
      });
      prodArr[ele.product_id] = item;
    }
  });
  let prods = Object.values(prodArr);
  if (prods && prods.length > 0) {
    prods = prods.map((prod) => {
      prod.stocks.sort((a, b) => a.salePrice - b.salePrice);
      return prod;
    });
    // const activeCategory = sessionStorage.getItem(
    //   `${this.props.match.params.storeid}`
    // );
    prods.sort((a, b) => a.displayOrder - b.displayOrder);
    const categoriesList = Object.values(categories).sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    return {
      products: prods,
      categories: categoriesList,
      activeCat: activeCat || categoriesList[0].id,
    };
  } else {
    return { products: [], categories: [], activeCat: activeCat };
  }
};

/**
 * @returns a filtered list of products which belong to the category
 * @param {Array} products product list
 * @param {String} cat category id
 */
export const filterProductsForCategory = (products = [], cat) => {
  const filteredProducts = products.filter((it) => it.category === cat);
  return filteredProducts;
};

/**
 * @description Checks if the product has multiple SKUs available
 * @param {Object} product product
 * @return {Boolean} true if the product has multiple SKUs
 */
export const CheckForMultiSku = (product) => {
  if (product && product.stocks && product.stocks.length > 1) {
    return true;
  }
  return false;
};

export const generateSkuLabels = (product) => {
  let skusLabel = {};
  try {
    console.log("Products", product);
    product.stocks.forEach((item) => {
      const itemLabel = item.skuLabel || "Select Any";
      if (skusLabel[itemLabel]) {
        let items = skusLabel[itemLabel];
        items.push(item);
        skusLabel[itemLabel] = items;
        console.log("Items", skusLabel, items);
        //   items.push(item);
        //   skusLabel[itemLabel] = items;
      } else {
        skusLabel[itemLabel] = [item];
      }
    });
    return skusLabel;
  } catch (error) {
    return skusLabel;
  }
};

export const generateCustomizationConfig = (item) => {
  let custConf = {};
  const customizations = item.node.customizations.edges;
  customizations.forEach((cust) => {
    let custom = {};
    custom["name"] = cust.node.name;
    custom["maxSelectable"] = cust.node.maxSelectable;
    custom["minSelectable"] = cust.node.minSelectable;
    custom["id"] = cust.node.id;
    custom["totalCount"] = cust.node.children.edges.length;
    custom["showradio"] =
      custom["maxSelectable"] === 1 && custom["minSelectable"] === 1;
    custom["showCustom"] = !(
      cust["totalCount"] === custom["maxSelectable"] &&
      cust["totalCount"] === custom["minSelectable"]
    );
    custom["selected"] = cust.node.children.edges.filter(
      (it) => it.node.defaultSelected === 1
    );
    custom["children"] = cust.node.children.edges.map((it) => it.node);
    if (
      custom["maxSelectable"] === custom["minSelectable"] &&
      custom["maxSelectable"] === 1
    ) {
      custom["helptext"] = `Required: select 1 item`;
    } else if (
      custom["maxSelectable"] === custom["minSelectable"] &&
      custom["maxSelectable"] === 0
    ) {
      custom["helptext"] = `Optional: select any no of item(s)`;
    } else if (
      custom["maxSelectable"] > 0 &&
      custom["maxSelectable"] < custom["totalCount"] &&
      custom["maxSelectable"] !== custom["minSelectable"]
    ) {
      custom[
        "helptext"
      ] = `required: select ${custom["minSelectable"]} to ${custom["maxSelectable"]} item(s)`;
    } else if (
      custom["minSelectable"] === 0 &&
      custom["maxSelectable"] <= custom["totalCount"] &&
      custom["maxSelectable"] !== custom["minSelectable"]
    ) {
      custom[
        "helptext"
      ] = `Optional: select upto ${custom["maxSelectable"]} item(s)`;
    }
    custConf[cust.node.id] = custom;
  });
  return custConf;
};

/**
 *@description Calculate the checkout's extra data such as CGST,SGST, discounts and charges
 */
export const calculateCheckoutExtra = (checkout) => {
  let checkoutExtras = {};
  let extras = JSON.parse(checkout.extra);
  Object.entries(extras).forEach(([key, value]) => {
    value.forEach((item) => {
      checkoutExtras[item.label] = item.amount;
    });
  });
  extras = checkout.carts.edges;
  extras.forEach((cart) => {
    let cartExtra =
      cart && cart.node && cart.node.extra ? cart.node.extra : "{}";
    cartExtra = JSON.parse(cartExtra);
    Object.entries(cartExtra).forEach(([key, value]) => {
      value.forEach((val) => {
        let amount = checkoutExtras[val.label] || 0;
        checkoutExtras[val.label] = amount + val.amount;
      });
    });
  });
  return checkoutExtras;
};

export const parseCheckoutExtra = (data) => {
  let res = [];
  const extras = data ? JSON.parse(data.extra) : {};
  Object.entries(extras).forEach(([key, value]) => {
    res = res.concat(value);
  });
  return res;
};

export const classifyOrdersForCheckout = (data = []) => {
  console.log("active orders! ---> ", data);
  let checkouts = {};
  data.forEach((item) => {
    const order = item.node;
    const cart = order.cart;
    const checkout = cart.checkout;
    if (!checkouts[checkout.id]) {
      let extra = JSON.parse(order.cart.extra);
      let exData = {};
      Object.entries(extra).forEach(([key, value]) => {
        value.forEach((val) => {
          exData[val.label] = val.amount;
        });
      });
      checkouts[checkout.id] = {
        carts: [order],
        checkout: checkout,
        extraData: exData,
        status: { [order.status]: 1 },
      };
    } else {
      let items = checkouts[checkout.id].carts;
      let extras = checkouts[checkout.id].extraData;
      let extra = JSON.parse(order.cart.extra);
      let status = checkouts[checkout.id].status;
      Object.entries(extra).forEach(([key, value]) => {
        value.forEach((val) => {
          extras[val.label] = extras[val.label] || 0 + val.amount;
        });
      });
      items.push(order);
      status[order.status] = status[order.status]
        ? status[order.status] + 1
        : 1;
      checkouts[checkout.id].carts = items;
      checkouts[checkout.id].extraData = extras;
      checkouts[checkout.id].status = status;
    }
  });
  return checkouts;
};

export const isDelivery = (mode) => {
  return mode && mode.toLowerCase() === "delivery";
};

export const isTakeaway = (mode) => {
  return mode && mode.toLowerCase() === "takeaway";
};

export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

/**
 * Filter the bank list with the search value
 * @param arr - Array to be filtered
 * @param searchValue - value to filter against
 * @param callBack - call back to return value with
 */
export const filterBankResults = (
  arr = [],
  searchValue = "",
  callBack = () => {}
) => {
  let results = arr;
  if (searchValue.length > 0) {
    results = arr.filter((item) => {
      const [item1, item2] = item;
      if (
        item1.toLowerCase().includes(searchValue) ||
        item2.toLowerCase().includes(searchValue)
      ) {
        return true;
      }
      return false;
    });
  }
  callBack(results);
};

/**
 * @description Measures and logs the time the func took to complete
 * @param {Function} func
 * @callback cb - a call back function to execute after finishing the execution
 */
export const measureFunctionTime = (func = () => {}, cb = () => {}) => {
  console.time(func.name);
  func();
  console.timeEnd(func.name);
  cb();
};

/**
 *
 * @param {String} mode - order mode
 * @returns true if order mode is delivery
 */
export const isOrderModeDelivery = (mode) => {
  if (mode === ORDER_MODES.delivery) {
    return true;
  }
};

/**
 * @param {String} mode - order mode
 * @returns true if order mode is takeaway
 */
export const isOrderModeTakeaway = (mode) => {
  if (mode === ORDER_MODES.takeaway) {
    return true;
  }
};

/**
 * @param {String} mode - order mode
 * @returns true if order mode is table service
 */
export const isOrderModeTableService = (mode) => {
  if (mode === ORDER_MODES.tableService) {
    return true;
  }
};

/**
 * @param {String} mode - order mode
 * @returns true if order mode is dine in
 */
export const isOrderModeDinein = (mode) => {
  if (mode === ORDER_MODES.dinein) {
    return true;
  }
};

/**
 * @description Checks if any stock in the current checkout is out of stock
 * @param {Object} checkout - a checkout object fetched from the server
 * @return {Boolean} - true if any one item is out of stock
 */
export const isAnyItemOutOfStock = (checkout) => {
  const carts = checkout.carts.edges;
  carts.forEach((cart) => {
    const cartItems = cart.cartItems.edges;
    cartItems.forEach((item) => {
      if (!item || item.node || !item.node.stock || !item.node.stock.inStock) {
        return true;
      }
    });
  });
  return false;
};

/**
 * @description creates an input for the updateCheckout
 * @param {String} orderMode
 * @param {Object} carts
 * @param {String} regionId
 * @param {String} flight
 * @param {String} boardingGate
 * @param {String} seatNo
 * @param {String} tableNo
 * @param {Date} deliveryTime
 */
export const generateCheckoutInput = ({
  orderMode,
  carts,
  regionId,
  flight = "",
  boardingGate = "",
  seatNo = "",
  tableNo = "",
  deliveryLocation = "",
  deliveryTime = null,
  code = "",
}) => {
  let input = {};
  if (orderMode === ORDER_MODES.dinein || orderMode === ORDER_MODES.takeaway) {
    input = {
      orderMode: orderMode,
      carts: carts,
      region: regionId,
      deliveryTime: deliveryTime,
      code: code,
    };
  } else if (orderMode === ORDER_MODES.delivery) {
    input = {
      orderMode: orderMode,
      carts: carts,
      region: regionId,
      flight: flight,
      boardingGate: boardingGate,
      seatNo: seatNo,
      deliveryLocation: deliveryLocation,
      deliveryTime: deliveryTime,
      code: code,
    };
  } else if (orderMode === ORDER_MODES.tableService) {
    input = {
      orderMode: orderMode,
      carts: carts,
      region: regionId,
      deliveryLocation: deliveryLocation,
      deliveryTime: deliveryTime,
      code: code,
    };
  } else if (orderMode === ORDER_MODES.seatService) {
    input = {
      orderMode: "SEAT SERVICE",
      carts: carts,
      region: regionId,
      deliveryLocation: deliveryLocation,
      deliveryTime: deliveryTime,
      code: code,
    };
  }
  return input;
};

/**
 * @param {Object} region
 * @returns {Boolean} is pay on delivery available for region
 */
export const isPayOnDeliveryAvailable = (region) => {
  return region.isPayOnDelivery;
};

/**
 * @param {Object} region without additional node
 * @returns {Boolean} is delivery at seats available for region
 */
export const isDeliveryAtSeatsAvailable = (region) => {
  return region.isDeliveryAtSticker;
};

/**
 * @returns {Object} input for the special instruction
 * @param {Object} specialInstruction speacial instruction object with key as cart id and value as the text
 */
export const createSpecialInstructionInput = (specialInstruction) => {
  const instructionForUpdate = Object.entries(
    specialInstruction
  ).map(([key, value]) => ({ id: key, specialInstructions: value.text }));
  return instructionForUpdate;
};

/**
 * @description validate if the table no is alpha num and of length greater than 1
 * @param {String} tableNo table no
 * @returns throw error if the table no is not valid else returns true
 */
export const validateTableNo = (tableNo = "") => {
  let item = tableNo.replace(/table no|tableno/gi, "");
  if (item.length < 1 || !item.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i)) {
    throw new Error("Please enter a valid table no.");
  }
  return true;
};

/**
 * @description validate if the seat no is alpha num and of length greater than 1
 * @param {String} seatNo seat no
 * @returns throw error if the seat no is not valid else returns true
 */
export const validateSeatNo = (seatNo = "") => {
  let item = seatNo.replace(/seat no|seatno/gi, "");
  if ((item.length < 1) | !item.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i)) {
    throw new Error("Please enter a valid seat no.");
  }
  return true;
};

/**
 * @return data from localstorage
 * @param {String} dataKey local storage data key
 */
export const getDataFromLocalStorage = (dataKey) => {
  try {
    return JSON.parse(localStorage.getItem(dataKey));
  } catch (error) {
    return null;
  }
};

/**
 * @return {Boolean} if the save was success or not
 * @param {String} dataKey key to save data with in local storage
 * @param {Object} data data to save in local storage
 */
export const saveDataToLocalStorage = (dataKey, data) => {
  try {
    localStorage.setItem(dataKey, JSON.stringify(data));
    return true;
  } catch (error) {
    return false;
  }
};

export const regionSearch = (item = {}, searchText = "") => {
  if (searchText.length > 0) {
    if (
      item.node &&
      ((item.node.city && item.node.city.toLowerCase().includes(searchText)) ||
        (item.node.name && item.node.name.toLowerCase().includes(searchText)))
    ) {
      return true;
    }
    return false;
  }
  return true;
};

/**
 *
 * @param {Array} data
 * @param {String} searchText
 */
export const categoriseRegionData = (data = [], searchText = "") => {
  let newSearchText = searchText.toLowerCase();
  let regionData = {};
  data.forEach((item) => {
    let cityName = item.node.city || "Others";
    if (item.node.app) {
      if (regionData.hasOwnProperty(cityName)) {
        let regData = regionData[cityName] || [];
        if (regionSearch(item, newSearchText) && item.node.app) {
          regData.push(item);
          regionData[cityName] = regData;
        }
      } else {
        let regData = [];
        if (regionSearch(item, newSearchText) && item.node.app) {
          regData.push(item);
          regionData[cityName] = regData;
        }
      }
    }
  });
  console.log("Region data", regionData);
  return regionData;
};

export const generateCartFromCheckout = (checkout) => {
  let carts = {};
  const cartCheckout = checkout.carts.edges;
  cartCheckout.forEach((cart) => {
    console.log("Checkot", cart);
    const pickupPoint = cart.node.pickupPoint;
    const cartItems = cart.node.cartItems.edges.map((cartItem) => {
      const customization = cartItem.node.customizations.edges.map((cust) => {
        return cust.node.id;
      });
      const item = {
        stockId: cartItem.node.id,
        quantity: cartItem.node.quantity,
        customization: customization,
      };
      return item;
    });
    carts[pickupPoint.id] = { pickupPoint: pickupPoint, cartItems: cartItems };
  });
  return carts;
};
/**
 * Calculate MD5 hash for the given data.
 * @param {object} data - The data to be hashed.
 * @returns {string} - The MD5 hash.
 */
export const calculateMD5 = (data) => {
  const base64Data = Buffer.from(JSON.stringify(data), 'utf-8').toString('base64');
  const hash = crypto.createHash('md5');
  hash.update(base64Data);
  return hash.digest('hex');
};

export const generateEpochTimestamp = () => {
  var currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp;
};

export  const generateSignature = (timestamp, token) => {
  var sampleString = String(timestamp);
  var sampleStringBytes = new TextEncoder().encode(sampleString);
  var base64Bytes = btoa(String.fromCharCode.apply(null, sampleStringBytes));
  var tokenNew = token + "-" + base64Bytes;

  var sampleTokenString = String(tokenNew);
  var sampleTokenStringBytes = new TextEncoder().encode(sampleTokenString);
  var base64TokenBytes = btoa(String.fromCharCode.apply(null, sampleTokenStringBytes));
  return base64TokenBytes;
};

export const generateHeaders = () =>{
  let storeInformation = JSON.parse(localStorage.getItem("storeInformation"));
  let authToken = storeInformation.token;
  
  
  // const checksum = calculateMD5(payload);
  // console.log("MD5 Checksum:", checksum);

  const authtimestamp = generateEpochTimestamp();
  console.log("Epoch Timestamp:", authtimestamp);

  const authsignature = generateSignature(authtimestamp,authToken);
  console.log("Signature:", authsignature);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Token " + authToken);
  myHeaders.append("Content-Type",  'application/json');
  // myHeaders.append("Checksum", checksum);
  myHeaders.append("Timestamp",authtimestamp);
  myHeaders.append("Signature",authsignature);
  console.log("HEADER",myHeaders);
  return myHeaders;
};

export const generateHeaderswithBasicAuthToken = (ntype, token) =>{
  // let storeInformation = JSON.parse(localStorage.getItem("storeInformation"));
  let authToken = token;
  
  
  // const checksum = calculateMD5(payload);
  // console.log("MD5 Checksum:", checksum);

  const authtimestamp = generateEpochTimestamp();
  console.log("Epoch Timestamp:", authtimestamp);

  const authsignature = generateSignature(authtimestamp,authToken);
  console.log("Signature:", authsignature);
  var myHeaders = new Headers();
  if (ntype == 'Basic'){
      myHeaders.append("Authorization", "Basic " + authToken);
      }
  if (ntype == 'Token'){
      myHeaders.append("Authorization", "Token " + authToken);
      }
  myHeaders.append("Content-Type",  'application/json');
  // myHeaders.append("Checksum", checksum);
  myHeaders.append("Timestamp",authtimestamp);
  myHeaders.append("Signature",authsignature);
  console.log("HEADER",myHeaders);
  return myHeaders;
};