import React, { useState, useEffect } from "react";
import classes from "./Cards.module.css";
import { ReactComponent as MasterCard } from "../../assets/mastercard.svg";
import { ReactComponent as Visa } from "../../assets/visa.svg";
import { ReactComponent as Amex } from "../../assets/amex.svg";
import { ReactComponent as Diners } from "../../assets/diners.svg";
import { Card } from "./definitions";

const Cards = ({ onSubmit = () => {} }) => {
  //state-values
  const [cardType, setCardType] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);
  const [isExpValid, setIsExpValid] = useState(false);

  // get Element by id function
  const getEl = elementId => {
    const item = document.getElementById(elementId);
    console.log("item", item);
    return item;
  };
  //razorpay card formatter

  useEffect(() => {
    const formatter = window.Razorpay.setFormatter(getEl("cardform"));
    const cvvField = getEl("cvv");
    formatter
      .add("card", getEl("card_number"))
      .on("network", data => {
        console.log("data for card_type", data);
        const cvvLen = data.type === "amex" ? 4 : 3;
        cvvField.maxLength = cvvLen;
        cvvField.pattern = "^[0-9]{" + cvvLen + "}$";
        setIsCardValid(data.valid);
      })
      .on("change", aa => {
        const cardType = aa.type;
        setCardType(cardType);
        const cvvLen = cardType === "amex" ? 4 : 3;
        cvvField.maxLength = cvvLen;
        cvvField.pattern = "^[0-9]{" + cvvLen + "}$";
        setIsCardValid(aa.valid);
      });
    formatter.add("expiry", getEl("expiry_date")).on("change", ee => {
      setIsExpValid(ee.valid);
    });
    return () => {
      formatter.off();
    };
  }, []);

  const onCardSubmit = e => {
    e.preventDefault();
    // formatter.off();
    const cardName = getEl("name").value;
    const cardNum = getEl("card_number").value.replace(/\s/g, "");
    const expiryDate = getEl("expiry_date")
      .value.replace(/\s/g, "")
      .split("/");
    const cvv = getEl("cvv").value;
    const card = new Card(cardNum, cardName, expiryDate[0], expiryDate[1], cvv);
    getEl("cardform").reset();
    onSubmit({ method: "card", card });
  };

  return (
    <div className={classes.maindiv}>
      <p className={classes.heading}>Debit/Credit Card</p>
      <form name="card_details" onSubmit={onCardSubmit} id="cardform">
        <div className={classes.name_div}>
          <input
            id="name"
            className={classes.card_name}
            placeholder={"Card holder's name"}
          />
        </div>
        <div className={classes.number_div}>
          <input
            id="card_number"
            className={classes.card_num_input}
            placeholder={"XXXX XXXX XXXX XXXX"}
          />
          <div className={classes.card_icon}>
            {cardType === "mastercard" && <MasterCard height={32} width={32} />}
            {cardType === "visa" && <Visa height={32} width={32} />}
            {cardType === "amex" && <Amex height={32} width={32} />}
            {cardType === "diners" && <Diners height={32} width={32} />}
          </div>
        </div>
        {!isCardValid && (
          <p className={classes.error_msg}>*Please enter a valid card number</p>
        )}
        <div className={classes.cvv_div}>
          <input
            id="expiry_date"
            className={classes.expiry_date}
            placeholder={"03/20"}
          />
          <input id="cvv" className={classes.cvv_input} placeholder={"CVV"} />
        </div>
        {!isExpValid && (
          <p className={classes.error_msg}>*Please enter a valid expiry date</p>
        )}
        <button className={classes.add_card_btn} type="submit">
          ADD CARD
        </button>
      </form>
    </div>
  );
};

export default Cards;
