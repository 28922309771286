import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as VegIcon } from "../../assets/veg.svg";
import { ReactComponent as NonvegIcon } from "../../assets/nonveg.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as CartIcon } from "../../assets/shopping-cart.svg";
import { Image } from "cloudinary-react";
import classes from "./ProductCard.module.css";
import { boxtheme, generateRandomShape } from "../../styles/theme";
import { Transformation } from "cloudinary-core";
import CartManager from "../../utilities/CartManager";
import {
  CheckForMultiSku,
  generateCustomizationConfig,
} from "../../utilities/helpers";
import { GET_CUSTOMIZATIONS_FOR_STOCKS } from "../../apollo/queries";
import client from "../../apollo/client";
import { RUPEE_SYMBOL } from "../../utilities/config";
import Swal from "sweetalert2";

const complimentaryImage = require("../../assets/complimentary.jpeg");

const shouldShowComplimentary = (offerDetails, stocks = []) => {
  let isavailable = false;
  if (
    offerDetails &&
    offerDetails.offerStocks &&
    offerDetails.offerStocks.length > 0
  ) {
    stocks.forEach((stock) => {
      isavailable = offerDetails.offerStocks.includes(stock.id);
    });
  }
  return isavailable;
};

const data = {
  product: "UHJvZHVjdDoxMDlmMTE2Ni03ZGE5LTRkZWMtOGRmYS1iNjMwNzM4ZDNlYjg=",
  name: "Peppy Tomato Masala Oats 22",
  productImage:
    "https://res.cloudinary.com/grabbngo/image/upload/v1568124896/AIRPORTIMAGES/mix%20images%202/PEPPY_TOMATO_MASALA_OATS.jpg",
  productDescription: "",
  stock: "U3RvY2s6M2FjM2E2NDEtYjA4Yy00MGM4LWE2ZTMtNDMwOTU1YTZlMjZi",
  stockSalePrice: 142.86,
  isCustomizable: false,
  description: undefined,
  displayOrder: undefined,
  categoryDisplayOrder: 0,
  categoryName: "Pastas & Sandwiches",
  contains: "NONVEG",
  category: "Q2F0ZWdvcnk6MGU0Y2Q0MWItZjc4Yi00YzRhLWJiMzgtODNjY2QwOTY3ODFj",
  stockSla: 900,
  stocks: [
    {
      id: "U3RvY2s6NDA2NWJhOWUtNzI3OS00YjhlLWE2YjktMjE3NjQ1MGMxYzEx",
      image:
        "https://res.cloudinary.com/grabbngo/image/upload/v1568793012/AIRPORTIMAGES/IRISH%20HOUSE/OLD_SCHOOL_MAC_N_CHEESE_GRILLED_SAUSAGES.jpg",
      inStock: true,
      quantity: null,
      salePrice: 476,
    },
  ],
};

class ProductCard extends React.PureComponent {
  mounted = false;
  patternShape = generateRandomShape();
  state = {
    customizations: {},
    isLoadingCustomization: false,
    itemsInCart: 0,
    showModal: false,
    stocksIds: [],
    itemAdded: false,
    showDescription: false,
    itemAddedAnimation: false,
  };

  componentDidMount() {
    this.mounted = true;
    const { item } = this.props;
    console.log("Item", item);
    const cartManager = new CartManager();
    const store = { id: item.pickuppoint, name: item.storeName };
    const stocksForProduct =
      item && item.stocks ? item.stocks.map((stock) => stock.id) : [];
    let sum = 0;
    cartManager
      .getAdddedStocksForProduct(stocksForProduct, store.id)
      .forEach((item) => {
        sum = sum + item.quantity;
      });
    this.setState(
      {
        itemsInCart: sum,
      },
      () => {
        if (item.isCustomizable) {
          this.setState(
            {
              isLoadingCustomization: true,
            },
            () => {
              const stocksIds = item.stocks.map((stk) => stk.id) || [];
              this.setState({
                stocksIds: stocksIds,
              });
              this.getCustomizationForProduct(stocksIds);
            }
          );
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cart !== this.props.cart) {
      const { item } = this.props;
      const store = { id: item.pickuppoint, name: item.storeName };
      const cartManager = new CartManager();
      const stocksForProduct = item.stocks.map((stock) => stock.id);
      let sum = 0;
      cartManager
        .getAdddedStocksForProduct(stocksForProduct, store.id)
        .forEach((item) => {
          sum = sum + item.quantity;
        });
      this.mounted &&
        this.setState({
          itemsInCart: sum,
        });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCustomizationForProduct = (stocksIds) => {
    client
      .query({
        query: GET_CUSTOMIZATIONS_FOR_STOCKS(),
        variables: { forStocks: stocksIds },
        fetchPolicy: "cache-first",
      })
      .then((res) => {
        let customStocks = {};
        res.data.stocks.edges.forEach((stock) => {
          console.log(
            "generate customization",
            generateCustomizationConfig(stock)
          );
          customStocks[stock.node.id] = generateCustomizationConfig(stock);
          // stock.node.customizations;
        });
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
            customizations: customStocks,
          });
      })
      .catch((err) => {
        console.log("Error in getting customization data", err);
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
          });
      });
  };

  addItemToCart = () => {
    const { item, freeMealCount, offerDetails, setFreeMealCount } = this.props;
    const pickupoint = { id: item.pickuppoint, name: item.storeName };
    const { customizations, isLoadingCustomization } = this.state;
    // const pickupoint = { id: store.id, name: store.store.name };
    const cartManager = new CartManager();
    if (item.isCustomizable || CheckForMultiSku(item)) {
      !isLoadingCustomization &&
        this.props.setCustomizationData(true, {
          product: item,
          pickupoint: pickupoint,
          customizations: customizations,
        });
    } else {
      const selectedStock = item.stocks[0];
      const stock = {
        id: selectedStock.id,
        name: item.name,
        price: selectedStock.salePrice,
      };
      if (
        offerDetails &&
        offerDetails.offerStocks &&
        offerDetails.offerStocks.includes(selectedStock.id) &&
        freeMealCount < 1
      ) {
        Swal.fire({
          text: "You have exhausted complimentary meal quota",
          title: "Error",
          icon: "error",
          confirmButtonColor: "#ff5500",
        });
      } else {
        if (
          offerDetails &&
          offerDetails.offerStocks &&
          offerDetails.offerStocks.includes(stock.id)
        ) {
          setFreeMealCount(freeMealCount - 1);
        }
        cartManager.addStockToCart(pickupoint, stock, 1);
      }
      this.props.updateCart();
      this.setState(
        {
          itemAdded: true,
          itemAddedAnimation: true,
        },
        () => {
          this.timeout = setTimeout(() => {
            this.setState({
              itemAddedAnimation: false,
            });
          }, 1000);
        }
      );
    }
  };

  addItemForQuantity = (sum, item) => {
    console.log("IOtem", item);
    return sum + item.quantity;
  };

  handleShowDescription = (e) => {
    // small description on the card itself
    // this.setState({
    //   showDescription: !this.state.showDescription
    // });
    // const { item } = this.props;
    // const pickupoint = { id: item.pickuppoint, name: item.storeName };
    // const { customizations, isLoadingCustomization } = this.state;
    this.props.setDescriptionData(
      true,
      {
        description: this.props,
        trending: false,
      },
      false
    );

    e.stopPropagation();
  };

  _addItemToCart = () => {
    // small description on the card itself
    // this.setState({
    //   showDescription: !this.state.showDescription
    // });
    // const { item } = this.props;
    // const pickupoint = { id: item.pickuppoint, name: item.storeName };
    // const { customizations, isLoadingCustomization } = this.state;
    const { item } = this.props;
    if (item && item.stocks) {
      this.props.setDescriptionData(true, {
        description: this.props,
        trending: false,
      });
    }
  };

  render() {
    const {
      item,
      type,
      cardType,
      availHeight,
      isSmallScreenDevice,
      offerDetails,
    } = this.props;
    const { itemsInCart, showDescription, itemAddedAnimation } = this.state;
    const { short, long } = boxtheme;
    const product = item;
    // const backgroundColor = item.skuCardColor || "#fff";
    const publicId =
      item && item.image
        ? item.image.replace(
            "https://res.cloudinary.com/grabbngo/image/upload/",
            ""
          )
        : "";
    const shortHeight = availHeight * 0.4 - 5;
    const longHeight = isSmallScreenDevice
      ? availHeight * 0.75
      : availHeight * 0.5 - 10;
    const widthImage = isSmallScreenDevice ? 200 : type === "long" ? 180 : 100;
    if (item && item.name !== "Peppy Tomato Masala Oats 22") {
      return (
        <div
          onClick={this._addItemToCart}
          style={
            type === "short"
              ? {
                  height: shortHeight,
                  width: short.width,
                  borderRadius: short.borderRadius,
                  background: item.skuCardColor || "#fff",
                }
              : {
                  height: longHeight,
                  width: long.width,
                  borderRadius: long.borderRadius,
                  background: item.skuCardColor || "#fff",
                }
          }
          className={classes.card}
        >
          {showDescription && (
            <div
              className={classes.description_view}
              style={{ backgroundColor: item.skuCardColor }}
              onClick={(e) => this.handleShowDescription(e)}
            >
              <div style={{ color: item.skuFontColor }}>{item.description}</div>
              <div className={classes.description_close}>
                <CrossIcon height={25} width={25} />
              </div>
            </div>
          )}
          {itemAddedAnimation && (
            <div
              className={classes.addItem}
              style={{
                backgroundColor: item.skuCardColor,
                color: item.skuFontColor,
              }}
            >
              Added to cart
              <div>
                <CartIcon
                  style={{ marginTop: "5%" }}
                  height={15}
                  width={15}
                  fill={item.skuFontColor === "#FFFFFF" ? "white" : "black"}
                />
              </div>
            </div>
          )}
          {cardType === "search" && (
            <div className={classes.store_image_div}>
              <img
                alt=""
                background={item.skuCardColor || "#fff"}
                src={item.storeImage}
                height={35}
                width={35}
              />
            </div>
          )}
          <img alt="" className={classes.svg_mask} src={this.patternShape} />
          <Image
            cloudName="grabbngo"
            responsive
            width={widthImage}
            quality={"auto:good"}
            crop={"scale"}
            fetchFormat={"auto"}
            dpr={2.0}
            publicId={publicId}
            alt={item.name}
            // radius={item.skuMask ? "max" : ""}
            background={"transparent"}
            style={{
              top: "10%",
              height: type === "long" ? "160px" : "140px",
              width: type === "long" ? "160px" : "140px",
              objectFit: "contain",
              filter: "drop-shadow(15px 15px 15px rgb(34, 34, 34, 0.8))",
            }}
            className={classes.imageCard}
          >
            <Transformation flags="progressive:steep,immutable_cache" />
          </Image>
          <div className={classes.item_contains_div}>
            {item.contains === "NONVEG" && (
              <NonvegIcon height={16} width={16} />
            )}
            {item.contains === "VEG" && <VegIcon height={16} width={16} />}
          </div>
          <div className={classes.overlay_div}>
            <button
              style={{
                color: item.skuFontColor || "#000",
              }}
              className={classes.cardButton}
            >
              +
              {itemsInCart > 0 && (
                <p
                  style={{
                    color: item.skuFontColor || "#000",
                    border: `1px solid ${item.skuFontColor || "#000"}`,
                  }}
                  className={classes.item_count_button}
                >
                  {itemsInCart}
                </p>
              )}
            </button>

            <div
              className={
                type === "short" ? classes.short_contentDiv : classes.contentDiv
              }
            >
              <p
                style={{
                  color: item.skuFontColor || "#000",
                }}
              >
                {product.name}
              </p>
              {/*shouldShowComplimentary(offerDetails, product.stocks) && (*/}
              
              {product && product.stocks && (
                <p
                  style={{
                    color: item.skuFontColor || "#000",
                  }}
                >
                  {(product.stocks[0].salePrice == 0 || product.stocks[0].salePrice == 0.00) && (  
                <img
                  alt="complimentary"
                  src={complimentaryImage}
                  style={{
                    height: 20,
                    width: 100,
                    objectFit: "contain",
                    marginBottom: 0,
                    marginLeft: 0,
                  }}
                />
              ) 
              }
              {(product.stocks[0].salePrice > 0) && (  
                  <>{RUPEE_SYMBOL} {product.stocks[0].salePrice}</>
              )}  
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

ProductCard.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(["short", "long"]),
};

ProductCard.defaultProps = {
  item: data,
  type: "short",
};

export default ProductCard;
