import React, { Component } from "react";
import classes from "./HomeScreen.module.css";
import client from "../../apollo/client";
import { GET_LAST_ORDERS } from "../../apollo/queries";
import { classifyOrdersForCheckout } from "../../utilities/helpers";
import moment from "moment";
import CheckoutCard from "../../components/CheckoutCard/CheckoutCard";
import SeatInfoModal from "../../components/SeatInfoModal/SeatInfoModal";
import StateManager from "../../utilities/StateManager";
import { ORDER_MODES, CUSTOMER_ORDER_MODE } from "../../utilities/config";
import { isEmpty } from "../../utilities/helpers";
import GlobalStore from "../../utilities/GlobalState";

// const AdImage = require("../../assets/ad_new.png");
// const ValImage = require("../../assets/appoffer.png");
// const Ad1Image = require("../../assets/appoffer_ah.png");

const getTextForDeliveryLocationType = orderMode => {
  let text = "Delivering at:";
  if (orderMode === ORDER_MODES.delivery) {
    return text;
  }
  if (orderMode === ORDER_MODES.tableService) {
    text = "Serving at:";
    return text;
  }
  if (orderMode === ORDER_MODES.seatService) {
    text = "Serving at:";
    return text;
  }
  return text;
};

class HomeScreen extends Component {
  mounted = false;
  state = {
    checkouts: {},
    timenow: String(moment().add(2, "hours")),
    isghost: false,
    userprofile: null,
    region: null,
    orderMode: "",
    deliveryLocation: ""
  };
  componentDidMount() {
    this.mounted = true;
    this.getActiveOrders();
    const stateManager = new StateManager();
    const userprofile = stateManager.userProfile;
    const region = this.props.region;
    const orderMode = this.props.orderMode;
    console.log("Region selected ---->", region, orderMode);
    let deliveryLocation = "";
    if (
      orderMode === ORDER_MODES.delivery ||
      orderMode === ORDER_MODES.tableService
    ) {
      if (orderMode === ORDER_MODES.tableService) {
        deliveryLocation = stateManager.tableNo;
      } else {
        deliveryLocation = stateManager.seatNo
          ? stateManager.seatNo
          : stateManager.deliveryLocation;
      }
    }
    this.mounted &&
      this.setState({
        userprofile: userprofile,
        isghost:
          userprofile && userprofile.user ? userprofile.user.isGhost : true,
        region: region,
        orderMode: orderMode,
        deliveryLocation: deliveryLocation
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log("location has changes", this.props.location);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.timer = null;
    clearTimeout(this.timer);
  }

  getActiveOrders = () => {
    const { isghost } = this.state;
    this.mounted &&
      !isghost &&
      client
        .query({
          query: GET_LAST_ORDERS(true),
          fetchPolicy: "network-only"
        })
        .then(res => {
          this.mounted &&
            this.setState(
              {
                checkouts: classifyOrdersForCheckout(res.data.orders.edges)
              },
              () => {
                this.timer = setTimeout(() => {
                  this.mounted && this.getActiveOrders();
                }, 40000);
              }
            );
        })
        .catch(err => {
          console.log("Error in fetching the orders", err);
        });
  };

  onOrderModeclick = () => {
    this.props.history.push("/boarding");
  };

  onRegionClick = () => {
    this.props.history.push("/region");
  };

  onStartOrderClick = () => {
    this.props.history.push("/store");
  };

  render() {
    const { checkouts, userprofile, isghost, region, orderMode } = this.state;
    console.log("Is ghost>>> ", region);
    return (
      <GlobalStore.Consumer>
        {({ appConfiguration, deliveryLocation, deliveryLocationType }) => (
          <div
            style={{ height: window.innerHeight - 112 }}
            className={classes.maindiv}
          >
            <div className={classes.setting_card}>
              {region && region && (
                <div className={classes.edit_mode_div}>
                  <p>{region.name}</p>
                  {appConfiguration.canChangeRegion && (
                    <button onClick={this.onRegionClick}>edit</button>
                  )}
                </div>
              )}
              {orderMode && (
                <div className={classes.edit_mode_div}>
                  <p> {orderMode && CUSTOMER_ORDER_MODE[orderMode]}</p>
                  {appConfiguration.canChangeOrderMode && (
                    <button onClick={this.onOrderModeclick}>edit</button>
                  )}
                </div>
              )}
              {orderMode &&
                (orderMode === ORDER_MODES.delivery ||
                  orderMode === ORDER_MODES.tableService) && (
                  <div className={classes.edit_mode_div}>
                    <p>
                      {getTextForDeliveryLocationType(
                        orderMode,
                        deliveryLocationType
                      )}{" "}
                      {`${
                        `${deliveryLocationType}` ? deliveryLocationType : ""
                      } ${deliveryLocation}`}
                    </p>
                    {/* {appConfiguration.canChangeDeliveryLocation && (
                      <button onClick={this.onOrderModeclick}>edit</button>
                    )} */}
                  </div>
                )}
              {!region && (
                <div className={classes.edit_mode_div}>
                  <button onClick={this.onRegionClick}>Select region</button>
                </div>
              )}
            </div>
            <div className={classes.orderBtnDiv}>
              <div
                className={classes.orderBtn}
                onClick={this.onStartOrderClick}
              >
                Order Now
              </div>
            </div>

            <div className={classes.last_orders_div}>
              {!isEmpty(checkouts) && !isghost && (
                <div className={classes.headerText}>Current Orders</div>
              )}

              {!isEmpty(checkouts) && !isghost && (
                <div className={classes.token}>
                  <div className={classes.tokenText}>TOKEN</div>
                  <div className={classes.tokenNumber}>
                    {userprofile &&
                      userprofile.user.phone.substring(
                        userprofile.user.phone.length - 4
                      )}
                  </div>
                </div>
              )}
              {checkouts &&
                Object.entries(checkouts).map(([key, value]) => {
                  return <CheckoutCard active={true} key={key} data={value} />;
                })}
            </div>

            <SeatInfoModal />
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default HomeScreen;
