import React from "react";
import classes from "./Loading.module.css";

const Loading = ({ open = false }) => {
  if (open) {
    return (
      <div className={classes.main_div}>
        <div className={classes.loader}></div>
        <div className={classes.text_div}>
          <p className={classes.loading_text}>Loading</p>
          <p className={classes.first_dot}>.</p>
          <p className={classes.second_dot}>.</p>
          <p className={classes.third_dot}>.</p>
        </div>
      </div>
    );
  }
  return null;
};

export default Loading;
