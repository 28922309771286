import React, { PureComponent } from "react";
import classes from "./PaymentScreen.module.css";
import Cards from "./Cards";
// import { CUSTOMER_PAYMENT_MODES } from "../../utilities/config";
import Banks from "./Banks";
import Wallets from "./Wallets";
import UPI from "./UPI";
import { PAYMENT_MODE_CONSTANTS } from "./config";
//eslint-disable-next-line
import { Card } from "./definitions";
import Loading from "../../components/Loading/Loading";

const RAZOR_RAZOR_KEY = "rzp_test_MIzY6bFhv39XgX";

const data = {
  amount: 22776,
  currency: "INR",
  email: "saurabh@grabngo.com",
  contact: "8112232159",
  notes: {
    address: "Ground Floor, SJR Cyber, Laskar Hosur Road, Bengaluru"
  },
  order_id: "order_EKWPZwCvJZdaqD",
  method: "wallet",
  wallet: "mobikwik"
};

class PaymentScreen extends PureComponent {
  state = {
    methods: null,
    hasGPay: false,
    isFailed: false,
    isLoading: false,
    isSuccess: false
  };

  componentDidMount() {
    this.configRazorpay();
  }

  configRazorpay = async () => {
    this.razorpay = await new window.Razorpay({
      key: RAZOR_RAZOR_KEY,
      order_id: "order_ELM5X2thPsiz7v",
      amount: "42551",
      currency: "INR",
      email: "saurabh@grabbngo.com",
      contact: "9602115166",
      customer_id: "cust_CzO3ZzAAmGoLJm",
      save: 1
    });
    this.checkForGPay();
    this.razorpay.once("ready", response => {
      console.log("response for the razorpay", response);
      this.setState({
        methods: (response && response.methods) || {}
      });
    });
  };

  checkForGPay = () => {
    this.razorpay
      .checkPaymentAdapter("gpay")
      .then(res => {
        console.log("res", res);
        this.setState({
          hasGPay: true
        });
      })
      .catch(err => {
        console.log("error", err);
        this.setState({
          hasGPay: false
        });
      });
  };

  onClickRazorpay = () => {
    console.log("razorpya", this.razorpay);
    this.razorpay.createPayment(data);
  };
  /**
   * @param {String} method : method of payment, one of PAYMENT_MODES
   * @param {Card} card : card object
   * @param {String} bank : bank code
   * @param {String} wallet : wallet code
   * @param {String} vpa: upi address
   * @param {String} order_id: order id razorpay's
   * @param {Number} amount : amount of the order
   * @param {String} email: email address of customer
   * @param {String} contact : contact number of the customer
   * @param {String} customer_id : razorpay customer ID
   * @param {String} token : customer's payment token
   * @param {boolean} gpay: has Gpay
   */
  onPaymentInit = ({
    method,
    card,
    bank,
    wallet,
    vpa,
    customer_id,
    token,
    gpay = false
  }) => {
    const { orderId, orderAmount, userEmail = null, phone } = this.props;
    const order_id = orderId;
    const amount = orderAmount;
    const currency = "INR";
    const email = userEmail;
    const contact = phone;
    let paymentObj = {};
    this.setState(
      {
        isLoading: true
      },
      () => {
        if (method === PAYMENT_MODE_CONSTANTS.card) {
          paymentObj = {
            card: {
              name: card.name,
              number: card.number,
              cvv: card.cvv,
              expiry_month: card.expiry_month,
              expiry_year: card.expiry_year
            }
          };
        } else if (method === PAYMENT_MODE_CONSTANTS.netbanking) {
          paymentObj = {
            bank: bank
          };
        } else if (method === PAYMENT_MODE_CONSTANTS.wallet) {
          paymentObj = {
            wallet: wallet
          };
        } else if (method === PAYMENT_MODE_CONSTANTS.upi) {
          paymentObj = {
            vpa: vpa
          };
        }
        this.razorpay
          .createPayment(
            {
              amount,
              email,
              contact,
              order_id,
              method,
              customer_id,
              token,
              currency,
              save: 1,
              ...paymentObj
            },
            { gpay: gpay },
            {
              paused: true,
              message: "Confirming Order..."
            }
          )
          .on("payment.success", resp => {
            this.setState({
              isSuccess: true,
              isLoading: false,
              isFailed: false
            });
            console.log("Success", resp);
          })
          .on("payment.error", resp => {
            console.log("response for payment", resp);
            this.setState({
              isSuccess: false,
              isFailed: true,
              isLoading: false
            });
          });
      }
    );
  };

  onGpayClick = () => {
    this.onPaymentInit({ method: "upi", gpay: true });
  };

  render() {
    const { methods, hasGPay, isLoading } = this.state;
    console.log("methods", methods);
    const availableBanks =
      methods && methods.netbanking ? Object.entries(methods.netbanking) : [];
    const availableWallets = methods && methods.wallet ? methods.wallet : {};
    if (methods) {
      return (
        <div className={classes.maindiv}>
          {methods["card"] && (
            <Cards onSubmit={this.onPaymentInit} razorpay={this.razorpay} />
          )}
          {methods["upi"] && (
            <UPI onSubmit={this.onPaymentInit} razorpay={this.razorpay} />
          )}
          {hasGPay && <button>Pay using Gpay</button>}
          {methods["wallet"] && (
            <Wallets
              onSubmit={this.onPaymentInit}
              availableWallets={availableWallets}
            />
          )}
          {methods["netbanking"] && (
            <Banks
              availableBanks={availableBanks}
              onSubmit={this.onPaymentInit}
            />
          )}
          {isLoading && <Loading open />}
        </div>
      );
    }
    return null;
  }
}

export default PaymentScreen;
