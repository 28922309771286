import { gql } from "apollo-boost";
/**
 * @description Gets the updated checkout from the server
 * @param {Array} carts - carts
 * @param {String} code  - any coupon or promotional discount
 * @param {String} codeType - type of coupon
 */
export const GET_UPDATED_CHECKOUT = () => {
  return gql`
    mutation updateCheckout($input: CheckoutInput!) {
      updateCheckout(input: $input) {
        isGhost
        checkout {
          id
          deliveryTime
          deliveryLocation
          seatNo
          status
          mode
          subtotal
          total
          subtotalCurrency
          extra
          user {
            phone
          }
          code
          codeText
          token
          carts {
            edges {
              node {
                id
                cartSla
                total
                subtotal
                specialInstructions
                pickupPoint {
                  id
                  name
                  store {
                    id
                    name
                  }
                }
                cartItems {
                  edges {
                    node {
                      id
                      subtotal
                      total
                      extra
                      quantity
                      customizations {
                        edges {
                          node {
                            id
                            name
                            price
                            quantity
                          }
                        }
                      }
                      stock {
                        id
                        price
                        inStock
                        sku {
                          id
                          name
                          quantity
                        }
                      }
                    }
                  }
                }
                status
                extra
              }
            }
          }
        }
      }
    }
  `;
};

export const INIT_PAYMENT = () => {
  return gql`
    mutation initializePayment($input: PaymentInput!) {
      updatePayment(input: $input) {
        payment {
          id
          token
          url
          amount
          status
          transactionId
        }
        razorpayOrderId
        orders {
          id
          cart {
            id
            extra
            total
            subtotal
            status
            pickupPoint {
              id
              name
              sla {
                id
                preparationTime
              }
              store {
                id
                name
              }
            }
            cartItems {
              edges {
                node {
                  id
                  total
                  quantity
                  stock {
                    id
                    stockSla
                    sku {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const UPDATE_USER_PROFILE = () => {
  return gql`
    mutation updateUserProfile($input: UserProfileInput!) {
      updateUserProfile(input: $input) {
        userProfile {
          firstName
          lastName
          user {
            email
            isGhost
            phone
          }
        }
      }
    }
  `;
};

export const UPDATE_USER_EMAIL = () => {
  return gql`
    mutation updateUserProfile($input: UserProfileInput!) {
      updateUserProfile(input: $input) {
        userProfile {
          firstName
          lastName
          user {
            email
            isGhost
            phone
          }
        }
      }
    }
  `;
};

export const SEND_EMAIL_INVOICE_FOR_ORDER = () => {
  return gql`
    mutation updateOrder($input: OrderInput!) {
      updateOrder(input: $input) {
        order {
          status
          id
        }
      }
    }
  `;
};

export const UPDATE_ORDER_FEEDBACK = () => {
  return gql`
    mutation updateOrderFeedback($input: OrderFeedbackInput!) {
      updateOrderFeedback(input: $input) {
        success
      }
    }
  `;
};

export const UPDATE_SPECIAL_INSTRUCTIONS = () => {
  return gql`
    mutation updateSpecialInstructions($input: SpecialInstructionsInput!) {
      updateSpecialInstructions(input: $input) {
        success
      }
    }
  `;
};
export const UPDATE_CHECKOUT_SEAT_NO = () => {
  return gql`
    mutation updateCheckoutSeatNo($input: CheckoutSeatInput!) {
      updateCheckoutSeatNo(input: $input) {
        checkout {
          id
          seatNo
        }
      }
    }
  `;
};

export const SEND_ORDER_INVOICE = () => {
  return gql`
    mutation updateOrder($input: OrderInput!) {
      updateOrder(input: $input) {
        order {
          id
        }
      }
    }
  `;
};

export const UPDATE_CHECKOUT_FEEDBACK = () => {
  return gql`
    mutation updateFeedback($input: FeedbackInput!) {
      updateFeedback(input: $input) {
        success
      }
    }
  `;
};

export const USER_AVAIL_SERVICE = () => {
  return gql`
    mutation updateUserAvailedService($input: UserAvailedServiceInput!) {
      updateUserAvailedService(input: $input) {
        userAvailedService {
          id
          service {
            id
            name
          }
        }
      }
    }
  `;
};

export const UPDATE_USER_LOCATION = () => {
  return gql`
    mutation updateUserLocation($input: UserLocationInput!) {
      updateUserLocation(input: $input) {
        userLocation {
          id
          name
          house
          landmark
          latitude
          longitude
        }
      }
    }
  `;
};
