import React, { PureComponent } from "react";
import classes from "./SeatInfoModal.module.css";
let QrReader;
if (process.env.NODE_ENV === "production") {
  QrReader = require("react-qr-reader");
}

const sticker = require("../../assets/sticker.png");
const message = {
  ACTION_INFO: "Look for the sticker on your boarding gate seat.",
  SUCCESS_INFO: "Your delicious food will be delivered at seat no",
  ERROR_INFO: "Error occurred while scanning seat!"
};

class SeatInfoModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      seatNumber: null,
      delay: 300,
      result: null,
      infoText: message.ACTION_INFO,
      inputActive: false,
      scanActive: true
    };
  }

  handleNext = event => {
    this.setState({
      isOpen: false
    });
    this.props.onNext(this.state.seatNumber);
  };

  handleCancel = event => {
    this.setState({
      seatNumber: null,
      isOpen: !this.state.isOpen
    });
    this.props.handleUpdateLater();
  };

  handleChange = event => {
    //   check server for valid seat no
    this.setState({
      seatNumber: event.target.value
    });
  };

  handleOkClick = () => {
    const seat = this.state.seatNumber;
    if (!seat) {
      return false;
    }
    this.setState({
      infoText: message.SUCCESS_INFO,
      isOpen: false
    });
    this.props.onNext(this.state.seatNumber);
  };

  handleScan = data => {
    if (data && data.startsWith("https://applite.grabbngo.com/")) {
      this.setState({
        result: data,
        seatNumber: data.slice(-4),
        infoText: message.SUCCESS_INFO,
        isOpen: false
      });
      this.props.onNext(this.state.seatNumber);
    }
  };

  handleScaning = event => {
    // handle scan
    this.setState({});
  };

  handleUpdateLater = event => {
    // send server event for update later
    this.setState({ isOpen: false });
    this.props.onUpdateLater();
  };

  handleError = err => {
    alert(err);
  };

  handleInputClick = () => {
    this.setState({
      inputActive: true,
      scanActive: false
    });
  };

  render() {
    const {
      isOpen,
      // seatNumber,
      //  showSeatInput,
      delay,
      infoText,
      inputActive,
      scanActive
    } = this.state;
    if (isOpen) {
      console.log(window.innerHeight);
      return (
        <>
          <div className={classes.overlay_div} />
          <div className={classes.seatmodal_modal}>
            <div className={classes.header}>
              <div className={classes.headerImage}>
                <img alt="" src={sticker} />
              </div>
              <div className={classes.infoText}>{infoText}</div>
            </div>
            {scanActive && process.env.NODE_ENV === "production" && (
              <div className={classes.qrdiv}>
                <QrReader
                  delay={delay}
                  onScan={this.handleScan}
                  onError={this.handleError}
                  style={{ width: "100%" }}
                  facingMode={"environment"}
                />
              </div>
            )}
            {(scanActive || inputActive) && (
              <div className={classes.btnGroup}>
                <input
                  placeholder="Enter seat no"
                  type="text"
                  onChange={this.handleChange}
                  onClick={this.handleInputClick}
                />
                {inputActive && (
                  <button onClick={this.handleOkClick}>OK</button>
                )}
                <button onClick={this.handleUpdateLater}>
                  I'll update later
                </button>
                {/* <button onClick={this.handleCancel}>
                    Cancel
                  </button>                  */}
                {/* {showNextButton && (
                  <button onClick={this.handleNext}>Next</button>
                )} */}
              </div>
            )}
          </div>
        </>
      );
    }
    return null;
  }
}

export default SeatInfoModal;
