import React, { PureComponent } from "react";
import Spinner from "react-spinkit";
import client from "../../apollo/client";
import { GET_POPULAR_FOOD } from "../../apollo/queries";
import { makeCouples } from "../../utilities/helpers";
import TrendingCard from "../../components/TrendingCard/TrendingCard";
import classes from "./TrendingScreen.module.css";
import GlobalStore from "../../utilities/GlobalState";

class Trending extends PureComponent {
  state = {
    stocks: [],
    isLoading: false,
    erorrMsg: null,
  };

  componentDidMount() {
    const { region, orderMode } = this.props;
    console.log("region", region);
    this.setState({
      isLoading: true,
    });
    region &&
      client
        .query({
          query: GET_POPULAR_FOOD(orderMode),
          variables: {
            regionId: region.id,
          },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          console.log("Region data", res.data.stocks.edges);
          const stocks = res.data.stocks.edges.filter((it) => it.node.inStock);
          this.setState({
            isLoading: false,
            stocks: stocks,
            hasError: false,
            erorrMsg: null,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            hasError: true,
            erorrMsg: err,
          });
        });
  }
  render() {
    const { stocks, isLoading } = this.state;
    const { freeMealCount, offerDetails, setFreeMealCount } = this.props;
    return (
      <GlobalStore.Consumer>
        {({
          cart,
          updateCart,
          setCustomizationData,
          screenDimensions,
          isSmallScreenDevice,
          setDescriptionData,
        }) => (
          <div className={classes.cardcontainer}>
            {!isLoading &&
              !isSmallScreenDevice &&
              makeCouples(stocks).map((it, index) => (
                <div className={classes.containerColumn} key={index}>
                  {it && it[0] && (
                    <TrendingCard
                      key={`${index}xx`}
                      type={index % 2 !== 0 ? "short" : "long"}
                      item={it[0]}
                      cart={cart}
                      updateCart={updateCart}
                      availHeight={screenDimensions.height - 186}
                      isSmallScreenDevice={isSmallScreenDevice}
                      setCustomizationData={setCustomizationData}
                      setDescriptionData={setDescriptionData}
                      freeMealCount={freeMealCount}
                      setFreeMealCount={setFreeMealCount}
                      offerDetails={offerDetails}
                    />
                  )}
                  {it && it[1] && (
                    <TrendingCard
                      key={`${index}yy`}
                      type={index % 2 === 0 ? "short" : "long"}
                      item={it[1]}
                      cart={cart}
                      updateCart={updateCart}
                      setCustomizationData={setCustomizationData}
                      availHeight={screenDimensions.height - 186}
                      isSmallScreenDevice={isSmallScreenDevice}
                      setDescriptionData={setDescriptionData}
                      freeMealCount={freeMealCount}
                      setFreeMealCount={setFreeMealCount}
                      offerDetails={offerDetails}
                    />
                  )}
                </div>
              ))}
            {!isLoading &&
              isSmallScreenDevice &&
              stocks.map((it, index) => (
                <div className={classes.containerColumn} key={index}>
                  {it && (
                    <TrendingCard
                      key={`${index}xx`}
                      type={"long"}
                      item={it}
                      cart={cart}
                      updateCart={updateCart}
                      setCustomizationData={setCustomizationData}
                      availHeight={screenDimensions.height - 186}
                      isSmallScreenDevice={isSmallScreenDevice}
                      setDescriptionData={setDescriptionData}
                      freeMealCount={freeMealCount}
                      setFreeMealCount={setFreeMealCount}
                      offerDetails={offerDetails}
                    />
                  )}
                </div>
              ))}
            {isLoading && (
              <div className={classes.loadingdiv}>
                <Spinner name="folding-cube" color="coral" />
                <p>Loading...</p>
              </div>
            )}
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default Trending;
