import React, { Component } from "react";
import classes from "./HelpScreen.module.css";
import { HELP_URL, GHOST_TOKEN } from "../../utilities/config";
import Swal from "sweetalert2";
import Spinner from "react-spinkit";
import StateManager from "../../utilities/StateManager";
import {generateHeaders, generateHeaderswithBasicAuthToken, generateEpochTimestamp, generateSignature} from '../../utilities/helpers';
class HelpScreen extends Component {
  state = {
    textContent: "",
    isSubmitted: false,
    isLoading: false
  };

  onTextChange = e => {
    this.setState({
      textContent: e.target.value
    });
  };

  onSubmit = () => {
    const { textContent } = this.state;
    var vtimestamp = generateEpochTimestamp();
    this.setState({
      isLoading: true
    });
    const stateManager = new StateManager();
    if (textContent.length > 5) {
      const authToken = stateManager.authToken || GHOST_TOKEN;
      fetch(HELP_URL, {
        method: "POST",
        headers: {
          Authorization: `Token ${authToken}`,
          "Content-Type": "application/json",
          Timestamp: vtimestamp,
          Signature: generateSignature(vtimestamp, authToken)           
        },
        body: JSON.stringify({
          comments: textContent
        })
      })
        .then(res => {
          if (res.status === 200) {
            this.setState(
              {
                textContent: "",
                isLoading: false
              },
              () => {
                Swal.fire({
                  title: "Success",
                  text: "Your query has been logged.\n We'll contact you soon.",
                  customClass: {
                    confirmButton: classes.swal_close_button
                  }
                });
              }
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              isLoading: false
            },
            () => {
              Swal.fire({
                title: "Oops!",
                text:
                  "An error occured while submitting your query. \n Please try again.",
                customClass: {
                  confirmButton: classes.swal_close_button
                }
              });
            }
          );
          console.log("error in help", err);
        });
    } else {
      this.setState(
        {
          isLoading: false
        },
        () => {
          Swal.fire({
            text: "Please submit a valid query",
            customClass: {
              confirmButton: classes.swal_close_button
            }
          });
        }
      );
    }
  };
  render() {
    const { textContent, isLoading } = this.state;
    return (
      <div className={classes.maindiv}>
        {isLoading && (
          <div className={classes.overlaydiv}>
            {" "}
            <Spinner
              style={{ marginBottom: 16 }}
              name="folding-cube"
              color={"#e63e25"}
            />
            <p>Updating...</p>
          </div>
        )}
        <textarea
          className={classes.text_area}
          placeholder="please write your queries here"
          value={textContent}
          onChange={this.onTextChange}
        />
        <button onClick={this.onSubmit} className={classes.sbumit_button}>
          Submit
        </button>
      </div>
    );
  }
}

export default HelpScreen;
