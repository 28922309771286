import React, { Component } from "react";
// import classes from "./ExploreRouter.module.css";
import { Route, Switch } from "react-router-dom";
import HomeScreen from "../../screens/HomeScreen/HomeScreen";
import UserProfileScreen from "../../screens/UserProfileScreen/UserProfileScreen";
import TrendingScreen from "../../screens/TrendingScreen/TrendingScreen";
import GlobalStore from "../../utilities/GlobalState";
import CheckoutModal from "../../components/CheckoutModal/CheckoutModal";
import SearchScreen from "../../screens/SearchScreen/SearchScreen";
import CustomizationModal from "../../components/CustomizationModal/CustomizationModal";
import ScreenLayout from "../../layouts/ScreenLayout/ScreenLayout";
import PaymentScreen from "../../screens/PaymentScreen/PaymentScreen";
import DescriptionModal from "../../components/DescriptionModal/DescriptionModal";
import DeliverySlot from "../../screens/DeliverySlot/DeliverySlot";
import DeliveryAddress from "../../screens/DeliveryAddress/DeliveryAddress";

class ExploreRouter extends Component {
  onExploreClick = () => {
    this.props.history.replace("/store");
  };
  onClose = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <GlobalStore.Consumer>
        {({
          customizationData: {
            product,
            customizations,
            pickupoint,
            stock,
            isStock,
          },
          isCustomizationModalOpen,
          closeCustomization,
          updateCart,
          isAuthenticated,
          showAuthModal,
          hideAuthModal,
          ordermode,
          searchText,
          setCustomizationData,
          cart,
          screenDimensions,
          region,
          isSmallScreenDevice,
          appConfiguration,
          isDescriptionModalOpen,
          setHasPendingPayment,
          descriptionData,
          closeDescriptionModal,
          addToCart,
          deliveryLocationType,
          deliveryLocation,
          redirectionURL,
        }) => (
          <div>
            <div>
              <Switch>
                <Route
                  path="/user"
                  render={(props) => (
                    <ScreenLayout children={<UserProfileScreen {...props} />} />
                  )}
                />
                <Route
                  path="/store"
                  render={(props) => (
                    <ScreenLayout
                      children={
                        <TrendingScreen
                          orderMode={ordermode}
                          region={region}
                          appConfiguration={appConfiguration}
                          {...props}
                        />
                      }
                    />
                  )}
                />
                <Route
                  path="/search"
                  render={(props) => (
                    <SearchScreen
                      searchText={searchText}
                      ordermode={ordermode}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/cart"
                  render={(props) => (
                    <ScreenLayout
                      children={
                        <CheckoutModal
                          onClose={this.onClose}
                          isOpen={true}
                          onExploreClick={this.onExploreClick}
                          updateCart={updateCart}
                          isUserAuthenticated={isAuthenticated}
                          onLoginClick={showAuthModal}
                          closeAuthModal={hideAuthModal}
                          cart={cart}
                          orderMode={ordermode}
                          deliveryLocationType={deliveryLocationType}
                          deliveryLocation={deliveryLocation}
                          region={region}
                          setHasPendingPayment={setHasPendingPayment}
                          redirectionURL={redirectionURL}
                          {...props}
                        />
                      }
                    />
                  )}
                />
                <Route
                  path="/slot-booking/"
                  render={(props) => (
                    <ScreenLayout children={<DeliverySlot {...props} />} />
                  )}
                />
                <Route
                  path="/delivery-address/"
                  render={(props) => (
                    <ScreenLayout children={<DeliveryAddress {...props} />} />
                  )}
                />
                } />
                <Route
                  exact
                  path="/payment"
                  render={(props) => <PaymentScreen {...props} />}
                />
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <ScreenLayout
                      children={
                        <HomeScreen
                          orderMode={ordermode}
                          region={region}
                          {...props}
                        />
                      }
                    />
                  )}
                />
              </Switch>
            </div>
            {isCustomizationModalOpen && (
              <CustomizationModal
                product={product}
                customizationConfig={customizations}
                store={pickupoint}
                isOpen={isCustomizationModalOpen}
                onClose={closeCustomization}
                stock={stock}
                isStock={isStock}
                updateCart={updateCart}
                setCustomizationData={setCustomizationData}
                cart={cart}
                screenDimensions={screenDimensions}
                isSmallScreenDevice={isSmallScreenDevice}
              />
            )}
            {/* adding description modal here */}
            {isDescriptionModalOpen && (
              <DescriptionModal
                data={descriptionData}
                onClose={closeDescriptionModal}
                onAddToCart={addToCart}
                isSmallScreenDevice={isSmallScreenDevice}
              />
            )}
          </div>
        )}
      </GlobalStore.Consumer>
    );
  }
}

export default ExploreRouter;
