import React, { PureComponent } from "react";
import classes from "./LegalInfo.module.css";
import Spinner from "react-spinkit";
import { Markdown } from "grommet";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  REFUND_POLICY_URL,
  OFFER_TERMS_URL
} from "../../utilities/config";

class LegalInfo extends PureComponent {
  state = {
    isLoading: false,
    legalContent: "",
    termsOfUse: "",
    refundContent: "",
    offerContent: ""
  };
  componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      () => {
        Promise.all([
          this.getPrivacyPolicy(),
          this.getTermsofUse(),
          this.getCancellationRefunds(),
          this.getOfferAndTc()
        ]).then(() => {
          this.setState({
            isLoading: false
          });
        });
      }
    );
  }
  getPrivacyPolicy = async () => {
    const res = await fetch(PRIVACY_POLICY_URL);
    const body = await res.text();
    this.setState({ legalContent: body });
  };

  getTermsofUse = async () => {
    const res = await fetch(TERMS_OF_USE_URL);
    const body = await res.text();
    this.setState({ termsOfUse: body });
  };

  getCancellationRefunds = async () => {
    const res = await fetch(REFUND_POLICY_URL);
    const body = await res.text();
    this.setState({ refundContent: body });
  };

  getOfferAndTc = async () => {
    const res = await fetch(OFFER_TERMS_URL);
    const body = await res.text();
    this.setState({ offerContent: body });
  };

  render() {
    const {
      termsOfUse,
      legalContent,
      refundContent,
      offerContent,
      isLoading
    } = this.state;
    return (
      <div className={classes.maindiv}>
        {!isLoading && (
          <div>
            <Markdown className={classes.markdown_div}>{legalContent}</Markdown>
          </div>
        )}
        {!isLoading && (
          <div>
            <Markdown className={classes.markdown_div}>{termsOfUse}</Markdown>
          </div>
        )}
        {!isLoading && (
          <div>
            <Markdown className={classes.markdown_div}>
              {refundContent}
            </Markdown>
          </div>
        )}
        {!isLoading && (
          <div>
            <Markdown className={classes.markdown_div}>{offerContent}</Markdown>
          </div>
        )}
        {isLoading && (
          <div className={classes.loadingdiv}>
            <Spinner name="folding-cube" color="coral" />
            <p>Loading...</p>
          </div>
        )}
      </div>
    );
  }
}

export default LegalInfo;
