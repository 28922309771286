import React, { PureComponent } from "react";
import classes from "./UserProfileScreen.module.css";
// import { ReactComponent as EmailIcon } from "../../assets/email.svg";
import { ReactComponent as UserWhiteIcon } from "../../assets/user_white.svg";
import { ReactComponent as HelpIcon } from "../../assets/help.svg";
import { ReactComponent as LegalIcon } from "../../assets/legal.svg";
import { ReactComponent as OrderIcon } from "../../assets/orders.svg";
import { ReactComponent as TnCIcon } from "../../assets/tnc.svg";
import StateManager from "../../utilities/StateManager";
import { logoutUser } from "../../apollo/api";

class MainUserPage extends PureComponent {
  mounted = false;
  state = {
    userprofile: null,
  };

  componentDidMount() {
    this.mounted = true;
    const stateManager = new StateManager();
    const userprofile = stateManager.userProfile;
    this.mounted &&
      this.setState({
        userprofile: userprofile,
      });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onHelpClick = () => {
    this.props.history.push("/user/help");
  };

  onPrevOrderClick = () => {
    this.props.history.push("/user/previousorders");
  };
  onLegalClick = () => {
    this.props.history.push("/user/legalinfo");
  };
  onPrivacyClick = () => {
    this.props.history.push("/user/privacypolicy");
  };
  onOfferClickClick = () => {
    this.props.history.push("/user/termsandconditions");
  };
  onAccountClick = () => {
    this.props.history.push("/user/account");
  };

  onLogout = () => {
    const { updateCart } = this.props;
    logoutUser()
      .then((res) => {
        const stateManager = new StateManager();
        stateManager.authToken = null;
        stateManager.userProfile = "";
        updateCart({});
        this.props.changeAuthState(false);
      })
      .catch((err) => {
        console.log("Error in logging out the user", err);
      });
  };

  onLogin = () => {
    console.log("Trying to login");
    const logoutEvent = new CustomEvent("clearauth");
    window.dispatchEvent(logoutEvent);
  };

  render() {
    const { isLoggedIn, changeAuthState } = this.props;
    console.log("is user authenticated", isLoggedIn, changeAuthState);
    return (
      <div>
        {isLoggedIn && isLoggedIn !== null && (
          <div onClick={this.onAccountClick} className={classes.options_div}>
            <UserWhiteIcon
              className={classes.icon_div}
              height={16}
              width={16}
              fill={"#FFFFFF"}
            />
            <p className={classes.options_text}>Account Info</p>
            {/* <CaretNext height={16} color={"#FFFFFF"} /> */}
          </div>
        )}
        {isLoggedIn && isLoggedIn !== null && (
          <div onClick={this.onPrevOrderClick} className={classes.options_div}>
            <OrderIcon
              className={classes.icon_div}
              height={16}
              width={16}
              fill={"#FFFFFF"}
            />
            <p className={classes.options_text}>Previous Orders</p>
          </div>
        )}
        {isLoggedIn && isLoggedIn !== null && (
          <div onClick={this.onHelpClick} className={classes.options_div}>
            <p className={classes.options_text}>
              <HelpIcon
                className={classes.icon_div}
                height={16}
                width={16}
                fill={"#FFFFFF"}
              />
              Help
            </p>
          </div>
        )}
        <div onClick={this.onLegalClick} className={classes.options_div}>
          <p className={classes.options_text}>
            {" "}
            <LegalIcon
              className={classes.icon_div}
              height={16}
              width={16}
              fill={"#FFFFFF"}
            />
            Legal Info
          </p>
          {/* <CaretNext height={16} color={"#FFFFFF"} /> */}
        </div>
        <div onClick={this.onOfferClickClick} className={classes.options_div}>
          <p className={classes.options_text}>
            {" "}
            <TnCIcon
              className={classes.icon_div}
              height={16}
              width={16}
              fill={"#FFFFFF"}
            />
            Offer Terms and Conditions
          </p>
          {/* <CaretNext height={16} color={"#FFFFFF"} /> */}
        </div>
        <div className={classes.button_div}>
          {isLoggedIn && isLoggedIn !== null && (
            <button onClick={this.onLogout} className={classes.logout_button}>
              Logout
            </button>
          )}
          {!isLoggedIn && (
            <button onClick={this.onLogin} className={classes.logout_button}>
              Login
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default MainUserPage;
