import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import MainRouter from "./routes/MainRouter";
import * as Sentry from "@sentry/browser";
import { SENTRY_URL } from "./utilities/config";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}
Sentry.init({
  dsn: SENTRY_URL,
});

ReactDOM.render(<MainRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
