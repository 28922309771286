import React, { useState } from "react";
import classes from "./CategorisedList.module.css";

const CategorisedList = ({ catName, data, onRegionSelect = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={classes.main_div}>
      <div onClick={toggleList} className={classes.category_title}>
        <p className={classes.category_name}>{catName}</p>
        <p
          style={
            !isOpen
              ? {
                  display: "inline",
                  transition: "all 0.2s"
                }
              : {
                  display: "inline",
                  transition: "all 0.2s",
                  transform: "rotate(90deg)"
                }
          }
        >
          &#10095;
        </p>
      </div>
      <div
        style={isOpen ? { display: "block" } : { display: "none" }}
        className={classes.item_div}
      >
        {data.map(region => (
          <p
            onClick={() => onRegionSelect(region)}
            className={classes.region_name}
            key={region.node.id}
          >
            {region.node.name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CategorisedList;
