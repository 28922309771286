import React, { PureComponent } from "react";
import classes from "./GatePicker.module.css";
import { FormClose } from "grommet-icons";
import { ORDER_MODES } from "../../utilities/config";
// import moment from "moment";

class GatePicker extends PureComponent {
  state = {
    searchValue: "",
    gates: this.props.gates,
  };

  onSelect = (gate) => {
    this.props.onSelect(ORDER_MODES.delivery, gate);
  };

  onlCose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { gates } = this.state;
    return (
      <div
        style={{ display: isOpen ? "block" : "none" }}
        className={classes.maindiv}
      >
        <div className={classes.search_header}>
          {/* <Search color={"white"} className={classes.search_icon} /> */}
          <p className={classes.headerText}>Select Your Delivery Location</p>
          <FormClose
            onClick={this.onlCose}
            color={"white"}
            className={classes.icons}
          />
        </div>
        <div className={classes.list_div}>
          {gates &&
            gates.edges
              .filter((ite) => ite.node.name.includes("Boarding Gate"))
              .map((item) => {
                return (
                  <div
                    className={classes.flightListItem}
                    key={item.node.id}
                    onClick={() => this.onSelect(item.node)}
                  >
                    <div className={classes.flghtNameDiv}>
                      <p className={classes.destinationText}>
                        {item.node.name}
                      </p>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
}

export default GatePicker;
