import React, { useState, useEffect } from "react";
import classes from "./PopUp.module.css";
import { ReactComponent as DineIn } from "../../assets/dinein.svg";
import { ReactComponent as Waiter } from "../../assets/waiter-table.svg";
import { Button, Statistic, Header, Portal } from "semantic-ui-react";
import { ORDER_MODES, CUSTOMER_ORDER_MODE } from "../../utilities/config";
import Swal from "sweetalert2";

const TableServicePopUp = ({ isOpen, onSelect, ...props }) => {
  const [showPopup, setShowPopUp] = useState(props.skipOrderMode && isOpen);
  const [hasTableNumber, setHasTableNumber] = useState(props.hasTableNumber);
  const [tableNumber, setTableNumber] = useState(props.tableNo);
  //eslint-disable-next-line
  const [orderMode, setOrderMode] = useState(props.mode);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setHasError(false);
  }, []);

  const handleTableChange = (e) => {
    setHasError(false);
    setTableNumber(e.target.value);
  };

  const onConfirm = () => {
    if (tableNumber && tableNumber.length > 0) {
      setShowPopUp(false);
      if (orderMode.name === ORDER_MODES.tableService)
        onSelect && onSelect(ORDER_MODES.tableService, tableNumber);
      else if (orderMode.name === ORDER_MODES.seatService)
        onSelect && onSelect(ORDER_MODES.seatService, tableNumber);
    } else {
      setHasError(true);
      setErrorMessage("Please enter a valid seat number");
    }
  };

  const handleOrderModeClick = () => {
    if (isOpen) {
      setShowPopUp(true);
    } else {
      Swal.fire({
        title: "",
        text: `We are not doing ${
          CUSTOMER_ORDER_MODE[orderMode.name]
        } at this moment.\n Please try after some time.`,
        customClass: {
          confirmButton: classes.swal_close_button,
          closeButton: classes.swal_close_button,
          cancelButton: classes.swal_close_button,
        },
        icon: "info",
      });
    }
  };

  return (
    <Portal
      size="fullscreen"
      closeOnEscape
      closeOnDimmerClick
      centered
      basic
      trigger={
        <Button fluid className={classes.button} onClick={handleOrderModeClick}>
          <DineIn height={30} width={30} fill={"#FFFFFF"} />
          <p>
            {orderMode.name === ORDER_MODES.tableService
              ? "TABLE SERVICE"
              : "SEAT SERVICE"}
          </p>
        </Button>
      }
      dimmer
      className={classes.main}
      open={showPopup}
      onClose={() => setShowPopUp(false)}
    >
      <div
        id="bottom-sheet"
        className={classes.overlay}
        //   onClick={this.togglePopup}
      >
        {hasTableNumber && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
              }}
            >
              <Button
                onClick={() => setHasTableNumber(false)}
                label="change"
                color="google plus"
                className={classes.changeButton}
              />
            </div>
            <div className={classes.stats}>
              <Statistic
                color={"orange"}
                label={
                  orderMode.name === ORDER_MODES.tableService
                    ? "Your table"
                    : "Your seat"
                }
                value={tableNumber}
                className={classes.stats_div}
                size="huge"
              ></Statistic>
            </div>
          </div>
        )}
        {!hasTableNumber && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Header
                as="h3"
                style={{ color: "#868686", textAlign: "left", width: "100%" }}
              >
                {orderMode.name === ORDER_MODES.tableService
                  ? "Enter table no"
                  : "Enter seat no"}
              </Header>
            </div>
            <input
              // disabled={!changeTableNumber}
              className={classes.input}
              value={tableNumber}
              type="text"
              autoFocus
              onChange={handleTableChange}
            />
            {hasError && (
              <p style={{ color: "red", fontSize: 10 }}>{errorMessage}</p>
            )}
          </div>
        )}
        <div style={{ textAlign: "center", padding: "20px" }}>
          <Waiter height={120} width={120}></Waiter>
        </div>
        <Button onClick={onConfirm} className={classes.confirmBtn}>
          Confirm
        </Button>
      </div>
    </Portal>
  );
};

export default TableServicePopUp;
