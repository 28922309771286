export class Card {
  number = "";
  name = "";
  expiry_month = "";
  expiry_year = "";
  cvv = "";
  constructor(
    number = "",
    name = "",
    expiry_month = "",
    expiry_year = "",
    cvv
  ) {
    this.number = number;
    this.name = name;
    this.expiry_month = expiry_month;
    this.expiry_year = expiry_year;
    this.cvv = cvv;
  }
}
