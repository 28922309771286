import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as VegIcon } from "../../assets/veg.svg";
import { ReactComponent as NonvegIcon } from "../../assets/nonveg.svg";
import { Transformation } from "cloudinary-core";
import { Image } from "cloudinary-react";
import classes from "./TrendingCard.module.css";
import { boxtheme, generateRandomShape } from "../../styles/theme";
import CartManager from "../../utilities/CartManager";
import { generateCustomizationConfig } from "../../utilities/helpers";
import { GET_CUSTOMIZATIONS_FOR_STOCKS } from "../../apollo/queries";
import client from "../../apollo/client";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { ReactComponent as CartIcon } from "../../assets/shopping-cart.svg";
import { RUPEE_SYMBOL } from "../../utilities/config";
import Swal from "sweetalert2";

const complimentaryImage = require("../../assets/complimentary.jpeg");

const shouldShowComplimentary = (offerDetails, stock) => {
  if (
    offerDetails &&
    offerDetails.offerStocks &&
    offerDetails.offerStocks.length > 0
  ) {
    return offerDetails.offerStocks.includes(stock.id);
  }
  return false;
};

class TrendingCard extends React.PureComponent {
  mounted = false;
  state = {
    customizations: {},
    isLoadingCustomization: false,
    itemsInCart: 0,
    showModal: false,
    showDescription: false,
    itemAddedAnimation: false,
  };

  componentDidMount() {
    this.mounted = true;
    const {
      item: { node: stock },
    } = this.props;

    const cartManager = new CartManager();
    const stocksForProduct = stock.id;
    let sum = 0;
    cartManager
      .getAdddedStocksForProduct(
        stocksForProduct,
        stock.store.pickupPoints.edges[0].node.id
      )
      .forEach((item) => {
        sum = sum + item.quantity;
      });
    this.setState(
      {
        itemsInCart: sum,
      },
      () => {
        if (stock.customizations.edges.length) {
          this.setState(
            {
              isLoadingCustomization: true,
            },
            () => {
              const stocksIds = [stock.id] || [];
              this.getCustomizationForProduct(stocksIds);
            }
          );
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cart !== this.props.cart) {
      const {
        item: { node: stock },
      } = this.props;

      const cartManager = new CartManager();
      const stocksForProduct = stock.id;
      let sum = 0;
      cartManager
        .getAdddedStocksForProduct(
          stocksForProduct,
          stock.store.pickupPoints.edges[0].node.id
        )
        .forEach((item) => {
          sum = sum + item.quantity;
        });
      this.setState({
        itemsInCart: sum,
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCustomizationForProduct = (stocksIds) => {
    client
      .query({
        query: GET_CUSTOMIZATIONS_FOR_STOCKS(),
        variables: { forStocks: stocksIds },
      })
      .then((res) => {
        let customStocks = {};
        res.data.stocks.edges.forEach((stock) => {
          console.log(
            "generate customization",
            generateCustomizationConfig(stock)
          );
          customStocks[stock.node.id] = generateCustomizationConfig(stock);
          // stock.node.customizations;
        });
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
            customizations: customStocks,
          });
      })
      .catch((err) => {
        console.log("Error in getting customization data", err);
        this.mounted &&
          this.setState({
            isLoadingCustomization: false,
          });
      });
  };

  addItemToCart = (e) => {
    console.log("Item added to the cart");
    console.log(this.props);
    e.stopPropagation();
    const {
      item: { node: stock },
      freeMealCount,
      setFreeMealCount,
      offerDetails,
    } = this.props;

    const pickupoint = {
      id: stock.store.pickupPoints.edges[0].node.id,
      name: stock.store.name,
    };
    console.log("item for the cart", pickupoint);
    const { customizations } = this.state;
    const cartManager = new CartManager();
    if (stock.customizations.edges.length > 0) {
      this.props.setCustomizationData(true, {
        stock: stock,
        pickupoint: pickupoint,
        customizations: customizations,
        isStock: true,
      });
    } else {
      const stockP = {
        id: stock.id,
        name: stock.sku.name,
        price: stock.salePrice,
      };
      if (
        offerDetails &&
        offerDetails.offerStocks &&
        offerDetails.offerStocks.includes(stock.id) &&
        freeMealCount < 1
      ) {
        Swal.fire({
          text: "You have exhausted complimentary meal quota",
          title: "Error",
          icon: "error",
          confirmButtonColor: "#ff5500",
        });
      } else {
        if (
          offerDetails &&
          offerDetails.offerStocks &&
          offerDetails.offerStocks.includes(stock.id)
        ) {
          setFreeMealCount(freeMealCount - 1);
        }
        cartManager.addStockToCart(pickupoint, stockP, 1);
      }
      this.setState(
        {
          itemAddedAnimation: true,
        },
        () => {
          this.timeout = setTimeout(() => {
            this.setState({
              itemAddedAnimation: false,
            });
          }, 1000);
        }
      );
      this.props.updateCart();
    }
  };

  addItemForQuantity = (sum, item) => {
    console.log("IOtem", item);
    return sum + item.quantity;
  };

  handleShowDescription = (e) => {
    // small description on the card itself
    // this.setState({
    //   showDescription: !this.state.showDescription
    // });
    // const { item } = this.props;
    // const pickupoint = { id: item.pickuppoint, name: item.storeName };
    // const { customizations, isLoadingCustomization } = this.state;
    console.log(this.props);
    this.props.setDescriptionData(true, {
      description: this.props,
      trending: true,
    });

    e.stopPropagation();
  };

  render() {
    const {
      item: { node: stock },
      type,
      availHeight,
      isSmallScreenDevice,
      offerDetails,
    } = this.props;
    const { itemsInCart, showDescription, itemAddedAnimation } = this.state;
    const shortHeight = availHeight * 0.4 - 10;
    const longHeight = isSmallScreenDevice
      ? availHeight * 0.75
      : availHeight * 0.5 - 10;
    const widthImage = !isSmallScreenDevice ? 200 : type === "long" ? 180 : 100;
    const { short, long } = boxtheme;
    const publicId = stock.sku.image.replace(
      "https://res.cloudinary.com/grabbngo/image/upload/",
      ""
    );
    if (stock && stock.inStock) {
      return (
        <div
          // onClick={this.addItemToCart}
          style={
            type === "short"
              ? {
                  height: shortHeight,
                  width: short.width,
                  borderRadius: short.borderRadius,
                  backgroundColor: stock.sku.selectedColor || "#FFFFFF",
                }
              : {
                  height: longHeight,
                  width: long.width,
                  borderRadius: long.borderRadius,
                  backgroundColor: stock.sku.selectedColor || "#FFFFFF",
                }
          }
          // onClick={this.addItemToCart}
          onClick={(e) => this.handleShowDescription(e)}
          className={classes.card}
        >
          {showDescription && (
            <div
              className={classes.description_view}
              style={{ backgroundColor: stock.sku.selectedColor }}
              onClick={(e) => this.handleShowDescription(e)}
            >
              <div style={{ color: stock.sku.fontColor }}>
                {stock.sku.description}
              </div>
              <div className={classes.description_close}>
                <CrossIcon height={25} width={25} />
              </div>
            </div>
          )}
          {itemAddedAnimation && (
            <div
              className={classes.addItem}
              style={{
                backgroundColor: stock.sku.selectedColor,
                color: stock.sku.fontColor,
              }}
            >
              Added to cart
              <div>
                <CartIcon
                  style={{ marginTop: "5%" }}
                  height={15}
                  width={15}
                  fill={stock.sku.fontColor === "#FFFFFF" ? "white" : "black"}
                />
              </div>{" "}
            </div>
          )}
          <div className={classes.store_image_div}>
            <img
              alt=""
              background={stock.sku.selectedColor || "#FFFFFF"}
              src={stock.store.image}
              height={35}
              width={35}
            />
          </div>
          <Image
            cloudName="grabbngo"
            width={widthImage}
            quality={"auto:good"}
            crop={"fit"}
            fetchFormat={"auto"}
            dpr={2.0}
            publicId={publicId}
            alt={stock.sku.name}
            radius={stock.sku.mask ? 999 : ""}
            height={type === "long" ? 120 : 80}
            className={classes.imageCard}
            background={"transparent"}
            style={{
              top: type === "long" ? "10%" : "3%",
              height: type === "long" ? "160px" : "140px",
              width: type === "long" ? "160px" : "140px",
              objectFit: "contain",
              filter: "drop-shadow(15px 15px 15px rgb(34, 34, 34, 0.8))",
            }}
          >
            <Transformation flags="progressive:steep,immutable_cache" />
          </Image>
          <img
            src={generateRandomShape()}
            alt=""
            className={classes.svg_mask}
          />
          <div className={classes.item_contains_div}>
            {stock.sku.product.contains === "NONVEG" && (
              <NonvegIcon height={16} width={16} />
            )}
            {stock.sku.product.contains === "VEG" && (
              <VegIcon height={16} width={16} />
            )}
          </div>
          {type === "short" && (
            <div
              style={{
                height: 1,
                width: 1,
                bottom: short.height / 3,
                left: short.width / 2,
                // boxShadow: `${stock.sku.selectedColor}bb 1px 50px 100px 100px`
              }}
              className={classes.blurdiv}
            ></div>
          )}
          <div className={classes.overlay_div}>
            <button
              style={{
                color: stock.sku.fontColor || "#000",
              }}
              className={classes.cardButton}
              onClick={this.addItemToCart}
            >
              +
              {itemsInCart > 0 && (
                <p
                  style={{
                    color: stock.sku.fontColor || "#000",
                    border: `1px solid ${stock.sku.fontColor}`,
                  }}
                  className={classes.item_count_button}
                >
                  {itemsInCart}
                </p>
              )}
            </button>

            <div
              className={
                type === "short" ? classes.short_contentDiv : classes.contentDiv
              }
            >
              <p
                style={
                  stock.sku.fontColor
                    ? { color: stock.sku.fontColor }
                    : { color: "#000" }
                }
              >
                {stock.sku.name}{" "}
                {stock.sku.quantity ? stock.sku.quantity.toLowerCase() : ""}
              </p>
              {shouldShowComplimentary(offerDetails, stock) && (
                <img
                  alt="complimentary"
                  src={complimentaryImage}
                  style={{
                    height: 20,
                    width: 100,
                    objectFit: "contain",
                    marginBottom: 0,
                    marginLeft: 0,
                  }}
                />
              )}
              <p
                style={
                  stock.sku.fontColor
                    ? { color: stock.sku.fontColor }
                    : { color: "#000" }
                }
              >
                {RUPEE_SYMBOL} {stock.salePrice}
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

TrendingCard.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(["short", "long"]),
};

// TrendingCard.defaultProps = {
//   item: data,
//   type: "short"
// };

export default TrendingCard;
